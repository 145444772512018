import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1
  pageSize = 5
  pageAd = 1
  pageSizeAd = 5
  administradores = []
  normais = []
  normaisOriginal = []
  atualizacaoSenha = {
    novaSenha: null,
    confirmaNovaSenha: null,
  }
  filterNome
  filterCidade
  novoUsuario = {
    tipoUsuario: null,
    estado: null,
    cidade: null,
    nome: null,
    login: null,
    senha: null,
    confirmaSenha: null
  }
  tipos = [

      {
        id: '1',
        name: 'Administrador',
      },{
        id: '2',
        name: 'Servidor',
      }]
  estados = [];
  cidades = [];
  userToUpdate = null
  tipoUsuario = null
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }

  async ngOnInit(){
    const self = this
    self.atualizacaoSenha = {
      novaSenha: null,
      confirmaNovaSenha: null,
    }
    self.userToUpdate = null
    self.tipoUsuario = null
    self.novoUsuario = {
      tipoUsuario: null,
      estado: null,
      cidade: null,
      nome: null,
      login: null,
      senha: null,
      confirmaSenha: null
    }
    self.estados = []
    self.cidades = []
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    const estados = await self.apiCall.get('enderecos/estados').pipe(take(1)).toPromise()
    self.estados = JSON.parse(JSON.stringify(estados));
    const admin = await self.apiCall.get('users/lista/admin').pipe(take(1)).toPromise()
    self.administradores = JSON.parse(JSON.stringify(admin));
    const normal = await self.apiCall.get('users/lista/servidor').pipe(take(1)).toPromise()
    self.normais = JSON.parse(JSON.stringify(normal));
    self.normaisOriginal = JSON.parse(JSON.stringify(normal));
    self.spinner.hide()
  }

  async updateSenha(modal){
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    await this.apiCall.get(`users/edicao/senha?idUsuario=${this.userToUpdate.id}&senha=${this.atualizacaoSenha.novaSenha}&tipoUsuario=${this.tipoUsuario}`).pipe(take(1)).toPromise()
    this.spinner.hide()
    await this.app.alert('Tudo certo!', 'Senha atualizada com sucesso!', 'success')
    modal.close('Close click')
    await this.ngOnInit()

  }
  openModal(content, usuario, tipo){
    this.userToUpdate = usuario
    this.tipoUsuario = tipo
    const modal = this.modalService.open(content, { centered: true })
  }
  teste(){
    console.log(this.novoUsuario.tipoUsuario)
  }
  filter(){
    this.normais = this.normaisOriginal
    if(this.filterNome){
      this.normais = this.normais.filter(x=>x.nome && x.nome.toLowerCase().includes(this.filterNome.toLowerCase()))
    }
    if(this.filterCidade){
      this.normais = this.normais.filter(x=>x.nomeCidade && x.nomeCidade.toLowerCase().includes(this.filterCidade.toLowerCase()))
    }
  }
  async toggleEstado(){
    this.cidades = []
    const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+this.novoUsuario.estado).pipe(take(1)).toPromise()
    this.cidades = JSON.parse(JSON.stringify(cidades));
  }
  async insertUsuario(modal){
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(this.novoUsuario.tipoUsuario=='1'){
      const verification = await this.apiCall.get('users/verificacao/admin?login='+this.novoUsuario.login).pipe(take(1)).toPromise()
      if(verification){
        this.spinner.hide()
        await this.app.alert('Ops', 'Este usuário já existe! informe outro login.', 'error')
        return
      }
    }else{
      const verification = await this.apiCall.get('users/verificacao/servidor?login='+this.novoUsuario.login).pipe(take(1)).toPromise()
      if(verification){
        this.spinner.hide()
        await this.app.alert('Ops', 'Este usuário já existe! informe outro login.', 'error')
        return
      }
    }
    await this.apiCall.get(`users/novo?tipoUsuario=${this.novoUsuario.tipoUsuario}&nome=${this.novoUsuario.nome}&cidade=${this.novoUsuario.cidade}&senha=${this.novoUsuario.senha}&login=${this.novoUsuario.login}`).pipe(take(1)).toPromise()
    this.spinner.hide()
    await this.ngOnInit()
    await this.app.alert('Tudo certo!', 'Usuário cadastrado com sucesso!', 'success')
    modal.close('Close click')
  }


}
