import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import { saveAs } from "file-saver";

@Component({
  selector: 'app-usuarios-municipio',
  templateUrl: './usuarios-municipio.component.html',
  styleUrls: ['./usuarios-municipio.component.scss']
})
export class UsuariosMunicipioComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1
  pageSize = 10
  atualizacaoSenha = {
    novaSenha: null,
    confirmaNovaSenha: null,
  }
  userToUpdate = null
  tipoUsuario = null

  usuarios = []
  cidade
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }
  async ngOnInit() {
    const self = this
    self.route.queryParams.subscribe( parametros => {
      if (parametros['cidade']) {
        self.cidade = parametros['cidade'];
      }
    });
    if(self.cidade){
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });

      const usuarios = await self.apiCall.get('servidor/usuarios/cidade?cidade='+self.cidade).pipe(take(1)).toPromise()
      self.usuarios = JSON.parse(JSON.stringify(usuarios));
     self.spinner.hide()
    }
  }

  async download(chave, nome){
    const url = await this.apiCall.get('banco-dados/files/url').pipe(take(1)).toPromise()
    if(url) saveAs(url+chave, nome);
  }
  async selecionaArquivo(event) {
    const self = this
    if(self.cidade){
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        console.log(file)
        if(file.name.endsWith('xls') || file.name.endsWith('xlsx')){
          const formData = new FormData();
          formData.append('file', file);
          try{
            self.spinner.show(undefined,
              {
                type: 'ball-triangle-path',
                size: 'medium',
                bdColor: 'rgba(0, 0, 0, 0.8)',
                color: '#fff',
                fullScreen: true
              });

            const uploadFiles = await this.apiCall.post('servidor/arquivo/usuarios?cidade='+self.cidade, formData).pipe(take(1)).toPromise()
            self.spinner.hide()
            await this.app.alert('Tudo certo!', 'Usuários importados com sucesso!', 'success')
            self.ngOnInit()
          }catch(e){
            self.ngOnInit()
          }
        }
      }
    }
  }
  async updateSenha(modal){
    if(!this.userToUpdate.id || !this.atualizacaoSenha.novaSenha) return
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    await this.apiCall.get(`servidor/usuarios/cidade/senha?id=${this.userToUpdate.id}&password=${this.atualizacaoSenha.novaSenha}`).pipe(take(1)).toPromise()
    this.spinner.hide()
    await this.app.alert('Tudo certo!', 'Senha atualizada com sucesso!', 'success')
    modal.close('Close click')
    await this.ngOnInit()

  }
  openModal(content, usuario){
    this.userToUpdate = usuario
    const modal = this.modalService.open(content, { centered: true })
  }
}
