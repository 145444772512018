<div class="row">
  <div class="col">
    <div class="row my-4">
      <div class="col">
        <h4 style="color: #0e6161">Exportação de relatórios</h4>
      </div>
    </div>
    <div class="row my-4">
      <div class="col">
        <div style="display: table">
          <button
            style="display: table-cell; margin-right: 20px"
            type="button"
            placement="bottom"
            ngbTooltip="Download relatórios"
            class="btn btn-primary"
            (click)="exportarBancoDeDadosFormatoSPREV()"
          >
            <i class="fa fa-download"></i> Exportar SPREV
          </button>
          <p style="display: table-cell; color: #0e6161">
            Layout padrão- planilha geral com todas as informações
          </p>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <div class="col">
        <button
          type="button"
          placement="bottom"
          ngbTooltip="Download relatórios"
          class="btn btn-outline-primary"
          (click)="getRelatoriosCidade()"
        >
          <i class="fa fa-download"></i> Exportar Servidores
        </button>
      </div>
    </div>
    <div class="row my-4">
      <div class="col">
        <button
          type="button"
          placement="bottom"
          ngbTooltip="Download relatório Tempo Serviço Passado"
          class="btn btn-outline-primary"
          (click)="getRelatorioTempoServicoPassado()"
        >
          <i class="fa fa-download"></i> Exportar Tempo de Serviço Passado
        </button>
      </div>
    </div>
    <div class="row my-4">
      <div class="col">
        <button
          type="button"
          placement="bottom"
          ngbTooltip="Download relatório Atendimentos"
          class="btn btn-outline-primary"
          (click)="getRelatorioAtendimento()"
        >
          <i class="fa fa-download"></i> Exportar Atendimentos
        </button>
      </div>
    </div>
    <div class="row my-4">
      <div class="col">
        <button
          type="button"
          placement="bottom"
          ngbTooltip="Download relatório de pendências."
          class="btn btn-outline-primary"
          (click)="getRelatorioPendencias()"
        >
          <i class="fa fa-download"></i> Exportar Pendências
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="col-6">
    <div class="row my-4">
        <div class="col">
          <h4 style="color: #0e6161">Importação de relatórios</h4>
        </div>
      </div>
    <div class="row my-4">
      <div class="col">
        <button
          type="button"
          placement="bottom"
          ngbTooltip="Upload Servidores"
          class="btn btn-primary"
          (click)="selecionaArquivo(1)"
        >
          <i class="fa fa-upload"></i> Importar Servidores
        </button>
      </div>
    </div>
    <div class="row my-4">
      <div class="col">
        <button
          type="button"
          placement="bottom"
          ngbTooltip="Upload Aposentados."
          class="btn btn-primary"
          (click)="selecionaArquivo(2)"
        >
          <i class="fa fa-upload"></i> Importar Aposentados
        </button>
      </div>
    </div>
    <div class="row my-4">
      <div class="col">
        <button
          type="button"
          placement="bottom"
          ngbTooltip="Upload Pensionistas"
          class="btn btn-primary"
          (click)="selecionaArquivo(3)"
        >
          <i class="fa fa-upload"></i> Importar Pensionistas
        </button>
      </div>
    </div>
    <div class="row my-4">
      <div class="col">
        <button
          type="button"
          placement="bottom"
          ngbTooltip="Upload Simplificado"
          class="btn btn-primary"
          (click)="uploadSimplificado()"
        >
          <i class="fa fa-upload"></i> Importar Modelo Simplificado
        </button>
      </div>
    </div>
  </div> -->
</div>
<ngx-spinner></ngx-spinner>
