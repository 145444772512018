import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import Me from "./me";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private me: Me) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.me.currentUserValue;
      if (currentUser && currentUser.token && !request.url.startsWith('https://cadastro-pessoas-arquivos-servidor.s3.amazonaws.com') && !request.url.startsWith('https://cadastro-pessoas-arquivos-servidor.s3.sa-east-1.amazonaws.com')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request);
    }
}
