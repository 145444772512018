import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {
  apiCall = this.api.new().silent();
  idServidor
  contato = {
    id: null,
    id_servidor: null,
    telefone: null,
    celular: null,
    email: null,
  }
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }
  async ngOnInit() {
    const self = this
    self.contato = {
      id: null,
      id_servidor: null,
      telefone: null,
      celular: null,
      email: null,
    }
    self.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        self.idServidor = parametros['id'];
      }
    });

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    const contato = await self.apiCall.get(`servidor/contatos?id_servidor=${self.idServidor}`).pipe(take(1)).toPromise()

    if(contato) self.contato = JSON.parse(JSON.stringify(contato));

    self.spinner.hide()
  }
  async atualizarOuCadastrar(){
    const self = this
    if(!self.contato || !self.idServidor){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(self.contato.id){
      await self.apiCall.get(`servidor/contatos/editar?id=${self.contato.id}&telefone=${self.contato.telefone}&celular=${self.contato.celular}&email=${self.contato.email}`).pipe(take(1)).toPromise()
    }else{
      await self.apiCall.get(`servidor/contatos/novo?id_servidor=${self.idServidor}&telefone=${self.contato.telefone}&celular=${self.contato.celular}&email=${self.contato.email}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.contato.id ? 'Contatos atualizados com sucesso!' : 'Contatos cadastrados com sucesso!', 'success')
    await self.ngOnInit()


  }

}
