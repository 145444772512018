import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import { ExcelService } from 'app/helpers/excel.service';
import Me from 'app/helpers/me';
import { forEach } from 'core-js/core/array';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-relatorios-dinamico',
  templateUrl: './relatorios-dinamico.component.html',
  styleUrls: ['./relatorios-dinamico.component.scss']
})
export class RelatoriosDinamicoComponent implements OnInit {
  apiCall = this.api.new().silent();
  idCidade = null;
  cidade = null;
  servidoresAtivos = [];
  servidoresInativos = [];
  servidoresPensionistas = [];
  servidoresAtivosPersonalizados = [];
  camposAtivos = [];
  camposInativos = [];
  camposPensionistas = [];

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private me: Me,
    private excelService: ExcelService,
    private app: App,
    private http: HttpClient,
    private api: Api
  ) { }

  ngOnInit(): void {
    const self = this;
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    self.idCidade = json.user.cidade;
    self.camposAtivos = [
      { id: 'NOME', label: 'NOME', checked: false },
      { id: 'ANO', label: 'ANO', checked: false },
      { id: 'MÊS', label: 'MÊS', checked: false },
      { id: 'CO_IBGE', label: 'CO_IBGE', checked: false },
      { id: 'NOME_DO_ENTE', label: 'NOME_DO_ENTE', checked: false },
      { id: 'SIGLA_ESTADO', label: 'SIGLA_ESTADO', checked: false },
      { id: 'COMPOSIÇÃO DA MASSA (A)', label: 'COMPOSIÇÃO DA MASSA (A)', checked: false },
      { id: 'TIPO DO FUNDO (B)', label: 'TIPO DO FUNDO (B)', checked: false },
      { id: 'CNPJ', label: 'CNPJ', checked: false },
      { id: 'NOME DO ÓRGÃO/ENTIDADE', label: 'NOME DO ÓRGÃO/ENTIDADE', checked: false },
      { id: 'PODER', label: 'PODER', checked: false },
      { id: 'TIPO', label: 'TIPO', checked: false },
      { id: 'A - POPULAÇÃO COBERTA', label: 'A - POPULAÇÃO COBERTA', checked: false },
      { id: 'B- POPULAÇÃO COBERTA', label: 'B- POPULAÇÃO COBERTA', checked: false },
      { id: 'C - POPULAÇÃO COBERTA', label: 'C - POPULAÇÃO COBERTA', checked: false },
      { id: 'MATRÍCULA', label: 'MATRÍCULA', checked: false },
      { id: 'CPF', label: 'CPF', checked: false },
      { id: 'PASEP', label: 'PIS-PASEP', checked: false },
      { id: 'SEXO', label: 'SEXO', checked: false },
      { id: 'ESTADO CIVIL', label: 'ESTADO CIVIL', checked: false },
      { id: 'DATA DE NASCIMENTO', label: 'DATA DE NASCIMENTO', checked: false },
      { id: 'COD SITUAÇÃO FUNCIONA', label: 'COD SITUAÇÃO FUNCIONAL', checked: false },
      { id: 'TIPO DE VÍNCULO', label: 'TIPO DE VÍNCULO', checked: false },
      { id: 'DATA DE INGRESSO NO SERV_PUBLICO', label: 'DATA DE INGRESSO NO SERV_PUBLICO', checked: false },
      { id: 'DATA DE INGRESSO NO ENTE', label: 'DATA DE INGRESSO NO ENTE', checked: false },
      { id: 'DATA DE INGRESSO NA CARREIRA ATUAL', label: 'DATA DE INGRESSO NA CARREIRA ATUAL', checked: false },
      { id: 'IDENTIFICAÇÃO DA CARREIRA ATUAL', label: 'IDENTIFICAÇÃO DA CARREIRA ATUAL', checked: false },
      { id: 'DATA DE INÍCIO DE EXERCÍCIO NO CARGO ATUAL', label: 'DATA DE INÍCIO DE EXERCÍCIO NO CARGO ATUAL', checked: false },
      { id: 'IDENTIFICAÇÃO DO CARGO ATUAL', label: 'IDENTIFICAÇÃO DO CARGO ATUAL', checked: false },
      { id: 'BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO', label: 'BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO', checked: false },
      { id: 'REMUNERAÇÃO MENSAL TOTAL DO SERVIDOR ATIVO', label: 'REMUNERAÇÃO MENSAL TOTAL DO SERVIDOR ATIVO', checked: false },
      { id: 'CONTRIBUIÇÃO MENSAL', label: 'CONTRIBUIÇÃO MENSAL', checked: false },
      { id: 'PRIMEIRA BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO', label: 'PRIMEIRA BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO', checked: false },
      { id: 'TEMPO DE CONTRIBUIÇÃO DO SERVIDOR ATIVO ANTERIOR À ADMISSÃO NO ENTE PARA O RGPS', label: 'TEMPO DE CONTRIBUIÇÃO DO SERVIDOR ATIVO ANTERIOR À ADMISSÃO NO ENTE PARA O RGPS', checked: false },
      { id: 'NÚMERO DE DEPENDENTES DO SERVIDOR ATIVO', label: 'NÚMERO DE DEPENDENTES DO SERVIDOR ATIVO', checked: false },
      { id: 'CONDIÇÃO DO CÔNJUGE', label: 'CONDIÇÃO DO CÔNJUGE', checked: false },
      { id: 'DATA DE NASCIMENTO DO DEPENDENTE 2', label: 'DATA DE NASCIMENTO DO DEPENDENTE 2', checked: false },
      { id: 'CONDIÇÃO DO DEPENDENTE 2', label: 'CONDIÇÃO DO DEPENDENTE 2', checked: false },
      { id: 'DATA DE NASCIMENTO DO DEPENDENTE 3', label: 'DATA DE NASCIMENTO DO DEPENDENTE 3', checked: false },
      { id: 'CONDIÇÃO DO DEPENDENTE 3', label: 'CONDIÇÃO DO DEPENDENTE 3', checked: false },
      { id: 'SEGURADO EM ABONO DE PERMANÊNCIA', label: 'SEGURADO EM ABONO DE PERMANÊNCIA', checked: false },
      { id: 'DATA DE INÍCIO DE ABONO DE PERMANÊNCIA DO SERVIDOR ATIVO', label: 'DATA DE INÍCIO DE ABONO DE PERMANÊNCIA DO SERVIDOR ATIVO', checked: false },
      { id: 'PREVIDÊNCIA COMPLEMENTAR', label: 'PREVIDÊNCIA COMPLEMENTAR', checked: false },
      { id: 'CONDIÇÃO DO DEPENDENTE 3', label: 'CONDIÇÃO DO DEPENDENTE 3', checked: false },
      { id: 'TETO REMUNERATÓRIO ESPECÍFICO', label: 'TETO REMUNERATÓRIO ESPECÍFICO', checked: false },
      // Adicione mais objetos para cada campo conforme necessário
    ];
    self.camposPensionistas = [
      { id: 'NOME', label: 'NOME', checked: false },
      { id: 'ANO', label: 'ANO', checked: false },
      { id: 'MÊS', label: 'MÊS', checked: false },
      { id: 'CO_IBGE', label: 'CO_IBGE', checked: false },
      { id: 'NOME_DO_ENTE', label: 'NOME DO ÓRGÃO/ENTIDADE', checked: false },
      { id: 'SG_UF', label: 'SIGLA_ESTADO', checked: false },
      { id: 'CO_COMP_MASSA', label: 'COMPOSIÇÃO DA MASSA (A)', checked: false },
      { id: 'CO_TIPO_FUNDO', label: 'TIPO DE FUNDO (B)', checked: false },
      { id: 'CNPJ', label: 'CNPJ', checked: false },
      { id: 'NOME DO ÓRGÃO/ENTIDADE', label: 'NOME DO ÓRGÃO/ENTIDADE', checked: false },
      { id: 'CO_PODER', label: 'CO_PODER (CÓDIGO DO PODER)', checked: false },
      { id: 'CO_TIPO_PODER', label: 'CO_TIPO_PODER (CÓDIGO DO TIPO DE PODER)', checked: false },
      { id: 'CO_TIPO_INSTITUIDOR', label: 'CO_TIPO_INSTITUIDOR (CÓDIGO DO TIPO DE INSTITUIDOR)', checked: false },
      { id: 'ID MATRICULA INSTITUIDOR', label: 'ID MATRICULA INSTITUIDOR', checked: false },
      { id: 'INSTITUIDOR CPF', label: 'INSTITUIDOR CPF', checked: false },
      { id: 'INSTITUIDOR PIS PASEP', label: 'INSTITUIDOR PIS PASEP', checked: false },
      { id: 'SEXO INSTITUIDOR', label: 'SEXO INSTITUIDOR', checked: false },
      { id: 'DT_NASC_INSTITUIDOR', label: 'DT_NASC_INSTITUIDOR', checked: false },
      { id: 'DT_OBITO_INSTITUIDOR', label: 'DT_OBITO_INSTITUIDOR', checked: false },
      { id: 'MATRICULA_PENSIONISTA', label: 'MATRICULA_PENSIONISTA', checked: false },
      { id: 'CPF_PENSIONISTA', label: 'CPF_PENSIONISTA', checked: false },
      { id: 'SEXO', label: 'SEXO', checked: false },
      { id: 'DT_NASC_PENSIONISTA', label: 'DT_NASC_PENSIONISTA', checked: false },
      { id: 'RELAÇÃO DO PENSIONISTA COM O INSTITUIDOR', label: 'RELAÇÃO DO PENSIONISTA COM O INSTITUIDOR', checked: false },
      { id: 'DATA INICIO PENSAO', label: 'DATA INICIO PENSAO', checked: false },
      { id: 'VALOR BENEF PENSAO', label: 'VALOR BENEF PENSAO', checked: false },
      { id: 'VALOR TOTAL DA PENSÃO', label: 'VALOR TOTAL DA PENSÃO', checked: false },
      { id: 'VL_PCT_QUOTA', label: 'VL_PCT_QUOTA', checked: false },
      { id: 'VALOR CONTRIBUICAO', label: 'VALOR CONTRIBUICAO', checked: false },
      { id: 'VALOR MENSAL DA COMPENSAÇÃO PREVIDENCIÁRIA', label: 'VALOR MENSAL DA COMPENSAÇÃO PREVIDENCIÁRIA', checked: false },
      { id: 'PARIDADE', label: 'PARIDADE', checked: false },
      { id: 'CONDIÇÃO DO PENSIONISTA', label: 'CONDIÇÃO DO PENSIONISTA', checked: false },
      { id: 'DURAÇÃO DO BENEFÍCIO', label: 'DURAÇÃO DO BENEFÍCIO', checked: false },
      { id: 'TEMPO DURAÇÃO DO BENEFÍCIO', label: 'TEMPO DURAÇÃO DO BENEFÍCIO', checked: false },
      { id: 'PREVIDÊNCIA COMPLEMENTAR', label: 'PREVIDÊNCIA COMPLEMENTAR', checked: false },
      { id: 'TETO REMUNERATÓRIO ESPECÍFICO', label: 'TETO REMUNERATÓRIO ESPECÍFICO', checked: false }
    ];
    self.camposInativos = [
      { id: 'NOME', label: 'NOME', checked: false },
      { id: 'ANO', label: 'ANO', checked: false },
      { id: 'MÊS', label: 'MÊS', checked: false },
      { id: 'CO_IBGE', label: 'CO_IBGE', checked: false },
      { id: 'NOME_DO_ENT', label: 'NOME_DO_ENT', checked: false },
      { id: 'SIGLA_ESTADO', label: 'SIGLA_ESTADO', checked: false },
      { id: 'COMPOSIÇÃO DA MASSA (A)', label: 'COMPOSIÇÃO DA MASSA (A)', checked: false },
      { id: 'TIPO DE FUNDO (B)', label: 'TIPO DE FUNDO (B)', checked: false },
      { id: 'CNPJ', label: 'CNPJ', checked: false },
      { id: 'NOME DO ÓRGÃO/ENTIDADE', label: 'NOME DO ÓRGÃO/ENTIDADE', checked: false },
      { id: 'PODER', label: 'PODER', checked: false },
      { id: 'TIPO_AUTARQUIA', label: 'TIPO_AUTARQUIA', checked: false },
      { id: 'CO_TIPO_POPULACAO', label: 'CO_TIPO_POPULACAO', checked: false },
      { id: 'TIPO_CARGO', label: 'TIPO_CARGO', checked: false },
      { id: 'CO_TIPO_APOSENTADORIA', label: 'CO_TIPO_APOSENTADORIA', checked: false },
      { id: 'MATRÍCULA', label: 'MATRÍCULA', checked: false },
      { id: 'CPF', label: 'CPF', checked: false },
      { id: 'PIS-PASEP', label: 'PIS-PASEP', checked: false },
      { id: 'SEXO DO APOSENTADO', label: 'SEXO DO APOSENTADO', checked: false },
      { id: 'ESTADO CIVIL', label: 'ESTADO CIVIL', checked: false },
      { id: 'DATA DE NASCIMENTO', label: 'DATA DE NASCIMENTO', checked: false },
      { id: 'DT_ING_SERV_PUB', label: 'DT_ING_SERV_PUB', checked: false },
      { id: 'DATA DE INGRESSO NO ENTE', label: 'DATA DE INGRESSO NO ENTE', checked: false },
      { id: 'DT_INICIO_APOSENTADORIA', label: 'DT_INICIO_APOSENTADORIA', checked: false },
      { id: 'VL_APOSENTADORIA', label: 'VL_APOSENTADORIA', checked: false },
      { id: 'VL_CONTRIBUICAO', label: 'VL_CONTRIBUICAO', checked: false },
      { id: 'VL_COMPENS_PREVID', label: 'VL_COMPENS_PREVID', checked: false },
      { id: 'IN_PARID_SERV', label: 'IN_PARID_SERV', checked: false },
      { id: 'CO_CONDICAO_APOSENTADO', label: 'CO_CONDICAO_APOSENTADO', checked: false },
      { id: 'NU_TEMPO_RGPS_MUN', label: 'NU_TEMPO_RGPS_MUN', checked: false },
      { id: 'NU_TEMPO_RPPS', label: 'NU_TEMPO_RPPS', checked: false },
      { id: 'NU_DEPENDENTES', label: 'NU_DEPENDENTES', checked: false },
      { id: 'DATA DE NASCIMENTO DO CÔNJUGE', label: 'DATA DE NASCIMENTO DO CÔNJUGE', checked: false },
      { id: 'CONDIÇÃO DO CÔNJUGE', label: 'CONDIÇÃO DO CÔNJUGE', checked: false },
      { id: 'DATA DE NASCIMENTO DO DEPENDENTE 2', label: 'DATA DE NASCIMENTO DO DEPENDENTE 2', checked: false },
      { id: 'CONDIÇÃO DO DEPENDENTE 2', label: 'CONDIÇÃO DO DEPENDENTE 2', checked: false },
      { id: 'DATA DE NASCIMENTO DO DEPENDENTE 3', label: 'DATA DE NASCIMENTO DO DEPENDENTE 3', checked: false },
      { id: 'CONDIÇÃO DO DEPENDENTE 3', label: 'CONDIÇÃO DO DEPENDENTE 3', checked: false },
      { id: 'PREVIDÊNCIA COMPLEMENTAR', label: 'PREVIDÊNCIA COMPLEMENTAR', checked: false },
      { id: 'TETO REMUNERATÓRIO ESPECÍFICO', label: 'TETO REMUNERATÓRIO ESPECÍFICO', checked: false }
    ];
  }

   openModal(content) {
    this.modalService.open(content, { centered: true, size: "xl" })
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  selecionarTudoInativos() {
    const algumSelecionado = this.camposInativos.some(campo => campo.checked);
    if (algumSelecionado) {
      this.camposInativos.forEach(campo => campo.checked = false);
    } else {
      this.camposInativos.forEach(campo => campo.checked = true);
    }
  }
  selecionarTudoAtivos() {
    const algumSelecionado = this.camposAtivos.some(campo => campo.checked);
    if (algumSelecionado) {
      this.camposAtivos.forEach(campo => campo.checked = false);
    } else {
      this.camposAtivos.forEach(campo => campo.checked = true);
    }
  }
  selecionarTudoPensionistas() {
    const algumSelecionado = this.camposPensionistas.some(campo => campo.checked);
    if (algumSelecionado) {
      this.camposPensionistas.forEach(campo => campo.checked = false);
    } else {
      this.camposPensionistas.forEach(campo => campo.checked = true);
    }
  }

  async exportarBancoDeDadosAtivos() {
    this.closeModal();
    const self = this;
    if (!self.idCidade) {
      return;
    }
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await this.apiCall
      .get(`servidor/relatorio/brprev?id_cidade=${self.idCidade}`)
      .pipe(take(1))
      .toPromise();
    if (!servidores) {
      this.spinner.hide();
      return;
    }
    self.servidoresAtivos = [];
    self.servidoresPensionistas = [];
    self.servidoresInativos = [];
    let servidoresPorAno =  servidores.filter((s) => s.status != 5);
    for (let servidor of servidoresPorAno) {
      if (servidor.status !== null && servidor.status == 1) {
        let data = {
          NOME: servidor.nome,
          ANO: servidor.ano,
          MÊS: servidor.mes,
          "CO_IBGE": servidor.ibge,
          "NOME_DO_ENTE": servidor.nome_cidade,
          "SIGLA_ESTADO": servidor.estado_naturalidade,
          "COMPOSIÇÃO DA MASSA (A)": "1",
          "TIPO DO FUNDO (B)": servidor.fundo,
          CNPJ: servidor.cnpj,
          "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
          PODER: servidor.poder == null || servidor.poder == undefined ? "" : servidor.poder,
          TIPO: servidor.administracao == null || servidor.administracao == undefined ? "" : servidor.administracao,
          "A - POPULAÇÃO COBERTA": servidor.carreira == '21' ? "Militares em atividade" : "Ativos",
          "B- POPULAÇÃO COBERTA": servidor.carreira,
          "C - POPULAÇÃO COBERTA": servidor.populacao_coberta_c,
          MATRÍCULA: servidor.matricula,
          CPF: servidor.cpf,
          "PIS-PASEP": servidor.pis,
          SEXO: servidor.sexo,
          "ESTADO CIVIL": servidor.estadoCivil,
          "DATA DE NASCIMENTO": servidor.dataNascimento,
          "COD SITUAÇÃO FUNCIONAL": "",
          "TIPO DE VÍNCULO": "",
          "DATA DE INGRESSO NO SERV_PUBLICO": servidor.data_ingresso_serv,
          "DATA DE INGRESSO NO ENTE": servidor.dataIngressoEnte,
          "DATA DE INGRESSO NA CARREIRA ATUAL":
          servidor.dataIngressoCarreiraAtual,
          "IDENTIFICAÇÃO DA CARREIRA ATUAL": servidor.carreiraAtual,
          "DATA DE INÍCIO DE EXERCÍCIO NO CARGO ATUAL":
            servidor.dataInicioCargoAtual,
          "IDENTIFICAÇÃO DO CARGO ATUAL": servidor.cargoAtual,
          "BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO":
            servidor.salarioBaseMensal,
          "REMUNERAÇÃO MENSAL TOTAL DO SERVIDOR ATIVO": servidor.salarioTotal,
          "CONTRIBUIÇÃO MENSAL": "",
          "PRIMEIRA BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO": "",
          "TEMPO DE CONTRIBUIÇÃO DO SERVIDOR ATIVO ANTERIOR À ADMISSÃO NO ENTE PARA O RGPS":
            servidor.tempoServicoAnteriorTotal,
          // "TEMPO DE CONTRIBUIÇÃO DO SERVIDOR ATIVO ANTERIOR À ADMISSÃO NO ENTE PARA OUTROS RPPS":
          //     servidor.tempoServicoAnteriorTotal,
          "NÚMERO DE DEPENDENTES DO SERVIDOR ATIVO": servidor.totalDependentes,
          "DATA DE NASCIMENTO DO CÔNJUGE DO SERVIDOR ATIVO":
            servidor.dataNascimentoConjuge,
          "CONDIÇÃO DO CÔNJUGE": "1",
          "DATA DE NASCIMENTO DO DEPENDENTE 2":
            servidor.dataNascimentoDependente2,
          "CONDIÇÃO DO DEPENDENTE 2": "1",
          "DATA DE NASCIMENTO DO DEPENDENTE 3":
            servidor.dataNascimentoDependente3,
          "CONDIÇÃO DO DEPENDENTE 3": "1",
          "SEGURADO EM ABONO DE PERMANÊNCIA ": servidor.abono_permanencia,
          "DATA DE INÍCIO DE ABONO DE PERMANÊNCIA DO SERVIDOR ATIVO": servidor.data_abono,
          "PREVIDÊNCIA COMPLEMENTAR": "",
          "TETO REMUNERATÓRIO ESPECÍFICO": "",
        };

        const newData = {};
        for (const campo of this.camposAtivos) {
          if (campo.checked) {
            newData[campo.id] = data[campo.id];
          }
        }
        self.servidoresAtivos.push(newData);
      } 
     
    }
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(this.servidoresAtivos)),
      "servidoresativos"
    );

    this.spinner.hide();
  }
  async exportarBancoDeDadosInativos() {
    this.closeModal();
    const self = this;
    if (!self.idCidade) {
      return;
    }
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await this.apiCall
      .get(`servidor/relatorio/brprev?id_cidade=${self.idCidade}`)
      .pipe(take(1))
      .toPromise();
    if (!servidores) {
      this.spinner.hide();
      return;
    }
    self.servidoresAtivos = [];
    self.servidoresPensionistas = [];
    self.servidoresInativos = [];
    let servidoresPorAno =  servidores.filter((s) => s.status != 5);
    for (let servidor of servidoresPorAno) {
      if (servidor.status == 2 || servidor.status == null) {
        let data = {
          NOME: servidor.nome,
          ANO: servidor.ano,
          MÊS: servidor.mes,
          "CO_IBGE": servidor.ibge,
          "NOME_DO_ENTE": servidor.nome_cidade,
          "SG_UF": servidor.estado_naturalidade,
          "CO_COMP_MASSA": "1",
          "CO_TIPO_FUNDO": servidor.fundo,
          CNPJ: servidor.cnpj,
          "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
          "CO_PODER (CÓDIGO DO PODER)": servidor.poder,
          "CO_TIPO_PODER (CÓDIGO DO TIPO DE PODER)": servidor.tipo_poder,
          "CO_TIPO_INSTITUIDOR (CÓDIGO DO TIPO DE INSTITUIDOR)": servidor.administracao,
          "ID MATRICULA INSTITUIDOR": servidor.matricula_pensao,
          "INSTITUIDOR CPF": servidor.cpf_pensao,
          "INSTITUIDOR PIS PASEP": servidor.pis_pensao,
          "SEXO INSTITUIDOR": servidor.sexo,
          "DT_NASC_INSTITUIDOR":  servidor.data_nascimento_pensao,
          "DT_OBITO_INSTITUIDOR": servidor.data_falecimento_pensao,
          "MATRICULA_PENSIONISTA": servidor.matricula,
          "CPF_PENSIONISTA": servidor.cpf,
          "SEXO": servidor.sexo,
          "DT_NASC_PENSIONISTA": servidor.dataNascimento,
          "RELAÇÃO DO PENSIONISTA COM O INSTITUIDOR": servidor.relacao_titular_pensao,
          "DATA INICIO PENSAO":  servidor.concessao_aposentadoria,
          "VALOR BENEF PENSAO": servidor.salarioBaseMensal,
          "VALOR TOTAL DA PENSÃO": servidor.salarioBaseMensal,
          "VL_PCT_QUOTA": "",
          "VALOR CONTRIBUICAO": "",
          "VALOR MENSAL DA COMPENSAÇÃO PREVIDENCIÁRIA": "",
          "PARIDADE": servidor.paridade_aposentadoria,
          "CONDIÇÃO DO PENSIONISTA": servidor.deficiente,
          "DURAÇÃO DO BENEFÍCIO": servidor.tipo_pensao,
          "TEMPO DURAÇÃO DO BENEFÍCIO": "",
          "PREVIDÊNCIA COMPLEMENTAR": "",
          "TETO REMUNERATÓRIO ESPECÍFICO": "",
        };
      

        const newData = {};
        for (const campo of this.camposInativos) {
          if (campo.checked) {
            newData[campo.id] = data[campo.id];
          }
        }
        self.servidoresInativos.push(newData);
      } 
     
    }
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(this.servidoresInativos)),
      "servidoresinativos"
    );

    this.spinner.hide();
  }
  async exportarBancoDeDadosPensionistas() {
    this.closeModal();
    const self = this;
    if (!self.idCidade) {
      return;
    }
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await this.apiCall
      .get(`servidor/relatorio/brprev?id_cidade=${self.idCidade}`)
      .pipe(take(1))
      .toPromise();
    if (!servidores) {
      this.spinner.hide();
      return;
    }
    self.servidoresAtivos = [];
    self.servidoresPensionistas = [];
    self.servidoresInativos = [];
    let servidoresPorAno =  servidores.filter((s) => s.status != 5);
    for (let servidor of servidoresPorAno) {
      if (servidor.status !== null && servidor.status == 3) {
        let data = {
          NOME: servidor.nome,
          ANO: servidor.ano,
          MÊS: servidor.mes,
          "CO_IBGE": servidor.ibge,
          "NOME_DO_ENT": servidor.nome_cidade,
          "SIGLA_ESTADO": servidor.estado_naturalidade,
          "COMPOSIÇÃO DA MASSA (A)":  "1",
          "TIPO DE FUNDO (B)": servidor.fundo,
          CNPJ: servidor.cnpj,
          "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
          PODER: servidor.poder == null || servidor.poder == undefined ? "" : servidor.poder ,
          "TIPO_AUTARQUIA": servidor.administracao,
          "CO_TIPO_POPULACAO": servidor.co_tipo_populacao,
          "TIPO_CARGO": servidor.tipo_cargo,
          "CO_TIPO_APOSENTADORIA": servidor.tipo_aposentadoria == null || servidor.tipo_aposentadoria == undefined ? "" : servidor.tipo_aposentadoria,
          "MATRÍCULA": servidor.matricula,
          "CPF": servidor.cpf,
          "PIS-PASEP": servidor.pis,
          "SEXO DO APOSENTADO": servidor.sexo,
          "ESTADO CIVIL": servidor.estadoCivil,
          "DATA DE NASCIMENTO": servidor.dataNascimento,
          "DT_ING_SERV_PUB": servidor.data_ingresso_serv,
          "DATA DE INGRESSO NO ENTE": servidor.dataIngressoEnte,
          "DT_INICIO_APOSENTADORIA": servidor.concessao_aposentadoria,
          "VL_APOSENTADORIA":
            servidor.salarioBaseMensal,
          "VL_CONTRIBUICAO": "",
          "VL_COMPENS_PREVID": "",
          "IN_PARID_SERV": servidor.paridade_aposentadoria,
          "CO_CONDICAO_APOSENTADO": servidor.deficiente,
          "NU_TEMPO_RGPS_MUN": "",
          "NU_TEMPO_RPPS": "",
          "NU_DEPENDENTES": servidor.totalDependentes,
          "DATA DE NASCIMENTO DO CÔNJUGE":
            servidor.dataNascimentoConjuge,
          "CONDIÇÃO DO CÔNJUGE": "1",
          "DATA DE NASCIMENTO DO DEPENDENTE 2":
            servidor.dataNascimentoDependente2,
          "CONDIÇÃO DO DEPENDENTE 2": "1",
          "DATA DE NASCIMENTO DO DEPENDENTE 3":
            servidor.dataNascimentoDependente3,
          "CONDIÇÃO DO DEPENDENTE 3": "1",
          "PREVIDÊNCIA COMPLEMENTAR": "",
          "TETO REMUNERATÓRIO ESPECÍFICO": "",
        };
      

        const newData = {};
        for (const campo of this.camposPensionistas) {
          if (campo.checked) {
            newData[campo.id] = data[campo.id];
          }
        }
        self.servidoresPensionistas.push(newData);
      } 
     
    }
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(this.servidoresPensionistas)),
      "servidorespensionistas"
    );

    this.spinner.hide();
  }
}
