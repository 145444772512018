import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import * as moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import { HttpClient } from "@angular/common/http";
import { ExcelService } from "../helpers/excel.service";

@Component({
  selector: "app-servidores",
  templateUrl: "./servidores.component.html",
  styleUrls: ["./servidores.component.scss"],
})
export class ServidoresComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1;
  pageSize = 10;
  urlToShow = null;
  idCidade;
  cidade = null;
  nomeCidade : string;
  isAdmin = false;
  tempoTotalCadastrado = 0
  tempoFiltradoCadastrado= 0
  tipo;
  servidor = {
    cpf: null,
    cidade_naturalidade: null,
    data_ingresso_servico_publico: null,
    data_nascimento: null,
    deficiente: null,
    data_falecimento: null,
    documento_ingresso_servico_publico: null,
    escolaridade: null,
    estado_civil: null,
    estado_naturalidade: null,
    id_cidade: null,
    nacionalidade: null,
    naturalizado: null,
    nome: null,
    nome_mae: null,
    nome_pai: null,
    sexo: null,
    status: null,
    paridade_aposentadoria: null,
    concessao_aposentadoria: null,
    valor_aposentadoria: null,
    cargo_aposentadoria: null,
    pis_aposentado: null,
    tipo_aposentadoria: null,
    cpf_pensao: null,
    pis_pensao: null,
    nome_pensao: null,
    matricula_pensao: null,
    data_nascimento_pensao: null,
    data_falecimento_pensao: null,
    matricula_beneficiario: null,
    tipo_pensao: null,
    valor_beneficio: null,
    motivo_exoneracao: null,
    telefone: null,
    matricula: null,
    carga_horaria: null,
    imagem: null,
    rg: null,
    salario: null,
    cargo: null
  };



  tiposaposentadoria = [
    { name: "Aposentadoria por Idade", value: 1, group: "Para Civis" },
    {
      name: "Aposentadoria por Tempo de Contribuição",
      value: 2,
      group: "Para Civis",
    },
    { name: 'Aposentadoria por tempo de contribuição e idade', value: 10, group: 'Para Civis' },
    { name: "Aposentadoria Compulsória", value: 3, group: "Para Civis" },
    { name: "Aposentadoria por Invalidez", value: 4, group: "Para Civis" },
    { name: "Aposentadoria como Professor", value: 5, group: "Para Civis" },
    {
      name: "Aposentadoria Especial - atividade de risco (Art. 40, § 4o, inc. II, CF)",
      value: 6,
      group: "Para Civis",
    },
    {
      name: "Aposentadoria Especial - atividade prejudiciais à saúde ou integridade física (Art. 40, § 4o, inc. III, CF)",
      value: 7,
      group: "Para Civis",
    },
    {
      name: "Militares Inativos - Reserva Remunerada",
      value: 8,
      group: "Militares Inativos",
    },
    {
      name: "Militares Inativos - Reforma",
      value: 9,
      group: "Militares Inativos",
    },
  ];
  servidores = [];
  selectedItems: boolean[] = [false, false, false, false];
  servidoresFiltered = [];
  filters = [
    "cpf",
    "matricula",
    "data de nascimento",
    "data de cadastro",
    "status",
    "nome",
  ];
  tipospensao = [
    { name: "Vitalícia", value: 1 },
    { name: "Temporária", value: 2 },
  ];
  selectedFilter = null;
  statusServidor = [
    {
      id: 1,
      name: "Ativo",
    },
    {
      id: 2,
      name: "Aposentado",
    },
    {
      id: 3,
      name: "Pensionista",
    },
    {
      id: 4,
      name: "Exonerado",
    },
    {
      id: 5,
      name: "Falecido",
    },
  ];
  sexos = [
    {
      id: "M",
      name: "Masculino",
    },
    {
      id: "F",
      name: "Feminino",
    },
  ];
  naturalizados = [
    {
      id: 1,
      name: "Sim",
    },
    {
      id: 2,
      name: "Não",
    },
  ];
  estados = [];
  cidades = [];
  estadosCivis = [];
  dependentes = [];
  dependentesToShow = [];
  escolaridades = [];
  tiposDependencia = [];
  planilhas = [];
  tempos = [];
  status = [
    {
      id: true,
      name: "Ativo",
    },
    {
      id: false,
      name: "Inativo",
    },
  ];
  filterName;
  filterCpf;
  filterMatricula;
  filterNascimento;
  filterCadastro;
  filterSexo;
  filterStatus;

  colunasEsperadasInativos = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_POPULACAO",
    "CO_TIPO_CARGO",
    "CO_TIPO_APOSENTADORIA",
    "ID_APOSENTADO_MATRICULA",
    "ID_APOSENTADO_CPF",
    "ID_APOSENT_PIS_PASEP",
    "CO_SEXO_APOSENTADO",
    "CO_EST_CIVIL_APOSENTADO",
    "DT_NASC_APOSENTADO",
    "DT_INICIO_APOSENTADORIA",
    "VL_APOSENTADORIA",
    "VL_CONTRIBUICAO",
    "IN_PARID_SERV",
    "CO_CONDICAO_APOSENTADO",
    "VL_COMPENS_PREVID",
    "NU_DEPENDENTES",
    "DT_NASC_CONJUGE",
    "CO_CONDICAO_CONJUGE",
    "DT_NASC_DEPENDENTE_1",
    "CO_CONDICAO_DEPENDENTE_1",
    "DT_NASC_DEPENDENTE_2",
    "CO_CONDICAO_DEPENDENTE_2",
  ];

  colunasEsperadasAposentados = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_INSTITUIDOR",
    "ID_INSTITUIDOR_MATRICULA",
    "ID_INSTITUIDOR_CPF",
    "ID_INSTITUIDOR_PIS_PASEP",
    "ID_PENSIONISTA_CPF",
    "CO_SEXO_PENSIONISTA",
    "DT_NASC_PENSIONISTA",
    "DT_INICIO_PENSAO",
    "VL_BENEF_PENSAO",
    "VL_CONTRIBUICAO",
    "VL_COMPENS_PREVID",
    "IN_PARID_SERV",
    "CO_CONDICAO",
    "CO_DURACAO",
  ];
  colunasEsperadasSimplificado = [
    "CO_IBGE",
    "ID_SERVIDOR_MATRICULA",
    "ID_SERVIDOR_CPF",
    "STATUS_SERVIDOR",
    "NOME_SERVIDOR"
  ]
  colunasEsperadasIdadeAposentadoria = [
    "CO_IBGE",
    "SERVIDOR_MATRICULA",
    "SERVIDOR_IDADE",
  ]
  colunasEsperadasAtivos = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "NU_CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_POPULACAO",
    "CO_TIPO_CARGO",
    "CO_CRITERIO_ELEGIBILIDADE",
    "ID_SERVIDOR_MATRICULA",
    "ID_SERVIDOR_CPF",
    "ID_SERVIDOR_PIS_PASEP",
    "CO_SEXO_SERVIDOR",
    "CO_EST_CIVIL_SERVIDOR",
    "DT_NASC_SERVIDOR",
    "CO_SITUACAO_FUNCIONAL",
    "CO_TIPO_VINCULO",
    "DT_ING_SERV_PUB",
    "DT_ING_ENTE",
    "DT_ING_CARREIRA",
    "NO_CARREIRA",
    "DT_ING_CARGO",
    "NO_CARGO",
    "VL_BASE_CALCULO",
    "VL_REMUNERACAO",
    "NU_TEMPO_RGPS",
    "NU_TEMPO_RPPS",
    "NU_DEPENDENTES",
    "DT_NASC_CONJUGE",
    "CO_COND_CONJUGE",
    "DT_NASC_DEPENDENTE_1",
    "CO_COND_DEPENDENTE_1",
    "DT_NASC_DEPENDENTE_2",
    "CO_COND_DEPENDENTE_2",
    "IN_ABONO_PERMANENCIA",
    "DT_INICIO_ABONO",
    "DT_PROV_APOSENT",
  ];
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private http: HttpClient,
    private api: Api
  ) {}



  selectFilter(event) { 
    this.filterName = null;
    this.filterCpf = null;
    this.filterMatricula = null;
    this.filterNascimento = null;
    this.filterCadastro = null;
    this.filterSexo = null;
    this.filterStatus = null;
    this.servidoresFiltered = this.servidores;
  }
  beautifulUrl(url) {
    if (url) {
      return url.replace(
        "https://cadastro-pessoas-arquivos-servidor.s3.amazonaws.com/",
        ""
      );
    } else {
      return "";
    }
  }
  async ngOnInit() {
    const self = this;
    self.servidor = {
      cpf: null,
      cidade_naturalidade: null,
      data_ingresso_servico_publico: null,
      data_nascimento: null,
      deficiente: null,
      documento_ingresso_servico_publico: "",
      escolaridade: null,
      data_falecimento: null,
      estado_civil: null,
      estado_naturalidade: null,
      id_cidade: null,
      nacionalidade: null,
      naturalizado: null,
      nome: null,
      nome_mae: null,
      nome_pai: null,
      sexo: null,
      status: null,
      paridade_aposentadoria: null,
      concessao_aposentadoria: null,
      valor_aposentadoria: null,
      cargo_aposentadoria: null,
      pis_aposentado: null,
      tipo_aposentadoria: null,
      cpf_pensao: null,
      pis_pensao: null,
      nome_pensao: null,
      matricula_pensao: null,
      data_nascimento_pensao: null,
      data_falecimento_pensao: null,
      matricula_beneficiario: null,
      tipo_pensao: null,
      valor_beneficio: null,
      motivo_exoneracao: null,
      telefone: null,
      matricula: null,
      carga_horaria: null,
      imagem: null,
      rg: null,
      salario: null,
      cargo: null
    };
    self.tempoTotalCadastrado = 0
    self.estados = [];
    self.cidades = [];
    self.estadosCivis = [];
    self.escolaridades = [];
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.cidade;
    console.log('json.user', json.user)
    self.nomeCidade = json.user.nomeCidade;
    self.isAdmin = json.user.tipo === "1";
    if (cidade) {
      self.idCidade = cidade;
    }
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (json.user.tipo === "1") {
      console.log("caiu if")
      self.route.queryParams.subscribe(async (parametros) => {
        if (parametros["cidade"]) {
          let idCidade = parametros["cidade"];
          self.idCidade = idCidade;
          json.user.cidade = idCidade;
          self.me.atualizaCidade(json);
          const planilhas = await self.apiCall
            .get("servidor/arquivo/planilha?id_cidade=" + self.idCidade)
            .pipe(take(1))
            .toPromise();
          const responseCidade = await self.apiCall
            .get("enderecos/cidade?id=" + self.idCidade)
            .pipe(take(1))
            .toPromise();
          self.planilhas = JSON.parse(JSON.stringify(planilhas));
          if (responseCidade && responseCidade[0])
            self.cidade = responseCidade[0];
            self.nomeCidade = self.cidade.nome;
          self.apiCall
            .get("servidor/todos/cidade?id_cidade=" + idCidade)
            .subscribe((data) => {
              let lista = data
              ? JSON.parse(JSON.stringify(data)).reverse()
              : []
              let listafinal = []
              if(lista.length>0){
                let lista1 = lista.filter(x=>x.temDependenteMaiorDe21)
                let lista2 = lista.filter(x=>x.temTetoINSS)
                let lista3 = lista.filter(x=>x.pendencias)
                listafinal = [...lista1]
                for(let l of lista2){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of lista3){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of lista){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of listafinal){
                  let duasmatriculas = listafinal.filter(x=>x.cpf&&x.cpf==l.cpf)
                  if(duasmatriculas.length>1){
                    l.duasmatriculas = true
                  }
                }
              }

            
            
              self.servidores =[...listafinal];
              self.servidoresFiltered =[...listafinal];
              console.log(listafinal, lista.length)
              self.spinner.hide();
            });
        } else {
          console.log("caiu else")
          self.apiCall
            .get("servidor/todos/cidade?id_cidade=" + cidade)
            .subscribe((data) => {
              let lista = data
              ? JSON.parse(JSON.stringify(data)).reverse()
              : []
              let listafinal = []
              if(lista.length>0){
                let lista1 = lista.filter(x=>x.temDependenteMaiorDe21)
                let lista2 = lista.filter(x=>x.temTetoINSS)
                let lista3 = lista.filter(x=>x.pendencias)
                listafinal = [...lista1]
                for(let l of lista2){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of lista3){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of lista){
                  if(!listafinal.find(x=>x==l)){
                    listafinal.push(l)
                  }
                }
                for(let l of listafinal){
                  let duasmatriculas = null
                  duasmatriculas = listafinal.filter(x=>x.cpf==l.cpf)
  
                  if(duasmatriculas.length>1){
                    l.duasmatriculas = true
                  }
                }
              }
              self.servidores =[...listafinal];
              self.servidoresFiltered =[...listafinal];
              console.log('ta aq', listafinal)
              self.spinner.hide();
            });
        }
      });
    } else {
      console.log("Else do else")
      const planilhas = await self.apiCall
        .get("servidor/arquivo/planilha?id_cidade=" + cidade)
        .pipe(take(1))
        .toPromise();
      self.planilhas = JSON.parse(JSON.stringify(planilhas));
      self.apiCall
        .get("servidor/todos/cidade?id_cidade=" + cidade)
        .subscribe((data) => {

          let lista = data  ? JSON.parse(JSON.stringify(data)).reverse() : []

          for(let l of lista){
            let duasmatriculas = null
            duasmatriculas = lista.filter(x=>x.cpf==l.cpf)

            if(duasmatriculas.length>1){
              l.duasmatriculas = true
            }
          }

          self.servidores = lista;
          self.servidoresFiltered = lista;


          /*self.servidores = data
            ? JSON.parse(JSON.stringify(data)).reverse()
            : [];
          self.servidoresFiltered = data
            ? JSON.parse(JSON.stringify(data)).reverse()
            : [];*/
          self.spinner.hide();
        });
    }

    const estados = await self.apiCall
      .get("enderecos/estados")
      .pipe(take(1))
      .toPromise();
    self.estados = JSON.parse(JSON.stringify(estados));
    const escolaridades = await self.apiCall
      .get("servidor/escolaridade")
      .pipe(take(1))
      .toPromise();
    self.escolaridades = JSON.parse(JSON.stringify(escolaridades));
    const estadosCivis = await self.apiCall
      .get("servidor/estadocivil")
      .pipe(take(1))
      .toPromise();
    self.estadosCivis = JSON.parse(JSON.stringify(estadosCivis));
  }
  openModalXl(content) {
    const modal = this.modalService.open(content, {
      centered: true,
      size: "xl",
    });
  }
  toggleSelected(index: number) {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.selectedItems[i] = (i === index) && !this.selectedItems[index];
    }
    this.servidoresFiltered = this.servidores;
    if(this.selectedItems[0]){
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.pendencias
      );
    }
    if(this.selectedItems[1]){
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.temDependenteMaiorDe21
      );
    }
    if(this.selectedItems[2]){
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.temTetoINSS
      );
      
    }
    if(this.selectedItems[3]){
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.duasmatriculas
      );
      this.servidoresFiltered.sort((a, b) => a.nome.localeCompare(b.nome));
      
    }

  }
  getYear(data){
    return moment(data).format('YYYY')
  }
  classificaStatus(status){
    if(!status){
      return ''
    }
    if(status==1){
      return 'Ativo'
    }
    if(status==2){
      return 'Aposentado'
    }
    if(status==3){
      return 'Pensionista'
    }
    if(status==4){
      return 'Exonerado'
    }
    if(status==5){
      return 'Falecido'
    }
  }
  viewMidia(urlToShow, content) {
    this.urlToShow = urlToShow;
    this.modalService.open(content, { centered: true });
  }
  //var csv is the CSV file with headers
  csvJSON(csv) {
    let lines = csv.split("\r\n");

    if(lines.length==1){
    lines = csv.split("\n");
    }

    var result = [];

    console.log(lines)

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split(";");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(";");
      if (currentline.length > 1) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }
  getColor(servidor){
    if(servidor.pendencias){
      return '#ffcfcf'
    }
    if(servidor.temDependenteMaiorDe21){
      return '#ffffcb'
    }
    if(servidor.temTetoINSS){
      return '#d9eeff'
    }
    return 'none'
  }
  async selecionaArquivo(type) {
    const self = this;
    const { value: file } = await Swal.fire({
      title:
        type == 1
          ? "Upload Servidores"
          : type == 2
          ? "Upload Aposentados"
          : "Upload Pensionistas",
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
    });

    if (!file) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar uma planilha!",
        "error"
      );
      return;
    }

    if (!file.name.endsWith("csv")) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar planilhas no formato .csv!",
        "error"
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      let text = reader.result;
      let csvJson = self.csvJSON(text);
      let lista = JSON.parse(csvJson);
      let newLista = [];
      for (let l of lista) {
        // @ts-ignore
        const trimmed = Object.entries(l).reduce((acc, curr) => {
          let [key, value] = curr;
          // Checking if the key is a string
          acc[typeof key === "string" ? key.trim() : key] = value;
          return acc;
        }, {});
        newLista.push(trimmed);
      }
      lista = [...newLista];
      let cols = lista ? Object.keys(lista[0]) : [];
      let totalCol = lista ? Object.keys(lista[0]).length : 0;
      let totalDeLinhas = lista.length;
      let tipo = file.type;
      let extension = file.name.split(".").pop().toLowerCase();
      const uploadFiles = await this.apiCall
        .post("servidor/arquivo/exact/url", {
          extension: file.name,
          type: tipo,
        })
        .pipe(take(1))
        .toPromise();

      //total de colunas
      //se tem todas colunas esperadas
      //se colunas de data tem data
      //se contem somente 1 codigo ibge na tabela
      //se codigo ibge corresponde com a cidade corrente

      if (type == 1) {
        if (totalCol != 41) {
          await this.app.alert(
            "Atenção!",
            "Quantidade de colunas do seu arquivo é diferente da esperada!",
            "error"
          );
          return;
        }
        const array2Sorted = this.colunasEsperadasAtivos.slice().sort();
        const isEqual =
          cols.length === this.colunasEsperadasAtivos.length &&
          cols
            .slice()
            .sort()
            .every(function (value, index) {
              return value === array2Sorted[index];
            });
        if (!isEqual) {
          await this.app.alert(
            "Atenção!",
            "O cabeçalho do seu arquivo é diferente do esperado!",
            "error"
          );
          return;
        }
      } else if (type == 2) {
        if (totalCol != 32) {
          await this.app.alert(
            "Atenção!",
            "Quantidade de colunas do seu arquivo é diferente da esperada!",
            "error"
          );
          return;
        }
        const array2Sorted = this.colunasEsperadasInativos.slice().sort();
        const isEqual =
          cols.length === this.colunasEsperadasInativos.length &&
          cols
            .slice()
            .sort()
            .every(function (value, index) {
              return value === array2Sorted[index];
            });
        if (!isEqual) {
          await this.app.alert(
            "Atenção!",
            "O cabeçalho do seu arquivo é diferente do esperado!",
            "error"
          );
          return;
        }
      } else if (type == 3) {
        if (totalCol != 24) {
          await this.app.alert(
            "Atenção!",
            "Quantidade de colunas do seu arquivo é diferente da esperada!",
            "error"
          );
          return;
        }
        const array2Sorted = this.colunasEsperadasAposentados.slice().sort();
        const isEqual =
          cols.length === this.colunasEsperadasAposentados.length &&
          cols
            .slice()
            .sort()
            .every(function (value, index) {
              return value === array2Sorted[index];
            });
        if (!isEqual) {
          await this.app.alert(
            "Atenção!",
            "O cabeçalho do seu arquivo é diferente do esperado!",
            "error"
          );
          return;
        }
      }
      let colunasDeData = cols.filter((x) => x.toString().startsWith("DT_"));
      const invalidDates = lista.filter(
        (l) =>
          colunasDeData.filter(
            (c) => l[c] && !moment(l[c], "DD/MM/YYYY").isValid()
          ).length > 0
      );
      if (invalidDates.length > 0) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém datas inválidas!",
          "error"
        );
        return;
      }
      let ibgeFound = [];
      for (let l of lista) {
        if (l.CO_IBGE && !ibgeFound.find((i) => i == l.CO_IBGE)) {
          ibgeFound.push(l.CO_IBGE);
        }
      }
      if (ibgeFound.length !== 1) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém mais de um código IBGE!",
          "error"
        );
        return;
      }
      if (self.cidade && self.cidade.ibge && ibgeFound[0] != self.cidade.ibge) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém código IBGE inválido! o código esperado para esta cidade é " +
            self.cidade.ibge,
          "error"
        );
        return;
      }
      if (!uploadFiles || !uploadFiles.result) {
        return;
      }
      var options = {
        headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
      };

      const resultado = await self.http
        .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
        .toPromise();
      let urlArquivo = uploadFiles.result.split("?")[0];

      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      await this.apiCall
        .post(
          `servidor/arquivo/planilha?tipo=${type}&cidade=${self.idCidade}&url=${urlArquivo}&total_rows=${totalDeLinhas}`
        )
        .pipe(take(1))
        .toPromise();
      await self.app.alert(
        "Tudo certo!",
        "Planilha cadastrada com sucesso!",
        "success"
      );

      await self.spinner.hide();
      await self.ngOnInit();
    };
    reader.readAsText(file as Blob, "ISO-8859-1");
  }
  async uploadSimplificado() {
    const self = this;
    const { value: file } = await Swal.fire({
      title: "Upload Simplificado Servidores",
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
    });

    if (!file) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar uma planilha!",
        "error"
      );
      return;
    }

    /*if (!file.name.endsWith("csv")) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar planilhas no formato .csv!",
        "error"
      );
      return;
    }*/
    const reader = new FileReader();
    reader.onload = async () => {
      let text = reader.result;
      let csvJson = self.csvJSON(text);
      console.log(text)
      let lista = JSON.parse(csvJson);
      let newLista = [];
      for (let l of lista) {
        // @ts-ignore
        const trimmed = Object.entries(l).reduce((acc, curr) => {
          let [key, value] = curr;
          // Checking if the key is a string
          acc[typeof key === "string" ? key.trim() : key] = value;
          return acc;
        }, {});
        newLista.push(trimmed);
      }
      lista = [...newLista];
      let cols = lista ? Object.keys(lista[0]) : [];
      let totalCol = lista ? Object.keys(lista[0]).length : 0;
      let totalDeLinhas = lista.length;
      let tipo = file.type;
      let extension = file.name.split(".").pop().toLowerCase();
      const uploadFiles = await this.apiCall
        .post("servidor/arquivo/exact/url", {
          extension: file.name,
          type: tipo,
        })
        .pipe(take(1))
        .toPromise();

      //total de colunas
      //se tem todas colunas esperadas
      //se colunas de data tem data
      //se contem somente 1 codigo ibge na tabela
      //se codigo ibge corresponde com a cidade corrente

      if (totalCol != 5) {
        await this.app.alert(
          "Atenção!",
          "Quantidade de colunas do seu arquivo é diferente da esperada!",
          "error"
        );
        return;
      }
      const array2Sorted = this.colunasEsperadasSimplificado.slice().sort();
      const isEqual =
        cols.length === this.colunasEsperadasSimplificado.length &&
        cols
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          });
      if (!isEqual) {
        await this.app.alert(
          "Atenção!",
          "O cabeçalho do seu arquivo é diferente do esperado!",
          "error"
        );
        return;
      }

      let ibgeFound = [];
      for (let l of lista) {
        if (l.CO_IBGE && !ibgeFound.find((i) => i == l.CO_IBGE)) {
          ibgeFound.push(l.CO_IBGE);
        }
      }
      if (ibgeFound.length !== 1) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém mais de um código IBGE!",
          "error"
        );
        return;
      }
      if (self.cidade && self.cidade.ibge && ibgeFound[0] != self.cidade.ibge) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém código IBGE inválido! o código esperado para esta cidade é " +
            self.cidade.ibge,
          "error"
        );
        return;
      }
      if (!uploadFiles || !uploadFiles.result) {
        return;
      }
      var options = {
        headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
      };

      const resultado = await self.http
        .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
        .toPromise();
      let urlArquivo = uploadFiles.result.split("?")[0];

      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      await this.apiCall
        .post(
          `servidor/arquivo/planilha?tipo=${9}&cidade=${self.idCidade}&url=${urlArquivo}&total_rows=${totalDeLinhas}&simplificada=true`
        )
        .pipe(take(1))
        .toPromise();
      await self.app.alert(
        "Tudo certo!",
        "Planilha cadastrada com sucesso!",
        "success"
      );
      await self.spinner.hide();
      await self.ngOnInit();
    };
    reader.readAsText(file as Blob, "ISO-8859-1");
  }
  async uploadIdadeAposentadoria() {
    const self = this;
    const { value: file } = await Swal.fire({
      title: "Upload Planilha Idade Aposentadoria",
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
    });

    if (!file) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar uma planilha!",
        "error"
      );
      return;
    }

    if (!file.name.endsWith("csv")) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar planilhas no formato .csv!",
        "error"
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      let text = reader.result;
      let csvJson = self.csvJSON(text);

      let lista = JSON.parse(csvJson);
      let newLista = [];
      for (let l of lista) {
        // @ts-ignore
        const trimmed = Object.entries(l).reduce((acc, curr) => {
          let [key, value] = curr;
          // Checking if the key is a string
          acc[typeof key === "string" ? key.trim() : key] = value;
          return acc;
        }, {});
        newLista.push(trimmed);
      }
      lista = [...newLista];
      let cols = lista ? Object.keys(lista[0]) : [];
      let totalCol = lista ? Object.keys(lista[0]).length : 0;
      let totalDeLinhas = lista.length;
      let tipo = file.type;
      let extension = file.name.split(".").pop().toLowerCase();
      const uploadFiles = await this.apiCall
        .post("servidor/arquivo/exact/url", {
          extension: file.name,
          type: tipo,
        })
        .pipe(take(1))
        .toPromise();

      if (totalCol != 3) {
        await this.app.alert(
          "Atenção!",
          "Quantidade de colunas do seu arquivo é diferente da esperada!",
          "error"
        );
        return;
      }
      const array2Sorted = this.colunasEsperadasIdadeAposentadoria.slice().sort();
      const isEqual =
        cols.length === this.colunasEsperadasIdadeAposentadoria.length &&
        cols
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          });
      if (!isEqual) {
        await this.app.alert(
          "Atenção!",
          "O cabeçalho do seu arquivo é diferente do esperado!",
          "error"
        );
        return;
      }

      let ibgeFound = [];
      for (let l of lista) {
        if (l.CO_IBGE && !ibgeFound.find((i) => i == l.CO_IBGE)) {
          ibgeFound.push(l.CO_IBGE);
        }
      }
      if (ibgeFound.length !== 1) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém mais de um código IBGE!",
          "error"
        );
        return;
      }
      if (self.cidade && self.cidade.ibge && ibgeFound[0] != self.cidade.ibge) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém código IBGE inválido! o código esperado para esta cidade é " +
            self.cidade.ibge,
          "error"
        );
        return;
      }
      if (!uploadFiles || !uploadFiles.result) {
        return;
      }
      var options = {
        headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
      };

      const resultado = await self.http
        .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
        .toPromise();
      let urlArquivo = uploadFiles.result.split("?")[0];

      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      await this.apiCall
        .post(
          `servidor/arquivo/planilha?tipo=${8}&cidade=${self.idCidade}&url=${urlArquivo}&total_rows=${totalDeLinhas}`
        )
        .pipe(take(1))
        .toPromise();
      await self.app.alert(
        "Tudo certo!",
        "Planilha cadastrada com sucesso!",
        "success"
      );
      await self.spinner.hide();
      await self.ngOnInit();
    };
    reader.readAsText(file as Blob, "ISO-8859-1");
  }
  async alterarStatusServidor(id, ativo) {
    if (ativo) {
      ativo = 0;
    } else {
      ativo = 1;
    }
    const deleteDependente = await this.apiCall
      .get(`servidor/atualiza-status?id_servidor=${id}&ativo=${ativo}`)
      .pipe(take(1))
      .toPromise();
    this.ngOnInit();
  }
  async deletarServidor(id) {
    const self = this;
    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar este servidor?`,
        `Deletar`
      )
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      await self.apiCall
        .get(`servidor/deletar?id_servidor=${id}`)
        .pipe(take(1))
        .toPromise();
      await self.spinner.hide();
      await self.app.alert(`Sucesso`, `Servidor deletado!`, `success`);
      await self.ngOnInit();
    }
  }
  async getRelatorioTempoServicoPassado() {
    const self = this;

    let tempos = [];

    if (!self.idCidade) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await self.apiCall
      .get("servidor/relatorio/tempo?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();
    console.log(servidores);
    for (let i in servidores) {
      const servidor = servidores[i];
      let data = {
        NOME_SERVIDOR: servidor.nome,
        CPF_CNPJ_EMPREGADOR: servidor.cnpj_cpf_empregador,
        DATA_INICIO: servidor.data_entrada,
        DATA_FIM: servidor.data_fim,
        TEMPO_BRUTO: servidor.tempo_bruto,
        NUMERO_CERTIDAO: servidor.numero_certidao,
        DATA_CERTIDAO: servidor.data_certidao,
      };
      tempos.push(data);
    }
    await self.spinner.hide();
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(tempos)),
      "servidores_tempo_passado"
    );
  }
  async getRelatorioAtendimento() {
    const self = this;


    if (!self.idCidade) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await self.apiCall
      .get("servidor/atendimento/relatorio?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();
    console.log('servidores', servidores)

    await self.spinner.hide();
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(servidores ? servidores : [])),
      "servidores_atendimento"
    );
  }

  async getRelatoriosCidade() {
    const self = this;

    let servidoresAtivos = [];
    let servidoresPensionistas = [];
    let servidoresAposentados = [];
    if (!self.idCidade) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    console.log("1");
    const servidores = await self.apiCall
      .get("servidor/relatorio/cidade?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();
    for (let i in servidores) {
      const servidor = servidores[i];
      //if ( servidor.status == '1' || (!servidor.status && servidor.statusServidor == "1")) {
      if ( servidor.status == '1') {
        let data = {
          NU_ANO: servidor.ano,
          CO_IBGE: servidor.ibge,
          NO_ENTE: servidor.cidade,
          SG_UF: servidor.estado,
          CO_COMP_MASSA: "1",
          CO_TIPO_FUNDO: "1",
          NU_CNPJ_ORGAO: servidor.cnpj,
          NO_ORGAO: servidor.orgao,
          CO_PODER: servidor.poder,
          CO_TIPO_PODER: "",
          CO_TIPO_POPULACAO: "1",
          CO_TIPO_CARGO: "",
          CO_CRITERIO_ELEGIBILIDADE: "",
          ID_SERVIDOR_MATRICULA: servidor.matricula,
          ID_SERVIDOR_CPF: servidor.cpf,
          ID_SERVIDOR_PIS_PASEP: servidor.pis,
          CO_SEXO_SERVIDOR: servidor.sexo,
          CO_EST_CIVIL_SERVIDOR: servidor.estadoCivil,
          DT_NASC_SERVIDOR: servidor.dataNascimento,
          CO_SITUACAO_FUNCIONAL: "1",
          CO_TIPO_VINCULO: "1",
          DT_ING_SERV_PUB: servidor.dataIngressoServicoPublico,
          DT_ING_ENTE: servidor.dataIngressoEnte,
          DT_ING_CARREIRA: servidor.dataIngressoCarreiraAtual,
          NO_CARREIRA: servidor.carreiraAtual,
          DT_ING_CARGO: servidor.dataInicioCargoAtual,
          NO_CARGO: servidor.cargoAtual,
          VL_BASE_CALCULO: servidor.salarioBaseMensal,
          VL_REMUNERACAO: servidor.salarioTotal,
          NU_TEMPO_RGPS: servidor.tempoServicoAnteriorTotalRgps,
          NU_TEMPO_RPPS: servidor.tempoServicoAnteriorTotalRpps,
          NU_DEPENDENTES: servidor.totalDependentes,
          DT_NASC_CONJUGE: servidor.dataNascimentoConjuge,
          CO_COND_CONJUGE: "1",
          DT_NASC_DEPENDENTE_1: servidor.dataNascimentoDependente2,
          CO_COND_DEPENDENTE_1: "1",
          DT_NASC_DEPENDENTE_2: servidor.dataNascimentoDependente3,
          CO_COND_DEPENDENTE_2: "1",
          "IN_ABONO_PERMANENCIA ": "",
          DT_INICIO_ABONO: "",
          DT_PROV_APOSENT: "",
        };
        servidoresAtivos.push(data);
     // } else if (servidor.status == '3' || (!servidor.status && servidor.statusServidor == "4")) {
      } else if (servidor.status == '3') {
        if(servidor.cpf && servidor.cpf.startsWith('607') )console.log('servidor pensionista', servidor)
        let data = {
          NU_ANO: servidor.ano,
          CO_IBGE: servidor.ibge,
          NO_ENTE: servidor.cidade,
          SG_UF: servidor.estado,
          CO_COMP_MASSA: "1",
          CO_TIPO_FUNDO: "1",
          CNPJ_ORGAO: servidor.cnpj,
          NO_ORGAO: servidor.orgao,
          CO_PODER: servidor.poder,
          CO_TIPO_PODER: "",
          CO_TIPO_INSTITUIDOR: "",
          ID_INSTITUIDOR_MATRICULA: "",
          ID_INSTITUIDOR_CPF: "",
          ID_INSTITUIDOR_PIS_PASEP: "",
          ID_PENSIONISTA_CPF: servidor.cpf,
          CO_SEXO_PENSIONISTA: servidor.sexo,
          DT_NASC_PENSIONISTA: servidor.dataNascimento,
          DT_INICIO_PENSAO: servidor.dataIngressoCarreiraAtual,
          VL_BENEF_PENSAO: servidor.salarioBaseMensal,
          VL_CONTRIBUICAO: "",
          VL_COMPENS_PREVID: "",
          IN_PARID_SERV: "",
          CO_CONDICAO: "",
          CO_DURACAO: "",
        };
        servidoresPensionistas.push(data);
     // } else if (servidor.status == '2' || (!servidor.status && servidor.statusServidor == "3")) {
      } else if (servidor.status == '2') {
        console.log('servidor aposentado', servidor)
        let data = {
          NU_ANO: servidor.ano,
          CO_IBGE: servidor.ibge,
          NO_ENTE: servidor.cidade,
          SG_UF: servidor.estado,
          CO_COMP_MASSA: "1",
          CO_TIPO_FUNDO: "1",
          CNPJ_ORGAO: servidor.cnpj,
          NO_ORGAO: servidor.orgao,
          CO_PODER: servidor.poder,
          CO_TIPO_PODER: "",
          CO_TIPO_POPULACAO: "4",
          CO_TIPO_CARGO: "",
          CO_TIPO_APOSENTADORIA: "",
          ID_APOSENTADO_MATRICULA: servidor.matricula,
          ID_APOSENTADO_CPF: servidor.cpf,
          ID_APOSENT_PIS_PASEP: servidor.pis,
          CO_SEXO_APOSENTADO: servidor.sexo,
          CO_EST_CIVIL_APOSENTADO: servidor.estadoCivil,
          DT_NASC_APOSENTADO: servidor.dataNascimento,
          DT_INICIO_APOSENTADORIA: servidor.dataIngressoCarreiraAtual,
          VL_APOSENTADORIA: servidor.salarioBaseMensal,
          VL_CONTRIBUICAO: "",
          IN_PARID_SERV: "",
          CO_CONDICAO_APOSENTADO: "",
          VL_COMPENS_PREVID: "",
          NU_DEPENDENTES: servidor.totalDependentes,
          DT_NASC_CONJUGE: servidor.dataNascimentoConjuge,
          CO_CONDICAO_CONJUGE: "1",
          DT_NASC_DEPENDENTE_1: servidor.dataNascimentoDependente2,
          CO_CONDICAO_DEPENDENTE_1: "1",
          DT_NASC_DEPENDENTE_2: servidor.dataNascimentoDependente3,
          CO_CONDICAO_DEPENDENTE_2: "1",
        };
        servidoresAposentados.push(data);
      }
    }
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(servidoresAtivos)),
      "servidores"
    );
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(servidoresAposentados)),
      "aposentados"
    );
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(servidoresPensionistas)),
      "pensionistas"
    );
    self.spinner.hide();
  }


  async getRelatorioPendencias() {
    const self = this;

    let lista = [];
    if (!self.idCidade) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await self.apiCall
      .get("servidor/relatorio/cidade/pendencias?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();
    for (let i in servidores) {
      const servidor = servidores[i];
      if(servidor.pendencias){
        let data = {
          NOME: servidor.nome,
          STATUS: (servidor.statusServidor == "1" || servidor.status == '1') ? 'Ativo' : (servidor.statusServidor == "4"  || servidor.status == '3') ? 'Pensionista' : (servidor.statusServidor == "3"  || servidor.status == '2') ? 'Aposentado' : servidor.status == '4' ? 'Exonerado' : '',
          'PENDÊNCIA': servidor.pendencias,
          'OBSERVAÇÃO': servidor.observacoes,
        };
        lista.push(data)
      }
    }
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(lista)),
      "servidores"
    );
    self.spinner.hide();
  }
  async toggleEstado() {
    if (!this.servidor.estado_naturalidade) {
      return;
    }
    this.cidades = [];
    const cidades = await this.apiCall
      .get(
        "enderecos/estados/cidades?estado=" + this.servidor.estado_naturalidade
      )
      .pipe(take(1))
      .toPromise();
    this.cidades = JSON.parse(JSON.stringify(cidades));
  }

  filter() {
    this.servidoresFiltered = this.servidores;
    if (this.filterName) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.nome && x.nome.toLowerCase().includes(this.filterName.toLowerCase())
      );
    }
    if (this.filterCpf) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.cpf && x.cpf.toLowerCase().includes(this.filterCpf.toLowerCase())
      );
    }
    if (this.filterMatricula) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          (x.matricula &&
          x.matricula.toLowerCase().includes(this.filterMatricula.toLowerCase()
          ) || (x.matricula_beneficiario &&
            x.matricula_beneficiario.toLowerCase().includes(this.filterMatricula.toLowerCase()
            )

          ))
      );
    }
    if (this.filterNascimento) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.data_nascimento &&
          moment
            .utc(x.data_nascimento)
            .format("DDMMYYYY")
            .toLowerCase()
            .includes(this.filterNascimento.toLowerCase())
      );
    }
    if (this.filterCadastro) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) =>
          x.data_inclusao &&
          moment
            .utc(x.data_inclusao)
            .format("DDMMYYYY")
            .toLowerCase()
            .includes(this.filterCadastro.toLowerCase())
      );
    }
    if (this.filterSexo && this.filterSexo.id) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) => x.sexo && x.sexo == this.filterSexo.id
      );
    }
    if (this.filterStatus) {
      this.servidoresFiltered = this.servidoresFiltered.filter(
        (x) => x.status && x.status == this.filterStatus
      );
    }
  }

  beautifulDate(date) {
    return moment.utc(date).format("DD/MM/YYYY");
  }
  disableServidor() {
    const self = this;
    if(!self.servidor.nome ||
      !self.servidor.status){
        return true;
      } else {
        return false;
      }

    // if (self.servidor.status && self.servidor.status == 1) {
    //   if (
    //     !self.servidor.nome ||
    //     !self.servidor.status ||
    //     !self.servidor.deficiente ||
    //     !self.servidor.escolaridade ||
    //     !self.servidor.nome_mae ||
    //     !self.servidor.nome_pai ||
    //     !self.servidor.naturalizado ||
    //     !self.servidor.nacionalidade ||
    //     !self.servidor.cidade_naturalidade ||
    //     !self.servidor.estado_civil ||
    //     !self.servidor.sexo ||
    //     !self.servidor.estado_naturalidade ||
    //     !self.servidor.data_nascimento
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    // if (self.servidor.status && self.servidor.status == 2) {
    //   if (
    //     !self.servidor.nome ||
    //     !self.servidor.status ||
    //     !self.servidor.deficiente ||
    //     !self.servidor.escolaridade ||
    //     !self.servidor.nome_mae ||
    //     !self.servidor.nome_pai ||
    //     !self.servidor.naturalizado ||
    //     !self.servidor.nacionalidade ||
    //     !self.servidor.cidade_naturalidade ||
    //     !self.servidor.estado_civil ||
    //     !self.servidor.sexo ||
    //     !self.servidor.estado_naturalidade ||
    //     !self.servidor.data_nascimento
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else if (self.servidor.status) {
    //   if (
    //     (self.servidor.status==3 && !self.servidor.cpf_pensao) ||
    //     (self.servidor.status==3 && !self.servidor.nome_pensao) ||
    //     !self.servidor.nome ||
    //     !self.servidor.status ||
    //     !self.servidor.deficiente ||
    //     !self.servidor.escolaridade ||
    //     !self.servidor.nome_mae ||
    //     !self.servidor.nome_pai ||
    //     !self.servidor.naturalizado ||
    //     !self.servidor.nacionalidade ||
    //     !self.servidor.cidade_naturalidade ||
    //     !self.servidor.estado_civil ||
    //     !self.servidor.sexo ||
    //     !self.servidor.estado_naturalidade ||
    //     !self.servidor.data_nascimento
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return false;
    // }
  }
  async cadastrarServidor(modal) {
    const self = this;
    const dadosUsuario = this.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.cidade;

    this.servidor.data_nascimento = this.preparaData(
      this.servidor.data_nascimento
    );
    this.servidor.data_ingresso_servico_publico = this.preparaData(
      this.servidor.data_ingresso_servico_publico
    );
    this.servidor.concessao_aposentadoria = this.preparaData(
      this.servidor.concessao_aposentadoria
    );
    if (this.servidor.valor_aposentadoria)
      this.servidor.valor_aposentadoria = this.servidor.valor_aposentadoria
        .toString()
        .replace(".", "")
        .replace(",", ".");
    if (this.servidor.valor_beneficio)
      this.servidor.valor_beneficio = this.servidor.valor_beneficio
        .toString()
        .replace(".", "")
        .replace(",", ".");
    this.servidor.data_falecimento_pensao = this.preparaData(
      this.servidor.data_nascimento_pensao
    );
    this.servidor.data_nascimento_pensao = this.preparaData(
      this.servidor.data_falecimento_pensao
    );

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const id = await this.apiCall
      .get(
        `servidor/novo?id_cidade=${cidade}&nome=${this.validateQuotes(
          self.servidor.nome
        )}&estado_civil=${self.servidor.estado_civil}&status=${
          self.servidor.status
        }&data_nascimento=${self.servidor.data_nascimento}&data_falecimento=${
          self.servidor.data_falecimento
        }&nacionalidade=${self.servidor.nacionalidade}&estado_naturalidade=${
          self.servidor.estado_naturalidade
        }&cidade_naturalidade=${
          self.servidor.cidade_naturalidade
        }&naturalizado=${self.servidor.naturalizado}&escolaridade=${
          self.servidor.escolaridade
        }&sexo=${self.servidor.sexo}&nome_mae=${this.validateQuotes(
          self.servidor.nome_mae
        )}&nome_pai=${this.validateQuotes(
          self.servidor.nome_pai
        )}&data_ingresso_servico_publico=${
          self.servidor.data_ingresso_servico_publico
        }&deficiente=${
          self.servidor.deficiente
        }&documento_ingresso_servico_publico=${
          self.servidor.documento_ingresso_servico_publico
        }&paridade_aposentadoria=${
          self.servidor.paridade_aposentadoria
        }&concessao_aposentadoria=${
          self.servidor.concessao_aposentadoria
        }&valor_aposentadoria=${
          self.servidor.valor_aposentadoria
        }&cargo_aposentadoria=${
          self.servidor.cargo_aposentadoria
        }&pis_aposentado=${self.servidor.pis_aposentado}&tipo_aposentadoria=${
          self.servidor.tipo_aposentadoria
        }&motivo_exoneracao=${self.servidor.motivo_exoneracao}&cpf_pensao=${self.servidor.cpf_pensao}&pis_pensao=${self.servidor.pis_pensao}&nome_pensao=${self.servidor.nome_pensao}&matricula_pensao=${self.servidor.matricula_pensao}&data_nascimento_pensao=${self.servidor.data_nascimento_pensao}&data_falecimento_pensao=${self.servidor.data_falecimento_pensao}&matricula_beneficiario=${self.servidor.matricula_beneficiario}&tipo_pensao=${self.servidor.tipo_pensao}&valor_beneficio=${self.servidor.valor_beneficio}`
      )
      .pipe(take(1))
      .toPromise();
    if (id && self.servidor.cpf) {
      await self.apiCall
        .get(
          `servidor/documentos/novo?id_servidor=${id}&cpf=${self.servidor.cpf}&nit=&rg=&data_emissao_rg=&orgao_expedidor_rg=null&uf_rg=&cpts=&serie_cpts=&data_emissao_cpts=&titulo_eleitor=&zona_titulo_eleitor=&secao_titulo_eleitor=&uf_titulo_eleitor=`
        )
        .pipe(take(1))
        .toPromise();
    }
    this.spinner.hide();
    await this.app.alert(
      "Tudo certo!",
      "Servidor cadastrado com sucesso!",
      "success"
    );
    await this.ngOnInit();
    modal.close("Close click");
  }

  preparaData(data) {
    if (data) {
      return moment(data, "DDMMYYYY").format("YYYY-MM-DD");
    } else {
      data = "";
    }
    return data;
  }

  validateQuotes(str) {
    if (str) {
      return str.replace("'", "'");
    } else {
      return "";
    }
  }

  async getRelatorioServidor(content, id) {
    const self = this;
    const servidor = await self.apiCall.get(`servidor?id=${id}&id_cidade=${self.idCidade}`).pipe(take(1)).toPromise()
    self.servidor = JSON.parse(JSON.stringify(servidor));
    const serv = await self.apiCall
    .get("servidor/relatorio/servidor?id=" + id)
    .pipe(take(1))
    .toPromise();
    const escolaridades = await self.apiCall
      .get("servidor/escolaridade")
      .pipe(take(1))
      .toPromise();
    const estadosCivis = await self.apiCall
      .get("servidor/estadocivil")
      .pipe(take(1))
      .toPromise();
    const dependentes = await self.apiCall.get(`servidor/dependente/todos?id_servidor=${id}`).pipe(take(1)).toPromise();
    const tiposDependencia = await self.apiCall.get('servidor/tiposdependencia').pipe(take(1)).toPromise();


    self.tiposDependencia = JSON.parse(JSON.stringify(tiposDependencia));
    self.dependentes = JSON.parse(JSON.stringify(dependentes));
    self.estadosCivis = JSON.parse(JSON.stringify(estadosCivis));
    self.escolaridades = JSON.parse(JSON.stringify(escolaridades));
    var servidorFilter = this.servidoresFiltered.filter(x => x['id'] == id);
    this.servidor = JSON.parse(JSON.stringify(servidorFilter[0]));
    var estadoCivil = this.estadosCivis.filter(x => x['id'] == servidorFilter[0]?.['estado_civil']);
    var escolaridade = this.escolaridades.filter(x => x['id'] == servidorFilter[0]?.['escolaridade']);

    this.servidor.status =  this.classificaStatus(serv.status);
    this.servidor.nome = serv.nome;
    this.servidor.salario = this.servidor.status == 'Ativo' ? serv.salario_base : serv.valor_beneficio;
    this.servidor.imagem = serv.imagem;
    //this.servidor.escolaridade = escolaridades.where(x => x.id = serv.escolaridade).descricao;
    this.servidor.cpf = serv.cpf;
    this.servidor.rg = serv.rg;
    this.servidor.matricula = this.servidor.status == 2 || this.servidor.status == 3 ? this.servidor.matricula_beneficiario : this.servidor.matricula;
    this.servidor.telefone = serv.telefone;
    this.servidor.naturalizado = serv.naturalizado == 1 ? 'Sim' : 'Não';
    this.servidor.estado_civil = estadoCivil[0]?.['descricao'];
    this.servidor.carga_horaria = serv.carga_horaria;
    this.servidor.cargo = serv.cargo;
    this.servidor.escolaridade = escolaridade[0]?.['descricao'];

    self.dependentesToShow = JSON.parse(JSON.stringify(dependentes));

    const tempos = await self.apiCall.get('servidor/temposervico/todos?id_servidor='+id).pipe(take(1)).toPromise()
    for(let item of JSON.parse(JSON.stringify(tempos))){
      self.tempoTotalCadastrado = self.tempoTotalCadastrado + +item.tempo_bruto
      if(self.tipo=='1' && item.rpps_rgps!=null && item.rpps_rgps == 0){
        self.tempos.push(item)
        self.tempoFiltradoCadastrado = self.tempoFiltradoCadastrado + +item.tempo_bruto
      }else if(self.tipo=='2' && (item.rpps_rgps==null || item.rpps_rgps == 1)){
        self.tempos.push(item)
        self.tempoFiltradoCadastrado = self.tempoFiltradoCadastrado + +item.tempo_bruto
      }
    }

    for(let i in self.dependentesToShow){
      if(self.dependentesToShow[i].tipo_dependencia){
        if(self.tiposDependencia.find(x => x.codigo == +self.dependentesToShow[i].tipo_dependencia)) self.dependentesToShow[i].tipo_dependencia_label = self.tiposDependencia.find(x => x.codigo == +self.dependentesToShow[i].tipo_dependencia).descricao
      }
      if(self.dependentesToShow[i].data_nascimento){
        self.dependentesToShow[i].idade = moment().diff(moment(self.dependentesToShow[i].data_nascimento), 'years')
      }
      if(self.dependentesToShow[i].inicio_dependencia) self.dependentesToShow[i].inicio_dependencia = moment(self.dependentesToShow[i].inicio_dependencia).format('DDMMYYYY')
      console.log(  self.dependentesToShow[i].inicio_dependencia, 'aq')

    }

    console.log('123', self.dependentesToShow);

    this.modalService.open(content, { centered: true, size: 'xl' });
  }
  generatePDF(id: string) {
     var printContents = document.getElementById(id).innerHTML;
			var originalContents = document.body.innerHTML;
			document.body.innerHTML = printContents;
			window.print();
			document.body.innerHTML = originalContents;

  }
  closeModal() {
    this.modalService.dismissAll();
  }

 async duplicarServidor(idServidor){
    console.log('duplicarServidor', idServidor)

    Swal.fire({
      title: 'Insira o número da matrícula',
      input: 'text',
      inputAttributes: {
          autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'OK',
      showLoaderOnConfirm: true,
      preConfirm: (matricula) => {
          if (!matricula) {
              Swal.showValidationMessage('Por favor, insira um número de matrícula válido');
              return false;
          }
          // Retornar a matrícula para ser usada depois
          return matricula; // Isso garante que a matrícula seja passada para o then()
      },
      allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
      if (result.isConfirmed && result.value) {
          const matricula = result.value; // Aqui você captura a matrícula inserida
          Swal.fire({
              title: 'Você realmente deseja duplicar o servidor?',
              text: "Esta ação não pode ser desfeita.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim, duplicar!',
              cancelButtonText: 'Cancelar'
          }).then(async (result) => {
              if (result.isConfirmed) {
                this.spinner.show(undefined, {
                  type: "ball-triangle-path",
                  size: "medium",
                  bdColor: "rgba(0, 0, 0, 0.8)",
                  color: "#fff",
                  fullScreen: true,
                });
                  // Certifique-se de que `this.apiCall` está acessível ou ajuste conforme o contexto de sua aplicação
                  const data = await this.apiCall
                  .post("servidor/duplicar", {
                    idServidorOriginal: idServidor, // Garanta que idServidor esteja definido corretamente aqui
                    matriculaNova: matricula, // Usa a matrícula capturada do primeiro SweetAlert
                  })
                  .pipe(take(1))
                  .toPromise();
                  this.spinner.hide();  
                  if(data){
                    Swal.fire(
                      'Duplicado!',
                      'O servidor foi duplicado com sucesso.',
                      'success'
                  );

                  }
      
                 
              }
          });
      }
  });
  

  }

}
