import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as cep from "cep-promise";
import * as moment from "moment";
import { saveAs } from "file-saver";

@Component({
  selector: 'app-dependentes',
  templateUrl: './dependentes.component.html',
  styleUrls: ['./dependentes.component.scss']
})
export class DependentesComponent implements OnInit {
  page = 1
  pageSize = 10
  apiCall = this.api.new().silent();
  idServidor
  idCidade
  servidor
  sexos = [

    {
      id: 'M',
      name: 'Masculino',
    },{
      id: 'F',
      name: 'Feminino',
    }]
  naturalizados = [

    {
      id: 1,
      name: 'Sim',
    },{
      id: 2,
      name: 'Não',
    }]
  dependente = {
    id: null,
    id_servidor: null,
    nome: null,
    data_nascimento: null,
    estado_civil: null,
    sexo: null,
    estado_naturalidade: null,
    cidade_naturalidade: null,
    nacionalidade: null,
    naturalizado: null,
    escolaridade: null,
    nome_mae: null,
    nome_pai: null,
    deficiente: null,
    cpf: null,
    rg: null,
    data_emissao_rg: null,
    orgao_expedidor_rg: null,
    uf_rg: null,
    pis_pasep: null,
    numero_certidao: null,
    livro: null,
    folha: null,
    tipo_logradouro: null,
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    estado: null,
    cidade: null,
    cep: null,
    telefone: null,
    celular: null,
    email: null,
    tipo_dependencia: null,
    motivo_inicio: null,
    motivo_fim: null,
    inicio_dependencia: null,
    fim_dependencia: null,
    fins_previdenciarios: null
  }
  dependentes = []
  dependentesToShow = []
  estados = [];
  cidades = [];
  cidadesNaturalidade = [];
  estadosCivis = [];
  escolaridades = [];
  orgaosExpedidores = [];
  tiposlogradouro = []
  tiposDependencia = [];
  motivosInicio = [];
  motivosFim = [];
  checkEndereco = false;
  checkContatos = false;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }

  async ngOnInit() {
    const self = this
    self.resetaDependente()
    self.dependentes = []
    self.estados = [];
    self.cidades = [];
    self.estadosCivis = [];
    self.escolaridades = [];
    self.orgaosExpedidores = [];
    self.tiposlogradouro = [];
    self.tiposDependencia = [];
    self.motivosInicio = [];
    self.motivosFim = [];
    self.idServidor = null
    this.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        this.idServidor = parametros['id'];
      }
      if (parametros['idCidade']) {
        this.idCidade = parametros['idCidade'];
      }
    });
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const orgaosexpedidores = await self.apiCall.get('servidor/orgaosexpedidores').pipe(take(1)).toPromise()
    self.orgaosExpedidores = JSON.parse(JSON.stringify(orgaosexpedidores));
    const tiposlogradouro = await self.apiCall.get('enderecos/tiposlogradouro').pipe(take(1)).toPromise()
    self.tiposlogradouro = JSON.parse(JSON.stringify(tiposlogradouro));
    const estados = await self.apiCall.get('enderecos/estados').pipe(take(1)).toPromise()
    self.estados = JSON.parse(JSON.stringify(estados));
    const escolaridades = await self.apiCall.get('servidor/escolaridade').pipe(take(1)).toPromise()
    self.escolaridades = JSON.parse(JSON.stringify(escolaridades));
    const estadosCivis = await self.apiCall.get('servidor/estadocivil').pipe(take(1)).toPromise()
    self.estadosCivis = JSON.parse(JSON.stringify(estadosCivis));
    const tiposDependencia = await self.apiCall.get('servidor/tiposdependencia').pipe(take(1)).toPromise()
    self.tiposDependencia = JSON.parse(JSON.stringify(tiposDependencia));
    const motivosInicio = await self.apiCall.get('servidor/motivosiniciodependencia').pipe(take(1)).toPromise()
    self.motivosInicio = JSON.parse(JSON.stringify(motivosInicio));
    const motivosFim = await self.apiCall.get('servidor/motivosfimdependencia').pipe(take(1)).toPromise()
    self.motivosFim = JSON.parse(JSON.stringify(motivosFim));
    const dependentes = await self.apiCall.get(`servidor/dependente/todos?id_servidor=${self.idServidor}`).pipe(take(1)).toPromise()
    self.dependentes = JSON.parse(JSON.stringify(dependentes));
    self.dependentesToShow = JSON.parse(JSON.stringify(dependentes));
    const servidor = await self.apiCall.get(`servidor?id=${self.idServidor}&id_cidade=${self.idCidade}`).pipe(take(1)).toPromise()
    self.servidor = JSON.parse(JSON.stringify(servidor));

    for(let i in self.dependentesToShow){
      if(self.dependentesToShow[i].tipo_dependencia){
        if(self.tiposDependencia.find(x => x.codigo == +self.dependentesToShow[i].tipo_dependencia)) self.dependentesToShow[i].tipo_dependencia_label = self.tiposDependencia.find(x => x.codigo == +self.dependentesToShow[i].tipo_dependencia).descricao
      }
      if(self.dependentesToShow[i].data_nascimento){
        self.dependentesToShow[i].idade = moment().diff(moment(self.dependentesToShow[i].data_nascimento), 'years')
      }
      if(self.dependentesToShow[i].inicio_dependencia) self.dependentesToShow[i].inicio_dependencia = moment(self.dependentesToShow[i].inicio_dependencia).format('DDMMYYYY')
      console.log(  self.dependentesToShow[i].inicio_dependencia, 'aq')
      
    }
    self.spinner.hide()

  }
  async baixarMatricula(matricula){
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const url = await self.apiCall
      .get(`banco-dados/files/url`)
      .pipe(take(1))
      .toPromise();
    if (url) saveAs(url + matricula, matricula);
    self.spinner.hide();
  }
  async copiaEndereco($event){
    const self = this
    this.checkEndereco = !this.checkEndereco
    if(this.checkEndereco){
      const endereco = await self.apiCall.get('servidor/endereco?id_servidor='+self.idServidor).pipe(take(1)).toPromise()

      if(endereco){
        const cidades = await self.apiCall.get('enderecos/estados/cidades?estado='+endereco.estado).pipe(take(1)).toPromise()
        self.cidades=cidades
        self.dependente.tipo_logradouro= endereco.tipo_logradouro
        self.dependente.logradouro= endereco.logradouro
        self.dependente.numero= endereco.numero
        self.dependente.complemento= endereco.complemento
        self.dependente.bairro= endereco.bairro
        self.dependente.estado= endereco.estado
        self.dependente.cidade= +endereco.cidade
        self.dependente.cep= endereco.cep
      }
    }
  }
  anexarComprovanteMatricula(dependente, event){
    const self = this
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async  () => {
      const formData = new FormData();
      formData.append("file", file);
      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
  
      const anexoEnviado = await self.apiCall
        .post(
          `servidor/arquivo/dependente/novo?id_dependente=${dependente.id}`,
          formData
        )
        .pipe(take(1))
        .toPromise();
      await self.app.alert(`Sucesso`, `Anexo cadastrado!`, `success`);
      await self.spinner.hide();
      await self.ngOnInit();
    }
    reader.onabort = () => {
    };
    reader.onerror = () => {
    };
  }
  resetaDependente(){
    this.dependente = {
      id: null,
      id_servidor: null,
      nome: null,
      data_nascimento: null,
      estado_civil: null,
      sexo: null,
      estado_naturalidade: null,
      cidade_naturalidade: null,
      nacionalidade: null,
      naturalizado: null,
      escolaridade: null,
      nome_mae: null,
      nome_pai: null,
      deficiente: null,
      cpf: null,
      rg: null,
      data_emissao_rg: null,
      orgao_expedidor_rg: null,
      uf_rg: null,
      pis_pasep: null,
      numero_certidao: null,
      livro: null,
      folha: null,
      tipo_logradouro: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      estado: null,
      cidade: null,
      cep: null,
      telefone: null,
      celular: null,
      email: null,
      tipo_dependencia: null,
      motivo_inicio: null,
      motivo_fim: null,
      inicio_dependencia: null,
      fim_dependencia: null,
      fins_previdenciarios: null
    }
  }
  async copiaContatos($event){
    const self = this
    this.checkContatos = !this.checkContatos
    if(this.checkContatos){
      const contato = await self.apiCall.get('servidor/contatos?id_servidor='+self.idServidor).pipe(take(1)).toPromise()
      if(contato){
        self.dependente.telefone= contato.telefone
        self.dependente.celular= contato.celular
        self.dependente.email= contato.email
      }
    }
  }
  consultaCep() {
    const self = this;
    if(!self.dependente.cep){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    cep(self.dependente.cep).then(async local => {
      self.dependente.logradouro = local.street
      self.dependente.bairro = local.neighborhood
      self.dependente.estado = local.state
      await self.toggleEstado(false)
      const foundCity = self.cidades.find(c=>c.nome.toLowerCase().includes(local.city.toLowerCase()))
      if(foundCity) self.dependente.cidade = foundCity.id
      const foundTipoLogradouro = self.tiposlogradouro.find(t=>t.descricao.toLowerCase().includes(local.street.split(" ")[0].toLowerCase()))
      if(foundTipoLogradouro) self.dependente.tipo_logradouro = foundTipoLogradouro.id
      self.spinner.hide()
    }).catch(erro => {
      self.spinner.hide()
    });
  }
  async toggleEstado(naturalidade){

    if(naturalidade && !this.dependente.estado_naturalidade){
      return
    }
    if(!naturalidade && !this.dependente.estado){
      return
    }

    if(naturalidade){
      this.cidadesNaturalidade = []
      this.dependente.cidade_naturalidade = null
      const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+this.dependente.estado_naturalidade).pipe(take(1)).toPromise()
      this.cidadesNaturalidade = JSON.parse(JSON.stringify(cidades));
    }else{
      this.cidades = []
      this.dependente.cidade = null
      const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+this.dependente.estado).pipe(take(1)).toPromise()
      this.cidades = JSON.parse(JSON.stringify(cidades));
    }


  }
  async deletarDependente(id){
    const self = this
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if (await self.app.confirm(`Atenção`, `Você deseja mesmo deletar este dependente?`, `Deletar`)) {
      const deleteDependente = await self.apiCall.get(`servidor/dependente/deletar?id_dependente=${id}&id_servidor=${self.idServidor}`).pipe(take(1)).toPromise()
      console.log(deleteDependente)
      await self.app.alert(`Sucesso`, `Dependente deletado!`, `success`)
      await self.spinner.hide()
      await self.ngOnInit()
    }
  }
  async deletarArquivo(dependente) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar este anexo?`,
        `Deletar`
      )
    ) {
      self.apiCall
        .post(`servidor/arquivo/dependente/deletar?id_dependente=${dependente.id}`)
        .subscribe(async (response) => {
          await self.app.alert(`Sucesso`, `Anexo deletado!`, `success`);
          await self.spinner.hide();
          await self.ngOnInit();
        });
    }
  }
  async openModalXl(content, dependente){
    if(dependente){
      this.dependente = {...dependente}
      if(dependente.data_emissao_rg) this.dependente.data_emissao_rg = moment.utc(dependente.data_emissao_rg).format('DDMMYYYY')
      if(dependente.data_nascimento) this.dependente.data_nascimento = moment.utc(dependente.data_nascimento).format('DDMMYYYY')
      if(dependente.estado_naturalidade){
        const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+dependente.estado_naturalidade).pipe(take(1)).toPromise()
        this.cidadesNaturalidade=JSON.parse(JSON.stringify(cidades))
        if(dependente.cidade_naturalidade) this.dependente.cidade_naturalidade = +dependente.cidade_naturalidade
      }
      if(dependente.estado){
        const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+dependente.estado).pipe(take(1)).toPromise()
        this.cidades=JSON.parse(JSON.stringify(cidades))
        if(dependente.cidade) this.dependente.cidade = +dependente.cidade
      }
    }else{
      this.resetaDependente()
      this.cidadesNaturalidade = []
      this.cidades = []
    }
    this.checkContatos = false
    this.checkEndereco = false
    const modal = this.modalService.open(content, { centered: true, size: "xl" })
  }
  verificaFilhos(dependente){
    if(dependente && dependente.data_nascimento && moment().diff(moment(dependente.data_nascimento), 'years') && moment().diff(moment(dependente.data_nascimento), 'years') >= 21 && moment().diff(moment(dependente.data_nascimento), 'years') <=24 && dependente.tipo_dependencia && [3, 4, 6, 7, 10].includes(dependente.tipo_dependencia)){
      return true
    } else {
      return false
    }
  }
  async atualizarOuCadastrar(modal){
    const self = this
    if(!self.dependente || !self.idServidor){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.dependente.data_nascimento = this.preparaData(this.dependente.data_nascimento)
    this.dependente.data_emissao_rg = this.preparaData(this.dependente.data_emissao_rg)
    this.dependente.inicio_dependencia = this.preparaData(this.dependente.inicio_dependencia)
    this.dependente.fim_dependencia = this.preparaData(this.dependente.fim_dependencia)
    if(self.dependente.id){
      const retorno = await self.apiCall.get(`servidor/dependente/editar?id_dependente=${self.dependente.id}&nome=${this.validateQuotes(this.dependente.nome)}&estado_civil=${this.dependente.estado_civil}&data_nascimento=${this.dependente.data_nascimento}&nacionalidade=${this.dependente.nacionalidade}&estado_naturalidade=${this.dependente.estado_naturalidade}&cidade_naturalidade=${this.dependente.cidade_naturalidade}&naturalizado=${this.dependente.naturalizado}&escolaridade=${this.dependente.escolaridade}&sexo=${this.dependente.sexo}&nome_mae=${this.validateQuotes(this.dependente.nome_mae)}&nome_pai=${this.validateQuotes(this.dependente.nome_pai)}&deficiente=${this.dependente.deficiente}&cpf=${this.dependente.cpf}&rg=${this.dependente.rg}&data_emissao_rg=${this.dependente.data_emissao_rg}&orgao_expedidor_rg=${this.dependente.orgao_expedidor_rg}&uf_rg=${this.dependente.uf_rg}&pis_pasep=${this.dependente.pis_pasep}&numero_certidao=${this.dependente.numero_certidao}&livro=${this.dependente.livro}&folha=${this.dependente.folha}&tipo_logradouro=${this.dependente.tipo_logradouro}&logradouro=${this.dependente.logradouro}&numero=${this.dependente.numero}&complemento=${this.dependente.complemento}&bairro=${this.dependente.bairro}&cep=${this.dependente.cep}&estado=${this.dependente.estado}&cidade=${this.dependente.cidade}&telefone=${this.dependente.telefone}&celular=${this.dependente.celular}&email=${this.dependente.email}&tipo_dependencia=${this.dependente.tipo_dependencia}&motivo_inicio=${this.dependente.motivo_inicio}&motivo_fim=${this.dependente.motivo_fim}&inicio_dependencia=${this.dependente.inicio_dependencia}&fim_dependencia=${this.dependente.fim_dependencia}&fins_previdenciarios=${this.dependente.fins_previdenciarios}`).pipe(take(1)).toPromise()
    }else{
      await self.apiCall.get(`servidor/dependente/novo?id_servidor=${this.idServidor}&nome=${this.validateQuotes(this.dependente.nome)}&estado_civil=${this.dependente.estado_civil}&data_nascimento=${this.dependente.data_nascimento}&nacionalidade=${this.dependente.nacionalidade}&estado_naturalidade=${this.dependente.estado_naturalidade}&cidade_naturalidade=${this.dependente.cidade_naturalidade}&naturalizado=${this.dependente.naturalizado}&escolaridade=${this.dependente.escolaridade}&sexo=${this.dependente.sexo}&nome_mae=${this.validateQuotes(this.dependente.nome_mae)}&nome_pai=${this.validateQuotes(this.dependente.nome_pai)}&deficiente=${this.dependente.deficiente}&cpf=${this.dependente.cpf}&rg=${this.dependente.rg}&data_emissao_rg=${this.dependente.data_emissao_rg}&orgao_expedidor_rg=${this.dependente.orgao_expedidor_rg}&uf_rg=${this.dependente.uf_rg}&pis_pasep=${this.dependente.pis_pasep}&numero_certidao=${this.dependente.numero_certidao}&livro=${this.dependente.livro}&folha=${this.dependente.folha}&tipo_logradouro=${this.dependente.tipo_logradouro}&logradouro=${this.dependente.logradouro}&numero=${this.dependente.numero}&complemento=${this.dependente.complemento}&bairro=${this.dependente.bairro}&cep=${this.dependente.cep}&estado=${this.dependente.estado}&cidade=${this.dependente.cidade}&telefone=${this.dependente.telefone}&celular=${this.dependente.celular}&email=${this.dependente.email}&tipo_dependencia=${this.dependente.tipo_dependencia}&motivo_inicio=${this.dependente.motivo_inicio}&motivo_fim=${this.dependente.motivo_fim}&inicio_dependencia=${this.dependente.inicio_dependencia}&fim_dependencia=${this.dependente.fim_dependencia}&fins_previdenciarios=${this.dependente.fins_previdenciarios}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.dependente.id ? 'Dependente atualizado com sucesso!' : 'Dependente cadastrado com sucesso!', 'success')
    modal.close('Close click')
    await self.ngOnInit()
  }
  validateQuotes(str){
    if(str){
      return str.replace("'", "\'");
    }else{
      return ''
    }
  }

  preparaData(data){
    if(data){
      return moment(data, 'DDMMYYYY').format('YYYY-MM-DD')
    }else{
      data = ''
    }
    return data
  }
}
