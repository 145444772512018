
<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Representante Legal</h3>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <form class="card-body">
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tipo_representatividade">Tipo Representatividade</label>
                    <ng-select [items]="tiposRepresentatividade"   name="tipo_representatividade" bindLabel="descricao" bindValue="id"  placeholder="Tipo Representatividade" id="tipo_representatividade"  [(ngModel)]="representante.tipo_representatividade"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_inicio">Data Início</label>
                    <input type="text" id="data_inicio" class="form-control" mask="00/00/0000" [(ngModel)]="representante.data_inicio" name="data_inicio" placeholder="Data Início"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_fim">Data Fim</label>
                    <input type="text" id="data_fim" class="form-control" mask="00/00/0000" [(ngModel)]="representante.data_fim" name="data_fim" placeholder="Data Fim"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
               <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Nome</label>
                      <input type="text" id="nome" class="form-control" [(ngModel)]="representante.nome_representante" name="nome" placeholder="Nome Completo"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_nascimento">Nascimento</label>
                      <input type="text" id="data_nascimento" class="form-control" mask="00/00/0000" [(ngModel)]="representante.data_nascimento_representante" name="data_nascimento" placeholder="Data Nascimento"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="sexo">Sexo</label>
                      <ng-select [items]="sexos"   name="sexo" bindLabel="name"  placeholder="Sexo" bindValue="id" id="sexo" [(ngModel)]="representante.sexo_representante"></ng-select>
                    </div>
                  </div>
                </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cpf">CPF</label>
                    <input type="text" id="cpf" class="form-control" [(ngModel)]="representante.cpf_representante" mask="000.000.000-00" name="cpf" placeholder="CPF"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="rg">RG</label>
                    <input type="text" id="rg" class="form-control" [(ngModel)]="representante.rg_representante" name="rg" placeholder="RG"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_emissao_rg">Emissão RG</label>
                    <input type="text" id="data_emissao_rg" class="form-control" mask="00/00/0000" [(ngModel)]="representante.data_emissao_rg_representante" name="data_emissao_rg" placeholder="Data Emissão RG"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="orgaosExpedidores">Orgão Expedidor RG</label>
                    <ng-select [items]="orgaosExpedidores"   name="orgaosExpedidores" bindLabel="descricao" bindValue="id"  placeholder="Orgão Expedidor RG" id="orgaosExpedidores"  [(ngModel)]="representante.orgao_expedidor_rg_representante"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="uf_rg">UF RG</label>
                    <ng-select [items]="estados"  name="uf_rg" bindLabel="estado" bindValue="estado"  placeholder="UF RG" id="uf_rg" [(ngModel)]="representante.uf_rg_representante"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cep">CEP</label>
                    <input type="text" id="cep" class="form-control" [(ngModel)]="representante.cep_representante" mask="00.000-000" name="cep" placeholder="CEP" (change)="consultaCep()"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tiposlogradouro">Tipo Logradouro</label>
                    <ng-select [items]="tiposLogradouro"   name="tiposlogradouro" bindLabel="descricao" bindValue="id"  placeholder="Tipo Logradouro" id="tiposlogradouro"  [(ngModel)]="representante.tipo_logradouro_representante"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="logradouro">Logradouro</label>
                    <input type="text" id="logradouro" class="form-control" [(ngModel)]="representante.logradouro_representante" name="logradouro" placeholder="Logradouro"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="numero">Número</label>
                    <input type="text" id="numero" class="form-control" [(ngModel)]="representante.numero_representante" name="numero" placeholder="Número"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="bairro">Bairro</label>
                    <input type="text" id="bairro" class="form-control" [(ngModel)]="representante.bairro_representante"   name="bairro" placeholder="Bairro"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="complemento">Complemento</label>
                    <input type="text" id="complemento" class="form-control" [(ngModel)]="representante.complemento_representante" name="complemento" placeholder="Complemento"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="estado">Estado</label>
                    <ng-select [items]="estados"  name="estado" (change)="toggleEstado()"  bindLabel="estado" bindValue="estado"  placeholder="Estado" id="estado" [(ngModel)]="representante.estado_representante"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cidade">Cidade</label>
                    <ng-select [items]="cidades" [disabled]="!cidades || cidades.length==0"  name="cidade"  bindLabel="nome" bindValue="id"  placeholder="Cidade" id="cidade" [(ngModel)]="representante.cidade_representante"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="telefone">Telefone</label>
                    <input type="text" id="telefone" class="form-control" [(ngModel)]="representante.telefone_representante" mask="(00) 0000-0000" name="telefone" placeholder="Telefone"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="celular">Celular</label>
                    <input type="text" id="celular" class="form-control" [(ngModel)]="representante.celular_representante" mask="(00) 00000-0000" name="celular" placeholder="Celular"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="email">Email</label>
                    <input type="text" id="email" class="form-control"  [(ngModel)]="representante.email_representante" name="email" placeholder="Email"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col" >
                <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidor" [queryParams]="{id: idServidor}" >Voltar</button>
              </div>
              <div class="col" >
                <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right" (click)="atualizarOuCadastrar()"
                        [disabled]="
                        !representante.tipo_representatividade
                        || !representante.data_inicio
                        || !representante.data_fim
                        || !representante.nome_representante
                        || !representante.cpf_representante
                        || !representante.rg_representante
                        || !representante.orgao_expedidor_rg_representante
                        || !representante.data_emissao_rg_representante
                        || !representante.uf_rg_representante
                        || !representante.data_nascimento_representante
                        || !representante.sexo_representante
                        || !representante.tipo_logradouro_representante
                        || !representante.logradouro_representante
                        || !representante.numero_representante
                        || !representante.complemento_representante
                        || !representante.bairro_representante
                        || !representante.cep_representante
                        || !representante.estado_representante
                        || !representante.cidade_representante" >{{representante.id ? 'Atualizar' : 'Cadastrar'}}</button>
              </div>
            </div>
          </form>
        </form>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
