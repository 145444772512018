
<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Óbito</h3>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <form class="card-body">
          <form #servForm="ngForm" novalidate>
            <h6 class="text-center">Dados do Cartório</h6>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cnpj_cartorio">CNPJ</label>
                    <input type="text" id="cnpj_cartorio" class="form-control" [(ngModel)]="obito.cnpj_cartorio" name="cnpj_cartorio" placeholder="CNPJ"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome_cartorio">Nome Cartório</label>
                    <input type="text" id="nome_cartorio" class="form-control" [(ngModel)]="obito.nome_cartorio" name="nome_cartorio" placeholder="Nome Cartório"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cep">CEP</label>
                    <input type="text" id="cep" class="form-control" [(ngModel)]="obito.cep_cartorio" mask="00.000-000" name="cep" placeholder="CEP" (change)="consultaCep()"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tiposlogradouro">Tipo Logradouro</label>
                    <ng-select [items]="tiposLogradouro"   name="tiposlogradouro" bindLabel="descricao" bindValue="id"  placeholder="Tipo Logradouro" id="tiposlogradouro"  [(ngModel)]="obito.tipo_logradouro_cartorio"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="logradouro">Logradouro</label>
                    <input type="text" id="logradouro" class="form-control" [(ngModel)]="obito.logradouro_cartorio" name="logradouro" placeholder="Logradouro"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="numero">Número</label>
                    <input type="text" id="numero" class="form-control" [(ngModel)]="obito.numero_cartorio" name="numero" placeholder="Número"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="bairro">Bairro</label>
                    <input type="text" id="bairro" class="form-control" [(ngModel)]="obito.bairro_cartorio"   name="bairro" placeholder="Bairro"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="complemento">Complemento</label>
                    <input type="text" id="complemento" class="form-control" [(ngModel)]="obito.complemento_cartorio" name="complemento" placeholder="Complemento"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="estado">Estado</label>
                    <ng-select [items]="estados"  name="estado" (change)="toggleEstado()"  bindLabel="estado" bindValue="estado"  placeholder="Estado" id="estado" [(ngModel)]="obito.estado_cartorio"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cidade">Cidade</label>
                    <ng-select [items]="cidades" [disabled]="!cidades || cidades.length==0"  name="cidade"  bindLabel="nome" bindValue="id"  placeholder="Cidade" id="cidade" [(ngModel)]="obito.cidade_cartorio"></ng-select>
                  </div>
                </div>
              </div>
            </div>
           <h6 class="text-center">Dados da Certidão</h6>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_emissao">Data de Emissão</label>
                    <input type="text" id="data_emissao" class="form-control" mask="00/00/0000" [(ngModel)]="obito.data_emissao" name="data_emissao" placeholder="Data de Emissão"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="numero_termo">Número Termo</label>
                    <input type="text" id="numero_termo" class="form-control" [(ngModel)]="obito.numero_termo" name="numero_termo" placeholder="Número Termo"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="numero_livro">Número Livro</label>
                    <input type="text" id="numero_livro" class="form-control" [(ngModel)]="obito.numero_livro" name="numero_livro" placeholder="Número Livro"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="numero_folha">Número Folha</label>
                    <input type="text" id="numero_folha" class="form-control" [(ngModel)]="obito.numero_folha" name="numero_folha" placeholder="Número Folha"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col" >
                <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidor" [queryParams]="{id: idServidor}" >Voltar</button>
              </div>
              <div class="col" >
                <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right" (click)="atualizarOuCadastrar()"
                        [disabled]="
                        !obito.numero_termo
                        || !obito.numero_folha
                        || !obito.numero_livro
                        || !obito.data_emissao
                        || !obito.nome_cartorio
                        || !obito.tipo_logradouro_cartorio
                        || !obito.logradouro_cartorio
                        || !obito.numero_cartorio
                        || !obito.complemento_cartorio
                        || !obito.bairro_cartorio
                        || !obito.cep_cartorio
                        || !obito.estado_cartorio
                        || !obito.cidade_cartorio" >{{obito.id ? 'Atualizar' : 'Cadastrar'}}</button>
              </div>
            </div>
          </form>
        </form>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
