import { Component, OnInit } from '@angular/core';
import Api from 'app/helpers/api';
import Me from 'app/helpers/me';
import { CommonModule } from "@angular/common";
import { take, filter } from 'rxjs/operators';
@Component({
  selector: 'app-relatorios-estatisticas',
  templateUrl: './relatorios-estatisticas.component.html',
  styleUrls: ['./relatorios-estatisticas.component.scss']
})
export class RelatoriosEstatisticasComponent implements OnInit {
  apiCall = this.api.new().silent();
  public admissaoServidor = [];
  public tempoServicoPassado = [];
  public composicaoFamiliar = [];
  public composicaoFamiliarIdades = [];
  public idadeMediaFilhos = [];
  constructor(private api: Api, private me: Me) { }

  async ngOnInit() {
    const dadosUsuario = this.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.cidade;

    const listAdmissao = await this.apiCall
    .get(`servidor/relatorio/estatisticas/admissao?id_cidade=${cidade}`).pipe(take(1))
     .toPromise();
     const tempoServicoPassadoData = await this.apiCall
     .get(`servidor/relatorio/estatisticas/tempoServicoPassado?id_cidade=${cidade}`).pipe(take(1))
      .toPromise();
     const composicaoFamiliarData = await this.apiCall
     .get(`servidor/relatorio/estatisticas/composicaofamiliar?id_cidade=${cidade}`).pipe(take(1))
      .toPromise();
     const idadeMediaConjugesData = await this.apiCall
     .get(`servidor/relatorio/estatisticas/mediaidadeconjuges?id_cidade=${cidade}`).pipe(take(1))
      .toPromise();
     const idadeMediaFilhosData = await this.apiCall
     .get(`servidor/relatorio/estatisticas/mediaidadefilhos?id_cidade=${cidade}`).pipe(take(1))
      .toPromise();

    //  const admissao98 = listAdmissao.filter(x => x.Admissao == 0).length;
    //  const admissao98_04 = listAdmissao.filter(x => x.Admissao == 1).length;
    //  const admissao04 = listAdmissao.filter(x => x.Admissao == 2).length;
     const mediaIdadeFilhos = idadeMediaFilhosData[0]['media_idade_dependentes'];

    this.admissaoServidor = [
      { coluna1: 'Até 1998', coluna2: listAdmissao[0]['antes_98' ] },
      { coluna1: 'De 1998 até 2004', coluna2: listAdmissao[0]['entre_98_04'] },
      { coluna1: 'Após 2004', coluna2: listAdmissao[0]['acima_2004'] }
    ];

    this.tempoServicoPassado = [
      { coluna1: 'Servidores com TSP no INSS', coluna2: tempoServicoPassadoData['servidoresINSS'], coluna3: tempoServicoPassadoData['porcentagemINSS']+ '%' },
      { coluna1: 'Tempo médio de contribuição para o RGPS', coluna2: tempoServicoPassadoData['tempoMedioRGPS'] + ' dias', coluna3: '-' },
      { coluna1: 'Tempo mediano de contribuição para o RGPS', coluna2: tempoServicoPassadoData['tempoMedianoRGPS'] + ' dias', coluna3: '-' },
      { coluna1: 'Tempo máximo de contribuição para o RGPS', coluna2: tempoServicoPassadoData['tempoMaximoRGPS'] +' dias', coluna3: '-' },
      { coluna1: 'Tempo mínimo de contribuição para o RGPS', coluna2: tempoServicoPassadoData['tempoMinimoRGPS'] +' dias', coluna3: '-' },
      { coluna1: '% de médio de contribuição para o RGPS', coluna2: tempoServicoPassadoData['porcentagemRGPS'] + '%', coluna3: '-' }
    ];

    this.composicaoFamiliar = [
      { coluna1: 'Quantidade Total de Dependentes', coluna2: composicaoFamiliarData['total_dependentes'] },
      { coluna1: 'Média de Dependentes por Segurado', coluna2: this.arredondarInteiro(composicaoFamiliarData['dependentes_segurado']) },
      { coluna1: 'Cônjuges por Segurado', coluna2: this.arredondarInteiro(composicaoFamiliarData['conjuge_segurado']) },
      { coluna1: 'Filhos por Segurado', coluna2: composicaoFamiliarData['filhos_segurado'] },
    ];

    this.composicaoFamiliarIdades = [
      { coluna1: 'Se Servidor for mulher, cônjuge tem', coluna2: this.arredondarInteiro(Math.abs(idadeMediaConjugesData.conjuge_feminino[0]['media_diferenca_idade'])) + ' anos a mais' },
      { coluna1: 'Se Servidor for homem, cônjuge tem', coluna2: this.arredondarInteiro(Math.abs(idadeMediaConjugesData.conjuge_masculino[0]['media_diferenca_idade']))  + ' anos a menos' },
    ];

    this.idadeMediaFilhos = [
      { coluna1: 'Idade Média dos Filhos', coluna2: this.arredondarInteiro(mediaIdadeFilhos) },
    ];

  }

   arredondarInteiro(numero) {
    const numeroArredondado = Math.round(numero);
    return numeroArredondado;
  }

}
