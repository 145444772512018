import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import Me from "app/helpers/me";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import * as Papa from "papaparse";
import { saveAs } from "file-saver";

@Component({
  selector: "importacoes-component",
  templateUrl: "./importacoes.component.html",
  styleUrls: ["./importacoes.component.scss"],
})
export class ImportacoesComponent implements OnInit {
  idCidade;
  cidade = null;
  nomeCidade: string;
  isAdmin = false;
  planilhas = [];
  listaSimplificado = [];
  listaUploadServidores = []
  listaUploadAposentados = []
  listaUploadPensionistas = []
  listaUploadIdadeAposentadoria = []
  listaImportacaoMensal = []

  apiCall = this.api.new().silent();
  meses = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];
  anos = [
    { id: 2020, nome: "2020" },
    { id: 2021, nome: "2021" },
    { id: 2022, nome: "2022" },
    { id: 2023, nome: "2023" },
    { id: 2024, nome: "2024" },
    { id: 2025, nome: "2025" },
    { id: 2026, nome: "2026" },
    { id: 2027, nome: "2027" },
  ];
  mesUpload = null;
  anoUpload = null;
  jobs = [];

  colunasEsperadasInativos = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_POPULACAO",
    "CO_TIPO_CARGO",
    "CO_TIPO_APOSENTADORIA",
    "ID_APOSENTADO_MATRICULA",
    "ID_APOSENTADO_CPF",
    "ID_APOSENT_PIS_PASEP",
    "CO_SEXO_APOSENTADO",
    "CO_EST_CIVIL_APOSENTADO",
    "DT_NASC_APOSENTADO",
    "DT_INICIO_APOSENTADORIA",
    "VL_APOSENTADORIA",
    "VL_CONTRIBUICAO",
    "IN_PARID_SERV",
    "CO_CONDICAO_APOSENTADO",
    "VL_COMPENS_PREVID",
    "NU_DEPENDENTES",
    "DT_NASC_CONJUGE",
    "CO_CONDICAO_CONJUGE",
    "DT_NASC_DEPENDENTE_1",
    "CO_CONDICAO_DEPENDENTE_1",
    "DT_NASC_DEPENDENTE_2",
    "CO_CONDICAO_DEPENDENTE_2",
  ];

  colunasEsperadasAposentados = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_INSTITUIDOR",
    "ID_INSTITUIDOR_MATRICULA",
    "ID_INSTITUIDOR_CPF",
    "ID_INSTITUIDOR_PIS_PASEP",
    "ID_PENSIONISTA_CPF",
    "CO_SEXO_PENSIONISTA",
    "DT_NASC_PENSIONISTA",
    "DT_INICIO_PENSAO",
    "VL_BENEF_PENSAO",
    "VL_CONTRIBUICAO",
    "VL_COMPENS_PREVID",
    "IN_PARID_SERV",
    "CO_CONDICAO",
    "CO_DURACAO",
  ];
  colunasEsperadasSimplificado = [
    "CO_IBGE",
    "ID_SERVIDOR_MATRICULA",
    "ID_SERVIDOR_CPF",
    "STATUS_SERVIDOR",
    "NOME_SERVIDOR",
  ];
  colunasEsperadasIdadeAposentadoria = [
    "CO_IBGE",
    "SERVIDOR_MATRICULA",
    "SERVIDOR_IDADE",
  ];
  colunasEsperadasAtivos = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "NU_CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_POPULACAO",
    "CO_TIPO_CARGO",
    "CO_CRITERIO_ELEGIBILIDADE",
    "ID_SERVIDOR_MATRICULA",
    "ID_SERVIDOR_CPF",
    "ID_SERVIDOR_PIS_PASEP",
    "CO_SEXO_SERVIDOR",
    "CO_EST_CIVIL_SERVIDOR",
    "DT_NASC_SERVIDOR",
    "CO_SITUACAO_FUNCIONAL",
    "CO_TIPO_VINCULO",
    "DT_ING_SERV_PUB",
    "DT_ING_ENTE",
    "DT_ING_CARREIRA",
    "NO_CARREIRA",
    "DT_ING_CARGO",
    "NO_CARGO",
    "VL_BASE_CALCULO",
    "VL_REMUNERACAO",
    "NU_TEMPO_RGPS",
    "NU_TEMPO_RPPS",
    "NU_DEPENDENTES",
    "DT_NASC_CONJUGE",
    "CO_COND_CONJUGE",
    "DT_NASC_DEPENDENTE_1",
    "CO_COND_DEPENDENTE_1",
    "DT_NASC_DEPENDENTE_2",
    "CO_COND_DEPENDENTE_2",
    "IN_ABONO_PERMANENCIA",
    "DT_INICIO_ABONO",
    "DT_PROV_APOSENT",
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private me: Me,
    private api: Api,
    private app: App,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    const self = this;

    self.route.queryParams.subscribe(async (parametros) => {
      if (parametros["cidade"]) {
        self.idCidade = parametros["cidade"];
      }
    });

    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.cidade;

    self.isAdmin = json.user.tipo === "1";
    if (cidade) {
      self.idCidade = cidade;
    }

    if (json.user.tipo === "1") {
      console.log("caiu if");
      self.route.queryParams.subscribe(async (parametros) => {
        if (parametros["cidade"]) {
          let idCidade = parametros["cidade"];
          self.idCidade = idCidade;
          json.user.cidade = idCidade;
          self.me.atualizaCidade(json);
        }
      });
    }

    const planilhas = await self.apiCall
      .get("servidor/arquivo/planilha?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();

    if (planilhas) {
      planilhas
      console.log("planilhas", planilhas);
      // Filtrando as listas com base no valor de 'type'
      this.listaSimplificado = planilhas.filter(item => item.type === 9);
      this.listaUploadServidores = planilhas.filter(item => item.type === 1);
      this.listaUploadAposentados = planilhas.filter(item => item.type === 2);
      this.listaUploadPensionistas = planilhas.filter(item => item.type === 3);
      this.listaUploadIdadeAposentadoria = planilhas.filter(item => item.type === 8);
      this.listaImportacaoMensal = planilhas.filter(item => item.type === 77);
    }
  }

  async selecionaArquivoSalarios(event) {
    const self = this;

    const { value: file } = await Swal.fire({
      title: "Upload Simplificado Servidores",
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
    });

    if (!file) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar uma planilha!",
        "error"
      );
      return;
    }

    if (!file.name.endsWith("csv")) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar planilhas no formato .csv!",
        "error"
      );
      return;
    }
    /* const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    console.log("doc", document);

    reader.onload = async () => {
      if (file.size > 2000000) {
        await this.app.alert(
          `Atenção`,
          `o tamanho do arquivo não pode ser maior que 2mb!`,
          `error`
        );
        return;
      }

      const fileExtension = self.fileNameAndExt(file.name)[1];

      if (fileExtension != "xlsx" && fileExtension != "xls") {
        await this.app.alert(
          `Atenção`,
          `o arquivo deve ser do tipo xls ou xlsx!`,
          `error`
        );
        return;
      }
    };*/
  }

  fileNameAndExt(str) {
    var file = str.split("/").pop();
    return [
      file.substr(0, file.lastIndexOf(".")),
      file.substr(file.lastIndexOf(".") + 1, file.length),
    ];
  }

  async novoUploadMovimentacao() {
    const self = this;
    const { value: formValues } = await Swal.fire({
      title: "Importação Movimentações Mensais",
      html:
        '<div style="display: flex; flex-direction: column;">' +
        '<select id="month-input" class="swal2-input" style="margin-bottom: 10px; width: calc(70% - 5px);">' +
        '<option value="" disabled selected>Selecione o mês</option>' +
        '<option value="01">Janeiro</option>' +
        '<option value="02">Fevereiro</option>' +
        '<option value="03">Março</option>' +
        '<option value="04">Abril</option>' +
        '<option value="05">Maio</option>' +
        '<option value="06">Junho</option>' +
        '<option value="07">Julho</option>' +
        '<option value="08">Agosto</option>' +
        '<option value="09">Setembro</option>' +
        '<option value="10">Outubro</option>' +
        '<option value="11">Novembro</option>' +
        '<option value="12">Dezembro</option>' +
        "</select>" +
        '<input id="year-input" type="number" placeholder="Digite o ano" class="swal2-input" style="margin-bottom: 10px; width: calc(70% - 5px);">' +
        '<input id="file-input" type="file" aria-label="Selecione a planilha" class="swal2-input">' +
        "</div>",
      focusConfirm: false,
      preConfirm: () => {
        const fileInput = document.getElementById(
          "file-input"
        ) as HTMLInputElement;
        const monthInput = document.getElementById(
          "month-input"
        ) as HTMLSelectElement;
        const yearInput = document.getElementById(
          "year-input"
        ) as HTMLInputElement;

        const file = fileInput.files?.[0];
        const month = monthInput.value;
        const year = yearInput.value;

        if (!month) {
          Swal.showValidationMessage("Você deve selecionar um mês!");
          return null;
        }
        if (!year) {
          Swal.showValidationMessage("Você deve informar o ano!");
          return null;
        }
        if (!file) {
          Swal.showValidationMessage("Você deve informar uma planilha!");
          return null;
        }
        if (!file.name.endsWith("xls") && !file.name.endsWith("xlsx")) {
          Swal.showValidationMessage(
            "Você deve informar planilhas no formato .xlsx ou .xls!"
          );
          return null;
        }
        return { file, month, year };
      },
    });

    if (!formValues) {
      return;
    }

    const { file, month, year } = formValues;

    const formattedDate = moment(`${year}-${month}-01`).format("YYYY-MM-DD");

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = e.target?.result;
        if (!data) {
          throw new Error("O arquivo está vazio");
        }

        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        let tipo = file.type;

        console.log("Registros", jsonData.length - 1);

        if (jsonData.length === 1) {
          throw new Error("O arquivo está vazio");
        }

        const headers = jsonData[0];
        if (
          headers[0] !== "Competência" ||
          headers[1] !== "Matrícula" ||
          headers[2] !== "CPF" ||
          headers[3] !== "Salário"
        ) {
          throw new Error("As colunas não têm os nomes corretos.");
        }

        const uploadFiles = await this.apiCall
          .post("servidor/arquivo/exact/url", {
            extension: file.name,
            type: tipo,
          })
          .pipe(take(1))
          .toPromise();

        if (!uploadFiles || !uploadFiles.result) {
          return;
        }
        var options = {
          headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
        };

        const resultado = await self.http
          .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
          .toPromise();
        let urlArquivo = uploadFiles.result.split("?")[0];

        await self.spinner.show(undefined, {
          type: "ball-triangle-path",
          size: "medium",
          bdColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          fullScreen: true,
        });
        await this.apiCall
          .post(
            `servidor/arquivo/planilha?tipo=${77}&cidade=${
              self.idCidade
            }&url=${urlArquivo}&total_rows=${
              jsonData.length
            }&simplificada=false&competencia=${formattedDate}`
          )
          .pipe(take(1))
          .toPromise();
        await self.app.alert(
          "Tudo certo!",
          "Planilha cadastrada com sucesso!",
          "success"
        );
        await self.spinner.hide();
        await self.ngOnInit();
      } catch (error) {
        Swal.fire("Erro", error.message, "error");
      }
    };

    reader.readAsArrayBuffer(file);
  }

  csvJSON(csv) {
    var lines = csv.split("\r\n");
    var result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split(";");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(";");
      if (currentline.length > 1) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  formatarData(data: string): string {
    const date = new Date(data);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  }

  formatarCompetencia(data: string): string {
    const date = new Date(data);
    return `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  }

  traduzirStatus(status: number): string {
    switch (status) {
      case 1:
        return "não importado";
      case 2:
        return "importando";
      case 3:
        return "importado";
      default:
        return "desconhecido";
    }
  }
  downloadInvalidCpfs(invalidCpfs: any): void {
    console.log("CPFs:", invalidCpfs); // Log para depuração
    let cpfArray = [];

    if (typeof invalidCpfs === "string") {
      // Tentativa de converter a string para um array
      try {
        cpfArray = JSON.parse(invalidCpfs);
      } catch (e) {
        console.error("Não foi possível converter invalidCpfs para array:", e);
      }
    } else if (Array.isArray(invalidCpfs)) {
      cpfArray = invalidCpfs;
    }

    if (cpfArray.length > 0) {
      const csvData = Papa.unparse(cpfArray.map((cpf) => ({ CPF: cpf })));
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "invalid_cpfs.csv");
    } else {
      console.error("invalidCpfs não é um array ou está vazio");
    }
  }
  async uploadSimplificado() {
    await this.uploadArquivo(
      "Upload Simplificado Servidores",
      this.colunasEsperadasSimplificado,
      9,
      true
    );
  }

  async uploadIdadeAposentadoria() {
    await this.uploadArquivo(
      "Upload Planilha Idade Aposentadoria",
      this.colunasEsperadasIdadeAposentadoria,
      8,
      false
    );
  }

  async selecionaArquivo(type: number) {
    let title = "";
    let expectedColumns = [];
    switch (type) {
      case 1:
        title = "Upload Servidores";
        expectedColumns = this.colunasEsperadasAtivos;
        break;
      case 2:
        title = "Upload Aposentados";
        expectedColumns = this.colunasEsperadasInativos;
        break;
      case 3:
        title = "Upload Pensionistas";
        expectedColumns = this.colunasEsperadasAposentados;
        break;
    }
    await this.uploadArquivo(title, expectedColumns, type, false);
  }

  private async uploadArquivo(
    title: string,
    expectedColumns: string[],
    type: number,
    isSimplificado: boolean
  ) {
    const self = this;
    const { value: file } = await Swal.fire({
      title: title,
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
    });

    if (!file) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar uma planilha!",
        "error"
      );
      return;
    }

    if (!file.name.endsWith("csv")) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar planilhas no formato .csv!",
        "error"
      );
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      let text = reader.result as string;
      let csvJson = self.csvJSON(text);
      let lista = JSON.parse(csvJson);
      let newLista = [];
      for (let l of lista) {
        const trimmed = Object.entries(l).reduce((acc, curr) => {
          let [key, value] = curr;
          acc[typeof key === "string" ? key.trim() : key] = value;
          return acc;
        }, {});
        newLista.push(trimmed);
      }
      lista = [...newLista];
      let cols = lista ? Object.keys(lista[0]) : [];
      let totalCol = lista ? Object.keys(lista[0]).length : 0;
      let totalDeLinhas = lista.length;
      let tipo = file.type;
      let extension = file.name.split(".").pop().toLowerCase();
      const uploadFiles = await this.apiCall
        .post("servidor/arquivo/exact/url", {
          extension: file.name,
          type: tipo,
        })
        .pipe(take(1))
        .toPromise();

      if (totalCol != expectedColumns.length) {
        await this.app.alert(
          "Atenção!",
          "Quantidade de colunas do seu arquivo é diferente da esperada!",
          "error"
        );
        return;
      }
      const array2Sorted = expectedColumns.slice().sort();
      const isEqual =
        cols.length === expectedColumns.length &&
        cols
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          });
      if (!isEqual) {
        await this.app.alert(
          "Atenção!",
          "O cabeçalho do seu arquivo é diferente do esperado!",
          "error"
        );
        return;
      }

      let ibgeFound = [];
      for (let l of lista) {
        if (l.CO_IBGE && !ibgeFound.find((i) => i == l.CO_IBGE)) {
          ibgeFound.push(l.CO_IBGE);
        }
      }
      if (ibgeFound.length !== 1) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém mais de um código IBGE!",
          "error"
        );
        return;
      }
      if (self.cidade && self.cidade.ibge && ibgeFound[0] != self.cidade.ibge) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém código IBGE inválido! o código esperado para esta cidade é " +
            self.cidade.ibge,
          "error"
        );
        return;
      }
      if (!uploadFiles || !uploadFiles.result) {
        return;
      }
      var options = {
        headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
      };

      const resultado = await self.http
        .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
        .toPromise();
      let urlArquivo = uploadFiles.result.split("?")[0];

      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      await this.apiCall
        .post(
          `servidor/arquivo/planilha?tipo=${type}&cidade=${self.idCidade}&url=${urlArquivo}&total_rows=${totalDeLinhas}&simplificada=${isSimplificado}`
        )
        .pipe(take(1))
        .toPromise();
      await self.app.alert(
        "Tudo certo!",
        "Planilha cadastrada com sucesso!",
        "success"
      );
      await self.spinner.hide();
      await self.ngOnInit();
    };
    reader.readAsText(file as Blob, "ISO-8859-1");
  }
}
