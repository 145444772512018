<div class="row">
    <div class="col">
      <div class="row my-4">
        <div class="col">
          <h4 style="color: #0e6161">Exportação Dinâmica</h4>
          <p style="display: table-cell; color: #0e6161">
            Personalize os campos que deseja exportar
          </p>
        </div>
      </div>
      <div class="row my-4">
        <div class="col">
          <div style="display: table">
            <button
              style="display: table-cell; margin-right: 20px"
              type="button"
              placement="bottom"
              ngbTooltip="Download Servidores Ativos"
              class="btn btn-outline-primary"
              (click)="openModal(ativos)"
            >
              <i class="fa fa-download"></i> Exportar Servidores Ativos
            </button>
           
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col">
          <button
            type="button"
            placement="bottom"
            ngbTooltip="Download Servidores Inativos"
            class="btn btn-outline-primary"
            (click)="openModal(inativos)"
          >
            <i class="fa fa-download"></i> Exportar Servidores Inativos
          </button>
        </div>
      </div>
      <div class="row my-4">
        <div class="col">
          <button
            type="button"
            placement="bottom"
            ngbTooltip="Download Servidores Pensionistas"
            class="btn btn-outline-primary"
            (click)="openModal(pensionistas)"
          >
            <i class="fa fa-download"></i> Exportar Servidores Pensionistas
          </button>
        </div>
      </div>


    </div>

  </div>
  <ngx-spinner></ngx-spinner>

  <ng-template #ativos>
    <div class="modal-body">
        <div class="row">
          <div class="col">
            <!-- Coluna 1 -->
            <div class="form-check mb-3" *ngFor="let campo of camposAtivos.slice(0, 15)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
          <div class="col">
            <!-- Coluna 2 -->
            <div class="form-check mb-3" *ngFor="let campo of camposAtivos.slice(15, 30)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
          <div class="col">
            <!-- Coluna 3 -->
            <div class="form-check mb-3" *ngFor="let campo of camposAtivos.slice(30, 45)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="selecionarTudoAtivos()">Marcar/Desmarcar Tudo</button>
        <button type="button" class="btn btn-primary" (click)="exportarBancoDeDadosAtivos()">Exportar</button>
      </div>
  </ng-template>

  <ng-template #inativos>
    <div class="modal-body">
        <div class="row">
          <div class="col">
            <!-- Coluna 1 -->
            <div class="form-check mb-3" *ngFor="let campo of camposInativos.slice(0, 15)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
          <div class="col">
            <!-- Coluna 2 -->
            <div class="form-check mb-3" *ngFor="let campo of camposInativos.slice(15, 30)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
          <div class="col">
            <!-- Coluna 3 -->
            <div class="form-check mb-3" *ngFor="let campo of camposInativos.slice(30, 45)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="selecionarTudoInativos()">Marcar/Desmarcar Tudo</button>
        <button type="button" class="btn btn-primary" (click)="exportarBancoDeDadosInativos()">Exportar</button>
      </div>
  </ng-template>

  <ng-template #pensionistas>
    <div class="modal-body">
        <div class="row">
          <div class="col">
            <!-- Coluna 1 -->
            <div class="form-check mb-3" *ngFor="let campo of camposPensionistas.slice(0, 15)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
          <div class="col">
            <!-- Coluna 2 -->
            <div class="form-check mb-3" *ngFor="let campo of camposPensionistas.slice(15, 30)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
          <div class="col">
            <!-- Coluna 3 -->
            <div class="form-check mb-3" *ngFor="let campo of camposPensionistas.slice(30, 45)">
              <input class="form-check-input" type="checkbox" [id]="campo.id" [(ngModel)]="campo.checked">
              <label class="form-check-label" [for]="campo.id">{{ campo.label }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="selecionarTudoPensionistas()">Marcar/Desmarcar Tudo</button>
        <button type="button" class="btn btn-primary" (click)="exportarBancoDeDadosPensionistas()">Exportar</button>
      </div>
  </ng-template>
  