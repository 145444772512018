<div class="row text-left mt-3">
  <div class="col-12">
    <h1 class="text-bold-300">Extrato Tempo de Contribuição</h1>
  </div>
</div>

<div class="row text-left mt-3">
  <div class="col-6" style="display: flex; justify-content: center">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="openModalXl(cadastroTempo, null, 0)"
    >
      Cadastrar CTC (RPPS)
    </button>
  </div>
  <div class="col-6" style="display: flex; justify-content: center">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="openModalXl(cadastroTempo, null, 1)"
    >
      Cadastrar CTC (INSS)
    </button>
  </div>
</div>

<div class="row text-left mt-3">
  <div class="col-6" style="display: flex; justify-content: center">
    {{ "Tempo Total Cadastrado RPPS (dias): " + tempoTotalRPPS }}
  </div>
  <div class="col-6" style="display: flex; justify-content: center">
    {{ "Tempo Total Cadastrado INSS (dias): " + tempoTotalINSS }}
  </div>
</div>
<div class="row text-left mt-3">
  <div class="col-6" style="display: flex; justify-content: center">
    {{ "Tempo Total Averbado RPPS (dias): " + tempoTotalRPPSAverbado }}
  </div>
  <div class="col-6" style="display: flex; justify-content: center">
    {{ "Tempo Total Averbado INSS (dias): " + tempoTotalINSSAverbado }}
  </div>
</div>
<hr/>
<div class="row text-left mt-3" *ngIf="tempoTotalCadastrado">
  <div class="col-6" style="display: flex; justify-content: center">
    {{ "Tempo Total Contribuição Cadastrado (dias): " + tempoTotalCadastrado }}
  </div>
  <div class="col-6" style="display: flex; justify-content: center">
    {{ "Tempo Total Contribuição Averbado (dias): " + tempoTotalAverbado }}
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <table class="table text-center m-0">
          <thead>
            <tr>
              <th>CPF/CNPJ</th>
              <th>Empregador</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Tempo Bruto (dias)</th>
              <th>Tipo</th>
              <th>Averbação</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let t of tempos
                  | slice
                    : (page - 1) * pageSize
                    : (page - 1) * pageSize + pageSize
              "
            >
              <td class="pl-0 pr-0">{{ t.cnpj_cpf_empregador }}</td>
              <td class="pl-0 pr-0">{{ t.nome_empregrador }}</td>
              <td class="pl-0 pr-0">{{ getBeautifulDate(t.data_entrada) }}</td>
              <td class="pl-0 pr-0">{{ getBeautifulDate(t.data_fim) }}</td>
              <td class="pl-0 pr-0">{{ t.tempo_bruto }}</td>
              <td class="pl-0 pr-0">
                {{
                  t.rpps_rgps != null
                    ? t.rpps_rgps == 0
                      ? "RPPS"
                      : "INSS"
                    : ""
                }}
              </td>
              <td class="pl-0 pr-0">
                {{
                  t.averbado==1
                    ? "Sim"
                    : "Não"
                }}
              </td>
              <td class="col-2">
                <div
                  class="btn-group btn-group-lg d-none d-md-block"
                  role="group"
                >
                  <button
                    type="button"
                    placement="top"
                    ngbTooltip="Deletar Tempo."
                    class="btn btn-outline-primary btn-icon"
                    (click)="deletarTempo(t.id)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                  <button
                    type="button"
                    placement="top"
                    ngbTooltip="Visualizar Tempo."
                    class="btn btn-outline-primary btn-icon"
                    (click)="openModalXl(cadastroTempo, t)"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination
            style="margin-top: 10px"
            *ngIf="tempos.length > 0"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="12"
            [collectionSize]="tempos.length"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="tempos.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pl-0 pr-0">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum tempo de serviço cadastrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="form-row">
  <div class="col">
    <button
      type="button"
      class="btn btn-outline-primary"
      routerLink="/page/servidor"
      [queryParams]="{ id: idServidor }"
    >
      Voltar
    </button>
  </div>
</div>

<ng-template #edicaoCertidao let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Certidão</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #certidaoForm="ngForm" novalidate>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="numero_certidaoa">Certidão</label>
                      <input
                        type="text"
                        id="numero_certidaoa"
                        class="form-control"
                        [(ngModel)]="certidaoEmEdicao.numero_certidao"
                        name="numero_certidaoa"
                        placeholder="Certidão"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_certidaoa">Data</label>
                      <input
                        type="text"
                        id="data_certidaoa"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="certidaoEmEdicao.data_certidao"
                        name="data_certidaoa"
                        placeholder="Data"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                    (click)="atualizarCertidao(modal)"
                    [disabled]="
                      !certidaoEmEdicao.id ||
                      !certidaoEmEdicao.numero_certidao ||
                      !certidaoEmEdicao.data_certidao
                    "
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cadastroTempo let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Tempo de Serviço Passado</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #servForm="ngForm" novalidate>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cnpj_cpf_empregador"
                        >CNPJ/CPF Empregador</label
                      >
                      <input
                        type="text"
                        id="cnpj_cpf_empregador"
                        class="form-control"
                        [(ngModel)]="tempo.cnpj_cpf_empregador"
                        name="cnpj_cpf_empregador"
                        placeholder="CNPJ/CPF"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome_empregrador">Nome Empregador</label>
                      <input
                        type="text"
                        id="nome_empregrador"
                        class="form-control"
                        [(ngModel)]="tempo.nome_empregrador"
                        name="nome_empregrador"
                        placeholder="Nome"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="naturalizado"
                        >Averbação</label
                      >
                      <ng-select
                        [items]="averbado"
                        name="averbado"
                        bindLabel="name"
                        placeholder="Averbado"
                        bindValue="id"
                        id="averbado"
                        [(ngModel)]="tempo.averbado"
                      ></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_entrada">Data Início</label>
                      <input
                        type="text"
                        id="data_entrada"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="tempo.data_entrada"
                        (change)="calculaTempoBruto()"
                        name="data_entrada"
                        placeholder="Data Início"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_fim">Data Fim</label>
                      <input
                        type="text"
                        id="data_fim"
                        class="form-control"
                        mask="00/00/0000"
                        [(ngModel)]="tempo.data_fim"
                        (change)="calculaTempoBruto()"
                        name="data_fim"
                        placeholder="Data Fim"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tempo_bruto">Tempo Bruto (dias)</label>
                      <input
                        type="text"
                        id="tempo_bruto"
                        class="form-control"
                        [(ngModel)]="tempo.tempo_bruto"
                        name="tempo_bruto"
                        placeholder="Tempo Bruto (dias)"
                        required
                        aria-invalid="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!isTempoCerto()" class="form-row">
                <p style="color: red">
                  <b>A data de início deve ser anterior à data de fim.</b>
                </p>
              </div>
              <div class="form-row">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                    (click)="atualizarOuCadastrar(modal)"
                    [disabled]="
                      !tempo.cnpj_cpf_empregador ||
                      !tempo.nome_empregrador ||
                      !tempo.data_entrada ||
                      !tempo.data_fim ||
                      !isTempoCerto()
                    "
                  >
                    {{ tempo.id ? "Atualizar" : "Cadastrar" }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ngx-spinner></ngx-spinner>
