import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import { saveAs } from "file-saver";

@Component({
  selector: "app-anexos",
  templateUrl: "./anexos.component.html",
  styleUrls: ["./anexos.component.scss"],
})
export class AnexosComponent implements OnInit {
  apiCall = this.api.new().silent();
  anexos = [];
  idServidor;
  page = 1;
  pageSize = 10;

  referencias = [
    "Certidão de Nascimento (no caso de dependente)",
    "Certidão de Casamento (no caso de dependente)",
    "Certidão de óbito (no caso de pensionista)",
    "Termo de Posse",
    "Portaria de nomeação",
    "Concessão de aposentadoria",
    "Comprovante de Endereço",
    "Carteira de Trabalho ",
    "PIS/PASEP",
    "Carteira de Motorista (CNH)",
    "Carteira Identidade (RG)",
    "CPF",
    "Documento Dependente",
    "NIT",
    "Extrato Tempo de Serviço Passado",
    "Outros",
  ];
  anexo = {
    referencia: null,
    arquivo: null,
  };
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {}

  async ngOnInit() {
    const self = this;
    self.anexos = [];
    self.anexo = {
      referencia: null,
      arquivo: null,
    };
    self.route.queryParams.subscribe((parametros) => {
      if (parametros["id"]) {
        self.idServidor = parametros["id"];
      }
    });

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const anexos = await self.apiCall
      .get(`servidor/arquivo/todos?id_servidor=${this.idServidor}`)
      .pipe(take(1))
      .toPromise();

    if (anexos) self.anexos = JSON.parse(JSON.stringify(anexos));

    self.spinner.hide();
  }
  openModalXl(content) {
    const modal = this.modalService.open(content, {
      centered: true,
      size: "xl",
    });
  }
  async selecionaArquivo(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      if (file.size > 5000000) {
        event.target.value = null;
        await this.app.alert(
          `Atenção`,
          `o tamanho do arquivo não pode ser maior que 5mb!`,
          `error`
        );
      } else {
        this.anexo.arquivo = file;
      }
    }
  }
  async deletar(chave, id_arquivo) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (
      await self.app.confirm(
        `Atenção`,
        `Você deseja mesmo deletar este anexo?`,
        `Deletar`
      )
    ) {
      self.apiCall
        .get(`servidor/arquivo/deletar?chave=${chave}&id_arquivo=${id_arquivo}`)
        .subscribe(async (response) => {
          await self.app.alert(`Sucesso`, `Anexo deletado!`, `success`);
          await self.spinner.hide();
          await self.ngOnInit();
        });
    }
  }

  async download(chave, nome) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const url = await self.apiCall
      .get(`banco-dados/files/url`)
      .pipe(take(1))
      .toPromise();
    if (url) saveAs(url + chave, nome);
    self.spinner.hide();
  }
  async cadastrarAnexo(modal) {
    const self = this;
    console.log(self.anexo);
    const formData = new FormData();
    formData.append("file", self.anexo.arquivo);
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const cidade = json.user.nomeCidade;
    const nome = json.user.nome;

    const anexoEnviado = await self.apiCall
      .post(
        `servidor/arquivo/novo?id_servidor=${self.idServidor}&referencia=${self.anexo.referencia}&cidade=${cidade}&nome_servidor=${nome}`,
        formData
      )
      .pipe(take(1))
      .toPromise();
    await self.app.alert(`Sucesso`, `Anexo cadastrado!`, `success`);
    modal.close("Close click");
    await self.spinner.hide();
    await self.ngOnInit();
  }
}
