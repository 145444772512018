<div class="row text-left mb-2">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Relatório de Servidores</h3>
      <div>
        <button
        type="button"
        class="btn btn-outline-primary mr-2"
        [disabled]="this.servidoresModeloPadraoFiltered.length == 0"
        (click)="gerarModeloPadrao()"
      >
          Modelo Padrão
        </button>
        <button
          type="button"
          class="btn btn-outline-primary mr-2"
          [disabled]="this.servidoresFiltered.length == 0"
          (click)="gerarRelatorio()"
        >
          Gerar Relatório
        </button>
        
      </div>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <ng-select
      [items]="tipos"
      name="tipos"
      placeholder="Tipo Relatório"
      id="tipos"
      bindLabel="descricao"
      bindValue="id"
      (change)="alternarRelatorio()"
      [(ngModel)]="statusRelatorio"
    ></ng-select>
  </div>
  <div class="col" *ngIf="statusRelatorio == '1'">
    <ng-select
      [items]="sexos"
      name="sexo"
      bindLabel="name"
      placeholder="Sexo"
      bindValue="id"
      id="sexo"
      (change)="pesquisarPorSexo()"
      [(ngModel)]="sexo"
    ></ng-select>
  </div>
  <div class="col" *ngIf="statusRelatorio == '2'">
    <input
      type="text"
      id="idade"
      class="form-control"
      [(ngModel)]="idade"
      (change)="pesquisarPorIdade()"
      mask="9*"
      name="idade"
      placeholder="Idade"
      required
      aria-invalid="false"
    />
  </div>
  <div class="col" *ngIf="statusRelatorio == '3'">
    <input
      type="text"
      id="quantidade_dependente"
      class="form-control"
      [(ngModel)]="quantidade_dependente"
      (change)="pesquisarPorQuantidadeDependentes()"
      mask="9*"
      name="quantidade_dependente"
      placeholder="Quantidade Dependentes"
      required
      aria-invalid="false"
    />
  </div>
  <div class="col" *ngIf="statusRelatorio == '4'">
    <input
      type="text"
      id="tempoMinimo"
      class="form-control"
      [(ngModel)]="tempoMinimo"
      (change)="pesquisarPorTempoMinimo()"
      mask="9*"
      name="tempoMinimo"
      placeholder="Mínimo (Dias)"
      required
      aria-invalid="false"
    />
  </div>
  <div class="col" *ngIf="statusRelatorio == '4'">
    <input
      type="text"
      id="tempoMaximo"
      class="form-control"
      [(ngModel)]="tempoMaximo"
      (change)="pesquisarPorTempoMaximo()"
      mask="9*"
      name="tempoMaximo"
      placeholder="Máximo (Dias)"
      required
      aria-invalid="false"
    />
  </div>

  <div class="col" *ngIf="statusRelatorio == '5'">
    <input
      type="text"
      id="dataNascimentoMinima"
      class="form-control"
      [(ngModel)]="dataNascimentoMinima"
      (change)="pesquisarPorDataNascimentoMinimoDatePicker()"
      [dropSpecialCharacters]="false"
      mask="00/00/0000"
      name="dataNascimentoMinima"
      placeholder="Data Início"
      required
      aria-invalid="false"
    />
  </div>
  <div class="col" *ngIf="statusRelatorio == '5'">
    <input
      type="text"
      id="dataNascimentoMaxima"
      class="form-control"
      [(ngModel)]="dataNascimentoMaxima"
      (change)="pesquisarPorDataNascimentoMaximoDatePicker()"
      [dropSpecialCharacters]="false"
      mask="00/00/0000"
      name="dataNascimentoMaxima"
      placeholder="Data Fim"
      required
      aria-invalid="false"
    />
  </div>
  <div class="col" *ngIf="statusRelatorio == '6'">
    <input
      type="text"
      id="dataNascimentoDependenteMinima"
      class="form-control"
      [(ngModel)]="dataNascimentoDependenteMinima"
      (change)="pesquisarPorDataNascimentoDependenteMinimoDatePicker()"
      [dropSpecialCharacters]="false"
      mask="00/00/0000"
      name="dataNascimentoDependenteMinima"
      placeholder="Data Início"
      required
      aria-invalid="false"
    />
  </div>
  <div class="col" *ngIf="statusRelatorio == '6'">
    <input
      type="text"
      id="dataNascimentoDependenteMaxima"
      class="form-control"
      [(ngModel)]="dataNascimentoDependenteMaxima"
      (change)="pesquisarPorDataNascimentoDependenteMaximoDatePicker()"
      [dropSpecialCharacters]="false"
      mask="00/00/0000"
      name="dataNascimentoDependenteMaxima"
      placeholder="Data Fim"
      required
      aria-invalid="false"
    />
  </div>
</div>
<div class="row mb-2" *ngIf="servidores.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <table class="table text-center m-0">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Data de Nascimento</th>
              <th>Sexo</th>
              <th>Ativo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let servidor of servidoresFiltered
                  | slice
                    : (page - 1) * pageSize
                    : (page - 1) * pageSize + pageSize
              "
            >
              <td>{{ servidor.nome }}</td>
              <td>{{ servidor.data_nascimento }}</td>
              <td>{{ servidor.sexo === "M" ? "Masculino" : "Feminino" }}</td>
              <td>{{ servidor.ativo ? "sim" : "não" }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  [routerLink]="['/page/servidor']"
                  [queryParams]="{ id: servidor.id }"
                >
                  Visualizar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination
            style="margin-top: 10px"
            *ngIf="servidoresFiltered.length > 0"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="12"
            [boundaryLinks]="true"
            [collectionSize]="servidoresFiltered.length"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="servidores.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum servidor encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="form-row d-flex justify-content-end">
  <div class="col-auto">
    <button  type="button" class="btn btn-outline-primary" (click)="goBack()" >Voltar</button>
  </div>
</div>
<ngx-spinner></ngx-spinner>
