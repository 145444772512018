import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as moment from "moment";
import * as cep from "cep-promise";

@Component({
  selector: 'app-dadospessoais',
  templateUrl: './dadospessoais.component.html',
  styleUrls: ['./dadospessoais.component.scss']
})
export class DadospessoaisComponent implements OnInit {
  contato = {
    id: null,
    id_servidor: null,
    telefone: null,
    celular: null,
    email: null,
  }
  documentos = {
    cpf: null,
    cpts: null,
    data_emissao_cpts: '',
    data_emissao_rg: '',
    id: null,
    id_servidor: null,
    nit: null,
    orgao_expedidor_rg: null,
    rg: null,
    secao_titulo_eleitor: null,
    serie_cpts: null,
    titulo_eleitor:  null,
    uf_rg: null,
    uf_titulo_eleitor:  null,
    zona_titulo_eleitor: null,
  }
  idCidade
  servidor
  orgaosExpedidores = []
  apiCall = this.api.new().silent();
  endereco = {
    cep: null,
    tipo_logradouro: null,
    logradouro: null,
    numero: null,
    id: null,
    id_servidor: null,
    complemento: null,
    bairro: null,
    estado: null,
    cidade: null,
  }
  idServidor
  tiposlogradouro = []
  estados = []
  cidades = []
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }

  async ngOnInit(){
    const self = this
    self.endereco = null
    self.idServidor = null
    self.tiposlogradouro = []
    self.estados = []
    self.cidades = []
    self.endereco = {
      cep: null,
      tipo_logradouro: null,
      logradouro: null,
      numero: null,
      id: null,
      id_servidor: null,
      complemento: null,
      bairro: null,
      estado: null,
      cidade: null,
    }
    self.contato = {
      id: null,
      id_servidor: null,
      telefone: null,
      celular: null,
      email: null,
    }
    self.documentos = null
    self.orgaosExpedidores = []
    self.documentos = {
      cpf: null,
      cpts: null,
      data_emissao_cpts: null,
      data_emissao_rg: null,
      id: null,
      id_servidor: null,
      nit: null,
      orgao_expedidor_rg: null,
      rg: null,
      secao_titulo_eleitor: null,
      serie_cpts: null,
      titulo_eleitor:  null,
      uf_rg: null,
      uf_titulo_eleitor:  null,
      zona_titulo_eleitor: null,
    }
    this.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        this.idServidor = parametros['id'];
      }
      if (parametros['idCidade']) {
        this.idCidade = parametros['idCidade'];
      }
    });

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const estados = await self.apiCall.get('enderecos/estados').pipe(take(1)).toPromise()
    self.estados = JSON.parse(JSON.stringify(estados));

    const tiposlogradouro = await self.apiCall.get('enderecos/tiposlogradouro').pipe(take(1)).toPromise()
    self.tiposlogradouro = JSON.parse(JSON.stringify(tiposlogradouro));

    const endereco = await self.apiCall.get(`servidor/endereco?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise()

    if(endereco) self.endereco = JSON.parse(JSON.stringify(endereco));
    if(self.endereco.cidade) self.endereco.cidade = +self.endereco.cidade

    await self.toggleEstado()


    const servidor = await self.apiCall.get(`servidor?id=${self.idServidor}&id_cidade=${self.idCidade}`).pipe(take(1)).toPromise()
    self.servidor = JSON.parse(JSON.stringify(servidor));
    const orgaosexpedidores = await self.apiCall.get('servidor/orgaosexpedidores').pipe(take(1)).toPromise()
    self.orgaosExpedidores = JSON.parse(JSON.stringify(orgaosexpedidores));

    const documento = await self.apiCall.get(`servidor/documentos?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise()
    if(documento) self.documentos = JSON.parse(JSON.stringify(documento));

    const contato = await self.apiCall.get(`servidor/contatos?id_servidor=${self.idServidor}`).pipe(take(1)).toPromise()
    if(contato) self.contato = JSON.parse(JSON.stringify(contato));

    self.spinner.hide()
  }


  disableDocumentos(){
    const self = this

    if(self.servidor && self.servidor.status && self.servidor.status==1){
      if (
        !self.documentos.cpf ||
        !self.documentos.nit ||
        !self.documentos.uf_rg ||
        !self.documentos.rg ||
        !self.documentos.orgao_expedidor_rg
      ) {
        return true;
      } else {
        return false;
      }
    }else if(self.servidor && self.servidor.status){ 
      if (
        !self.documentos.cpf ||
        !self.documentos.nit ||
        !self.documentos.orgao_expedidor_rg ||
        !self.documentos.uf_rg ||
        !self.documentos.rg ||
        !self.documentos.data_emissao_rg
      ) {
        return true;
      } else {
        return false;
      }
    }else {
      return false
    }
  }
  async atualizarOuCadastrarContato(){
    const self = this;
    if (
      !self.contato ||
      !self.documentos ||
      !self.endereco ||
      !self.idServidor
    ) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (self.contato.id) {
      await self.apiCall
        .get(
          `servidor/contatos/editar?id=${self.contato.id}&telefone=${self.contato.telefone}&celular=${self.contato.celular}&email=${self.contato.email}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/contatos/novo?id_servidor=${self.idServidor}&telefone=${self.contato.telefone}&celular=${self.contato.celular}&email=${self.contato.email}`
        )
        .pipe(take(1))
        .toPromise();
    }

    self.documentos.data_emissao_rg = this.preparaData(
      self.documentos.data_emissao_rg
    );
    self.documentos.data_emissao_cpts = this.preparaData(
      self.documentos.data_emissao_cpts
    );

    if (self.documentos.id) {
      await self.apiCall
        .get(
          `servidor/documentos/editar?id=${self.documentos.id}&cpf=${self.documentos.cpf}&nit=${self.documentos.nit}&rg=${self.documentos.rg}&data_emissao_rg=${self.documentos.data_emissao_rg}&orgao_expedidor_rg=${self.documentos.orgao_expedidor_rg}&uf_rg=${self.documentos.uf_rg}&cpts=${self.documentos.cpts}&serie_cpts=${self.documentos.serie_cpts}&data_emissao_cpts=${self.documentos.data_emissao_cpts}&titulo_eleitor=${self.documentos.titulo_eleitor}&zona_titulo_eleitor=${self.documentos.zona_titulo_eleitor}&secao_titulo_eleitor=${self.documentos.secao_titulo_eleitor}&uf_titulo_eleitor=${self.documentos.uf_titulo_eleitor}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/documentos/novo?id_servidor=${self.idServidor}&cpf=${self.documentos.cpf}&nit=${self.documentos.nit}&rg=${self.documentos.rg}&data_emissao_rg=${self.documentos.data_emissao_rg}&orgao_expedidor_rg=${self.documentos.orgao_expedidor_rg}&uf_rg=${self.documentos.uf_rg}&cpts=${self.documentos.cpts}&serie_cpts=${self.documentos.serie_cpts}&data_emissao_cpts=${self.documentos.data_emissao_cpts}&titulo_eleitor=${self.documentos.titulo_eleitor}&zona_titulo_eleitor=${self.documentos.zona_titulo_eleitor}&secao_titulo_eleitor=${self.documentos.secao_titulo_eleitor}&uf_titulo_eleitor=${self.documentos.uf_titulo_eleitor}`
        )
        .pipe(take(1))
        .toPromise();
    }

    let endereco = { ...self.endereco };
    if (endereco.bairro) endereco.bairro = endereco.bairro.replace(/'/g, "''");
    if (endereco.logradouro)
      endereco.logradouro = endereco.logradouro.replace(/'/g, "''");
    if (endereco.complemento)
      endereco.complemento = endereco.complemento.replace(/'/g, "''");
    if (self.endereco.id) {
      await self.apiCall
        .get(
          `servidor/endereco/editar?id_endereco=${endereco.id}&tipo_logradouro=${endereco.tipo_logradouro}&logradouro=${endereco.logradouro}&numero=${endereco.numero}&complemento=${endereco.complemento}&bairro=${endereco.bairro}&cep=${endereco.cep}&estado=${endereco.estado}&cidade=${endereco.cidade}`
        )
        .pipe(take(1))
        .toPromise();
    } else {
      await self.apiCall
        .get(
          `servidor/endereco/novo?id_servidor=${self.idServidor}&tipo_logradouro=${endereco.tipo_logradouro}&logradouro=${endereco.logradouro}&numero=${endereco.numero}&complemento=${endereco.complemento}&bairro=${endereco.bairro}&cep=${endereco.cep}&estado=${endereco.estado}&cidade=${endereco.cidade}`
        )
        .pipe(take(1))
        .toPromise();
    }
    await self.spinner.hide();
    await this.app.alert(
      "Tudo certo!",
      "Dados pessoais salvos com sucesso!",
      "success"
    );
    await self.ngOnInit();


  }
  consultaCep() {
    const self = this;
    if(!self.endereco.cep){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    cep(self.endereco.cep).then(async local => {
      self.endereco.logradouro = local.street
      self.endereco.bairro = local.neighborhood
      self.endereco.estado = local.state
      await self.toggleEstado()
      const foundCity = self.cidades.find(c=>c.nome.toLowerCase().includes(local.city.toLowerCase()))
      if(foundCity) self.endereco.cidade = foundCity.id
      const foundTipoLogradouro = self.tiposlogradouro.find(t=>t.descricao.toLowerCase().includes(local.street.split(" ")[0].toLowerCase()))
      if(foundTipoLogradouro) self.endereco.tipo_logradouro = foundTipoLogradouro.id
      self.spinner.hide()
    }).catch(erro => {
      self.spinner.hide()
    });
  }
  async toggleEstado(){
    this.cidades = []
    const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+this.endereco.estado).pipe(take(1)).toPromise()
    this.cidades = JSON.parse(JSON.stringify(cidades));
  }
  preparaData(data){
    if(data){
      return moment(data, 'DDMMYYYY').format('YYYY-MM-DD')
    }else{
      data = ''
    }
    return data
  }

}
