import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import Me from "app/helpers/me";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import * as Papa from "papaparse";
import { saveAs } from "file-saver";

@Component({
  selector: "importacao-mensal-component",
  templateUrl: "./importacao-mensal.component.html",
  styleUrls: ["./importacao-mensal.component.scss"],
})
export class ImportacaoMensalComponent implements OnInit {
  planilhas = [];
  apiCall = this.api.new().silent();
  meses = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];
  anos = [
    { id: 2020, nome: "2020" },
    { id: 2021, nome: "2021" },
    { id: 2022, nome: "2022" },
    { id: 2023, nome: "2023" },
    { id: 2024, nome: "2024" },
    { id: 2025, nome: "2025" },
    { id: 2026, nome: "2026" },
    { id: 2027, nome: "2027" },
  ];
  mesUpload = null;
  anoUpload = null;
  idCidade = null;
  jobs = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private me: Me,
    private api: Api,
    private app: App,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    const self = this;

    self.route.queryParams.subscribe(async (parametros) => {
      if (parametros["cidade"]) {
        self.idCidade = parametros["cidade"];
      }
    });

    const planilhas = await self.apiCall
      .get("servidor/arquivo/planilha?id_cidade=" + self.idCidade + "&tipo=77")
      .pipe(take(1))
      .toPromise();

    if (planilhas && planilhas.length > 0) {
      self.jobs = planilhas;
      self.changeDetectorRef.detectChanges(); // Detecção de mudanças
    }
  }
  async selecionaArquivo(event) {
    const self = this;

    const { value: file } = await Swal.fire({
      title: "Upload Simplificado Servidores",
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
    });

    if (!file) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar uma planilha!",
        "error"
      );
      return;
    }

    if (!file.name.endsWith("csv")) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar planilhas no formato .csv!",
        "error"
      );
      return;
    }
    /* const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    console.log("doc", document);

    reader.onload = async () => {
      if (file.size > 2000000) {
        await this.app.alert(
          `Atenção`,
          `o tamanho do arquivo não pode ser maior que 2mb!`,
          `error`
        );
        return;
      }

      const fileExtension = self.fileNameAndExt(file.name)[1];

      if (fileExtension != "xlsx" && fileExtension != "xls") {
        await this.app.alert(
          `Atenção`,
          `o arquivo deve ser do tipo xls ou xlsx!`,
          `error`
        );
        return;
      }
    };*/
  }

  fileNameAndExt(str) {
    var file = str.split("/").pop();
    return [
      file.substr(0, file.lastIndexOf(".")),
      file.substr(file.lastIndexOf(".") + 1, file.length),
    ];
  }

  async novoUploadMovimentacao() {
    const self = this;
    const { value: formValues } = await Swal.fire({
      title: "Importação Movimentações Mensais",
      html:
        '<div style="display: flex; flex-direction: column;">' +
        '<select id="month-input" class="swal2-input" style="margin-bottom: 10px; width: calc(70% - 5px);">' +
        '<option value="" disabled selected>Selecione o mês</option>' +
        '<option value="01">Janeiro</option>' +
        '<option value="02">Fevereiro</option>' +
        '<option value="03">Março</option>' +
        '<option value="04">Abril</option>' +
        '<option value="05">Maio</option>' +
        '<option value="06">Junho</option>' +
        '<option value="07">Julho</option>' +
        '<option value="08">Agosto</option>' +
        '<option value="09">Setembro</option>' +
        '<option value="10">Outubro</option>' +
        '<option value="11">Novembro</option>' +
        '<option value="12">Dezembro</option>' +
        "</select>" +
        '<input id="year-input" type="number" placeholder="Digite o ano" class="swal2-input" style="margin-bottom: 10px; width: calc(70% - 5px);">' +
        '<input id="file-input" type="file" aria-label="Selecione a planilha" class="swal2-input">' +
        "</div>",
      focusConfirm: false,
      preConfirm: () => {
        const fileInput = document.getElementById("file-input") as HTMLInputElement;
        const monthInput = document.getElementById("month-input") as HTMLSelectElement;
        const yearInput = document.getElementById("year-input") as HTMLInputElement;

        const file = fileInput.files?.[0];
        const month = monthInput.value;
        const year = yearInput.value;

        if (!month) {
          Swal.showValidationMessage("Você deve selecionar um mês!");
          return null;
        }
        if (!year) {
          Swal.showValidationMessage("Você deve informar o ano!");
          return null;
        }
        if (!file) {
          Swal.showValidationMessage("Você deve informar uma planilha!");
          return null;
        }
        if (!file.name.endsWith("xls") && !file.name.endsWith("xlsx")) {
          Swal.showValidationMessage(
            "Você deve informar planilhas no formato .xlsx ou .xls!"
          );
          return null;
        }
        return { file, month, year };
      },
    });

    if (!formValues) {
      return;
    }

    const { file, month, year } = formValues;

    const formattedDate = moment(`${year}-${month}-01`).format("YYYY-MM-DD");

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = e.target?.result;
        if (!data) {
          throw new Error("O arquivo está vazio");
        }

        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        let tipo = file.type;

        console.log("Registros", jsonData.length - 1);

        if (jsonData.length === 1) {
          throw new Error("O arquivo está vazio");
        }

        const headers = jsonData[0];
        if (
          headers[0] !== "Competência" ||
          headers[1] !== "Matrícula" ||
          headers[2] !== "CPF" ||
          headers[3] !== "Salário"
        ) {
          throw new Error("As colunas não têm os nomes corretos.");
        }

        const uploadFiles = await this.apiCall
          .post("servidor/arquivo/exact/url", {
            extension: file.name,
            type: tipo,
          })
          .pipe(take(1))
          .toPromise();

        if (!uploadFiles || !uploadFiles.result) {
          return;
        }
        var options = {
          headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
        };

        const resultado = await self.http
          .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
          .toPromise();
        let urlArquivo = uploadFiles.result.split("?")[0];

        await self.spinner.show(undefined, {
          type: "ball-triangle-path",
          size: "medium",
          bdColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          fullScreen: true,
        });
        await this.apiCall
          .post(
            `servidor/arquivo/planilha?tipo=${77}&cidade=${
              self.idCidade
            }&url=${urlArquivo}&total_rows=${
              jsonData.length
            }&simplificada=false&competencia=${formattedDate}`
          )
          .pipe(take(1))
          .toPromise();
        await self.app.alert(
          "Tudo certo!",
          "Planilha cadastrada com sucesso!",
          "success"
        );
        await self.spinner.hide();
        await self.ngOnInit();
      } catch (error) {
        Swal.fire("Erro", error.message, "error");
      }
    };

    reader.readAsArrayBuffer(file);
  }

  csvJSON(csv) {
    var lines = csv.split("\r\n");
    var result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split(";");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(";");
      if (currentline.length > 1) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  formatarData(data: string): string {
    const date = new Date(data);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  }

  formatarCompetencia(data: string): string {
    const date = new Date(data);
    return `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  }

  traduzirStatus(status: number): string {
    switch (status) {
      case 1:
        return "não importado";
      case 2:
        return "importando";
      case 3:
        return "importado";
      default:
        return "desconhecido";
    }
  }
  downloadInvalidCpfs(invalidCpfs: any): void {
    console.log("CPFs:", invalidCpfs); // Log para depuração
    let cpfArray = [];

    if (typeof invalidCpfs === "string") {
      // Tentativa de converter a string para um array
      try {
        cpfArray = JSON.parse(invalidCpfs);
      } catch (e) {
        console.error("Não foi possível converter invalidCpfs para array:", e);
      }
    } else if (Array.isArray(invalidCpfs)) {
      cpfArray = invalidCpfs;
    }

    if (cpfArray.length > 0) {
      const csvData = Papa.unparse(cpfArray.map((cpf) => ({ CPF: cpf })));
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "invalid_cpfs.csv");
    } else {
      console.error("invalidCpfs não é um array ou está vazio");
    }
  }
}
