
<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Endereço</h3>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <form class="card-body">
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cep">CEP</label>
                    <input type="text" id="cep" class="form-control" [(ngModel)]="endereco.cep" mask="00.000-000" name="cep" placeholder="CEP" (change)="consultaCep()"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tiposlogradouro">Tipo Logradouro</label>
                    <ng-select [items]="tiposlogradouro"   name="tiposlogradouro" bindLabel="descricao" bindValue="id"  placeholder="Tipo Logradouro" id="tiposlogradouro"  [(ngModel)]="endereco.tipo_logradouro"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="logradouro">Logradouro</label>
                    <input type="text" id="logradouro" class="form-control" [(ngModel)]="endereco.logradouro" name="logradouro" placeholder="Logradouro"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="numero">Número</label>
                    <input type="text" id="numero" class="form-control" [(ngModel)]="endereco.numero" name="numero" placeholder="Número"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="bairro">Bairro</label>
                    <input type="text" id="bairro" class="form-control" [(ngModel)]="endereco.bairro"   name="bairro" placeholder="Bairro"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="complemento">Complemento</label>
                    <input type="text" id="complemento" class="form-control" [(ngModel)]="endereco.complemento" name="complemento" placeholder="Complemento"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="estado">Estado</label>
                    <ng-select [items]="estados"  name="estado" (change)="toggleEstado()"  bindLabel="estado" bindValue="estado"  placeholder="Estado" id="estado" [(ngModel)]="endereco.estado"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cidade">Cidade</label>
                    <ng-select [items]="cidades" [disabled]="!cidades || cidades.length==0"  name="cidade"  bindLabel="nome" bindValue="id"  placeholder="Cidade" id="cidade" [(ngModel)]="endereco.cidade"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col" >
                <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidor" [queryParams]="{id: idServidor}" >Voltar</button>
              </div>
              <div class="col" >
                <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right" (click)="atualizarOuCadastrar()" [disabled]="!endereco.cep || !endereco.tipo_logradouro || !endereco.logradouro || !endereco.bairro || !endereco.complemento || !endereco.cidade || !endereco.estado || !endereco.numero" >{{endereco.id ? 'Atualizar' : 'Cadastrar'}}</button>
              </div>
            </div>
          </form>
        </form>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
