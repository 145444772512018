
<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Contatos</h3>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <form class="card-body">
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="telefone">Telefone</label>
                    <input type="text" id="telefone" class="form-control" [(ngModel)]="contato.telefone" mask="(00) 0000-0000" name="telefone" placeholder="Telefone"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="celular">Celular</label>
                    <input type="text" id="celular" class="form-control" [(ngModel)]="contato.celular" mask="(00) 00000-0000" name="celular" placeholder="Celular"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="email">Email</label>
                    <input type="text" id="email" class="form-control"  [(ngModel)]="contato.email" name="email" placeholder="Email"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col" >
                <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidor" [queryParams]="{id: idServidor}" >Voltar</button>
              </div>
              <div class="col" >
                <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right" (click)="atualizarOuCadastrar()" [disabled]="!contato.celular && !contato.email && !contato.telefone" >{{contato.id ? 'Atualizar' : 'Cadastrar'}}</button>
              </div>
            </div>
          </form>
        </form>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
