<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300">Importação Mensal</h1>
    </div>
  </div>
</div>

<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Nova Importação</h4>
      </div>
      <div class="card-content">
        <div class="card-body">          
          <div class="form-row">
            <div class="col">
              <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                      (click)="novoUploadMovimentacao()"><i class="fa fa-plus"></i> Adicionar Importação</button>
            </div>
          </div>
          
          <!-- Tabela de Importação -->
          <div class="table-responsive mt-4" *ngIf="jobs && jobs.length > 0">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Arquivo</th>
                  <th>Status</th>
                   <th>Data de Inclusão</th>
                  <th>Linhas Totais</th>
                  <th>Linhas Inseridas</th>
                  <th>Competência</th>
                  <th>CPFs Inválidos</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let importacao of jobs">
                  <td><a [href]="importacao.url" target="_blank">Download</a></td>
                  <td>{{ traduzirStatus(importacao.status) }}</td>
                  <td>{{ formatarData(importacao.data_inclusao) }}</td>
                  <td>{{ importacao.total_rows }}</td>
                  <td>{{ importacao.inserted_rows }}</td>
                  <td>{{ formatarCompetencia(importacao.competencia) }}</td>
                  <td> <button *ngIf="importacao.invalid_cpfs && importacao.invalid_cpfs.length > 0" 
                    (click)="downloadInvalidCpfs(importacao.invalid_cpfs)" 
                    class="btn btn-sm btn-secondary">Download</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Fim da Tabela de Importação -->
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
