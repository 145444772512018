<div>
  <h1>GRUPO GERAL</h1>
</div>
<br/>
<div class="row">
  <div class="col-md-6">
    <h5>Distribuição da Massa Segurada</h5>
    <canvas baseChart [datasets]="insuredMassData" [labels]="insuredMassLabels" [options]="chartOptionsPie" [chartType]="pieType"></canvas>
  </div>
  <div class="col-md-6">
    <h5>Distribuição por Sexo</h5>
    <canvas baseChart [datasets]="distributionbySexData" [labels]="distributionbySexLabels" [options]="chartOptionsPie" [chartType]="pieType"></canvas>
  </div>
</div>
<br/><br/>
<div>
  <h1>GRUPOS ATIVOS</h1>
</div>
<br/>
<div class="row">
  <div class="col-md-6">
    <h5>Salário Médio dos Servidores Ativos</h5>
    <canvas baseChart [datasets]="averageSalaryActiveServerData"
    [options]="chartOptionsBar" [chartType]="barType">
  </canvas>
  </div>
  <div class="col-md-6">
    <h5>Distribuição por Sexo - Ativos</h5>
  <canvas baseChart [datasets]="distributionbySexActiveData" [labels]="distributionbySexActiveLabels"
   [options]="chartOptionsPie" [chartType]="pieType"></canvas>
</div>
</div>
<br/>
<div class="row">
  <div class="col-md-6">
    <h5>Distribuição por Idade - Ativos</h5>
    <canvas baseChart [datasets]="distributionbyAgeData" [labels]="distributionbyAgeLabels" [options]="chartOptionsBar" [chartType]="barType"></canvas>
  </div>
  <div class="col-md-6">
    <h5>Idade Média por Sexo - Inativos</h5>
    <canvas baseChart [datasets]="distributionbySexInativeData"
     [options]="chartOptionsBar" [chartType]="barType">
    </canvas>
  </div>
</div>
<br/><br/>
<div>
  <h1>GRUPO APOSENTADOS</h1>
</div>
<br/>
<div class="row">
  <div class="col-md-6">
    <h5>Distribuição por Sexo - Inativos</h5>
    <canvas baseChart [datasets]="distributionbySexRetiredInactiveData" [labels]="distributionbySexRetiredInactiveLabels"
     [options]="chartOptionsPie" [chartType]="pieType">
    </canvas>
  </div>
  <div class="col-md-6">
    <h5>Valor Médio de Benefícios dos Servidores Inativos</h5>
    <canvas baseChart [datasets]="averageSalaryRetiredInactiveData"
    [options]="chartOptionsBar" [chartType]="barType">
  </canvas>
  </div>
</div>
<br/><br/>
<div>
  <h1>GRUPO PENSIONISTAS</h1>
</div>
<br/>
<div class="row">
  <div class="col-md-6">
    <h5>Distribuição por Sexo - Pensionistas</h5>
    <canvas baseChart [datasets]="distributionbySexPensionersData" [labels]="distributionbySexPensionersLabels"
     [options]="chartOptionsPie" [chartType]="pieType">
    </canvas>
  </div>
  <div class="col-md-6">
    <h5>Idade Média por Sexo - Pensionistas</h5>
    <canvas baseChart [datasets]="distributionbySexPensionoersData"
     [options]="chartOptionsBar" [chartType]="barType">
    </canvas>
  </div>
</div>
<br/>
<div class="row">
  <div class="col-md-6">
    <h5>Distribuição por Idade Pensionistas</h5>
    <canvas baseChart [datasets]="distributionbyAgePensionersData"
    [labels]="distributionbyAgePensionersLabels"
     [options]="chartOptionsBar" [chartType]="barType"></canvas>
  </div>
  <div class="col-md-6">
    <h5>Valor Médio de Benefícios dos Pensionistas</h5>
    <canvas baseChart [datasets]="averageSalaryPensionersData"
    [options]="chartOptionsBar" [chartType]="barType">
  </canvas>
  </div>
</div>


