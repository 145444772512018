import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import Api from "./api";
import {take} from "rxjs/operators";
import config from '../config';
import { NgxSpinnerService } from 'ngx-spinner';

export class User {
  [x: string]: any;
  id: number;
  nome: string;
  cidade: string;
  nomeCidade: string;
  login: string;
  ativo: boolean;
  tipo: string;
  token?: string;
  id_log: number;
}


@Injectable()
export default class Me {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private spinner: NgxSpinnerService;

  constructor(private http: HttpClient, public api: Api) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  atualizaCidade(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return user
  }

  async login(login: string, senha: string, tipoUsuario: string) {
    let user = null
    try{
      user =  await this.http.post<any>(`${config.api.production_link}/users/authenticate`, { login, senha, tipoUsuario }).pipe(take(1)).toPromise();
      console.log(user)
      // store user details and jwt token in local storage to keep user logged in between page refreshes
    }catch(e){
    }finally {
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
    }
  }

  async logout() {
    try{

      if (this.currentUserValue?.user?.tipo === '2') {
        const id_log = this.currentUserValue.id_log;
      await this.http.post<any>(`${config.api.production_link}/users/logout`, { id_log }).pipe(take(1)).toPromise();
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      } else {
         // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
      }
      
    }catch(e){
    }finally {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
    }
  
  }
}
