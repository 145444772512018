<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Lista de Orgãos por Cidade</h3>
      <button  type="button" class="btn btn-outline-primary" (click)="openModalXl(cadastroOrgao, null)">Novo Orgão</button>
    </div>
  </div>
</div>

<div class="row mb-2" *ngIf="orgaos.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">

        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Descrição</th>
            <th>Poder</th>
            <th>Administração</th>
            <th>Ativo</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let orgao of orgaos| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <td>{{orgao.descricao}}</td>
            <td>{{orgao.poder}}</td>
            <td>{{orgao.administracao}}</td>
            <td>{{orgao.ativo ? 'sim' : 'não'}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="alterarStatusOrgao(orgao.id, orgao.ativo)">{{orgao.ativo ? 'Inativar' : 'Ativar' }}</button>
            </td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="openModalXl(cadastroOrgao, orgao)">Visualizar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px" *ngIf="orgaos.length>0"
                          [(page)]="page"
                          [pageSize]="pageSize"  [maxSize]="12"
                          [collectionSize]="orgaos.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="orgaos.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum orgão encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #cadastroOrgao let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Orgão</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #servForm="ngForm" novalidate>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cnpj_orgao">CNPJ</label>
                      <input type="text" id="cnpj_orgao" class="form-control" [(ngModel)]="orgao.cnpj" name="cnpj_orgao" mask="00.000.000/0000-00" placeholder="CNPJ"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="descricao">Descrição</label>
                      <input type="text" id="descricao" class="form-control" [(ngModel)]="orgao.descricao" name="descricao"  placeholder="Descrição"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="poder">Poder</label>
                      <ng-select [items]="poderes" [appendTo]="'body'" name="poder"  placeholder="Tipo Representatividade" id="Poder"  [(ngModel)]="orgao.poder"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="administracao">Admninistração</label>
                      <ng-select [items]="administracoes"  name="administracao"  placeholder="Tipo Administração" id="Admninistração"  [(ngModel)]="orgao.administracao"></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col" >
                  <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                          (click)="atualizarOuCadastrar(modal)"
                          [disabled]="
                          !orgao.cnpj
                          || !orgao.descricao
                          || !orgao.poder
                          || !orgao.administracao
                           ">{{orgao.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ngx-spinner></ngx-spinner>
