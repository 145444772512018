import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import {ExcelService} from "../helpers/excel.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-relatorios-municipio',
  templateUrl: './relatorios-municipio.component.html',
  styleUrls: ['./relatorios-municipio.component.scss']
})
export class RelatoriosMunicipioComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1
  pageSize = 10
  tipos = [
    {
      id: "0",
      descricao: "Todos"
    },
    {
      id: "1",
      descricao: "Sexo"
    },
    {
      id: "2",
      descricao: "Idade"
    },
    {
      id: "3",
      descricao: "Quantidade Dependentes"
    },
    {
      id: "4",
      descricao: "Tempo Total Serviço"
    },
    {
      id: "5",
      descricao: "Data Nascimento"
    },
    {
      id: "6",
      descricao: "Data Nascimento Dependentes"
    },
  ]
  sexos = [

    {
      id: 'M',
      name: 'Masculino',
    },{
      id: 'F',
      name: 'Feminino',
    }]
  servidores = []
  servidoresFiltered = []
  servidoresModeloPadraoFiltered = []
  cidade
  nomeFiltro = '';
  statusRelatorio = '0'
  sexo = null
  idade = null
  quantidade_dependente = null
  tempoMinimo = null
  tempoMaximo = null
  dataNascimentoMinima = null
  dataNascimentoMaxima = null
  dataNascimentoDependenteMinima = null
  dataNascimentoDependenteMaxima = null
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private excelService:ExcelService,
    private me: Me,
    private app: App,
    private api: Api,
    private location: Location
  ) {
  }

  goBack() {
    this.location.back();
  }

  async ngOnInit(){
    const self = this
    self.servidores = []
    self.servidoresFiltered = []
    self.servidoresModeloPadraoFiltered = []
    self.route.queryParams.subscribe( parametros => {
      if (parametros['cidade']) {
        self.cidade = parametros['cidade'];
      }
    });
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const servidores = await self.apiCall.get('servidor/relatorio/todos?id_cidade='+self.cidade).pipe(take(1)).toPromise()
    const servidoresModeloPadrao = await self.apiCall.get('servidor/relatorio/modelopadrao?id_cidade='+self.cidade).pipe(take(1)).toPromise()
    this.spinner.hide()
    self.servidores = JSON.parse(JSON.stringify(servidores));
    self.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
    self.servidoresModeloPadraoFiltered = JSON.parse(JSON.stringify(servidoresModeloPadrao));
  }

  async alternarRelatorio(){
    this.sexo = null
    this.idade = null
    this.quantidade_dependente = null
    this.tempoMinimo = null
    this.tempoMaximo = null
    this.dataNascimentoMinima = null
    this.dataNascimentoMaxima = null
    this.dataNascimentoDependenteMinima = null
    this.dataNascimentoDependenteMaxima = null
    if(this.statusRelatorio=='0'){
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get('servidor/relatorio/todos?id_cidade='+this.cidade).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorSexo(){
    if(this.sexo){
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/sexo?id_cidade=${this.cidade}&sexo=${this.sexo}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorIdade(){
    if(this.idade){
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/idade?id_cidade=${this.cidade}&idade_minima=${this.idade}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorTempoMinimo(){
    if(this.tempoMinimo && this.tempoMaximo) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/tempo_servico_total?id_cidade=${this.cidade}&tempo_minimo=${this.tempoMinimo}&tempo_maximo=${this.tempoMaximo}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorTempoMaximo(){
    if(this.tempoMinimo && this.tempoMaximo) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/tempo_servico_total?id_cidade=${this.cidade}&tempo_minimo=${this.tempoMinimo}&tempo_maximo=${this.tempoMaximo}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorQuantidadeDependentes(){
    if(this.quantidade_dependente){
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/quantidade_dependentes?id_cidade=${this.cidade}&quantidade=${this.quantidade_dependente}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorDataNascimentoMinimoDatePicker(){
    console.log(this.dataNascimentoMinima)
    console.log(this.dataNascimentoMaxima)
    if(this.dataNascimentoMinima && this.dataNascimentoMaxima) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/data_nascimento?id_cidade=${this.cidade}&data_nascimento_inicio=${this.dataNascimentoMinima}&data_nascimento_fim=${this.dataNascimentoMaxima}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorDataNascimentoMinimo(){
    if(this.dataNascimentoMinima && this.dataNascimentoMaxima) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/data_nascimento?id_cidade=${this.cidade}&data_nascimento_inicio=${this.dataNascimentoMinima}&data_nascimento_fim=${this.dataNascimentoMaxima}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }

  }
  gerarRelatorio(){
    this.excelService.exportAsExcelFile(this.servidoresFiltered, 'servidores');
  }
  gerarModeloPadrao(){
    this.excelService.exportAsExcelFile(this.servidoresModeloPadraoFiltered, 'relatorio_modelo_padrao');
  }
  async pesquisarPorDataNascimentoMaximoDatePicker(){
    if(this.dataNascimentoMinima && this.dataNascimentoMaxima) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/data_nascimento?id_cidade=${this.cidade}&data_nascimento_inicio=${this.dataNascimentoMinima}&data_nascimento_fim=${this.dataNascimentoMaxima}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorDataNascimentoMaximo(){
    if(this.dataNascimentoMinima && this.dataNascimentoMaxima) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/data_nascimento?id_cidade=${this.cidade}&data_nascimento_inicio=${this.dataNascimentoMinima}&data_nascimento_fim=${this.dataNascimentoMaxima}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorDataNascimentoDependenteMinimoDatePicker(){
    if(this.dataNascimentoDependenteMinima && this.dataNascimentoDependenteMaxima) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/data_nascimento_dependente?id_cidade=${this.cidade}&data_nascimento_inicio=${this.dataNascimentoDependenteMinima}&data_nascimento_fim=${this.dataNascimentoDependenteMaxima}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorDataNascimentoDependenteMinimo(){
    if(this.dataNascimentoDependenteMinima && this.dataNascimentoDependenteMaxima) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/data_nascimento_dependente?id_cidade=${this.cidade}&data_nascimento_inicio=${this.dataNascimentoDependenteMinima}&data_nascimento_fim=${this.dataNascimentoDependenteMaxima}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }

  }
  async pesquisarPorDataNascimentoDependenteMaximoDatePicker(){
    if(this.dataNascimentoDependenteMinima && this.dataNascimentoDependenteMaxima) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/data_nascimento_dependente?id_cidade=${this.cidade}&data_nascimento_inicio=${this.dataNascimentoDependenteMinima}&data_nascimento_fim=${this.dataNascimentoDependenteMaxima}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }
  async pesquisarPorDataNascimentoDependenteMaximo(){
    if(this.dataNascimentoDependenteMinima && this.dataNascimentoDependenteMaxima) {
      this.servidores = []
      this.servidoresFiltered = []
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const servidores = await this.apiCall.get(`servidor/relatorio/data_nascimento_dependente?id_cidade=${this.cidade}&data_nascimento_inicio=${this.dataNascimentoDependenteMinima}&data_nascimento_fim=${this.dataNascimentoDependenteMaxima}`).pipe(take(1)).toPromise()
      this.servidores = JSON.parse(JSON.stringify(servidores));
      this.servidoresFiltered = JSON.parse(JSON.stringify(servidores));
      this.spinner.hide();
    }
  }

}
