<div class="row mb-2" *ngIf="planilhas.length > 0">
    <div class="col">
      <div class="card-content">
        <div class="card-body pr-0 pl-0">
  
          <table class="table text-center m-0">
            <thead>
            <tr>
              <th>Planilha</th>
              <th>Tipo</th>
              <th>Status</th>
              <th>Linhas Totais</th>
              <th>Linhas Importadas</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let p of planilhas">
              <td>{{beautifulUrl(p.url)}}</td>
              <td>{{p.type && p.type=='1' ? 'Servidores' : (p.type && p.type=='2' ? 'Aposentados' : (p.type && p.type=='3' ? 'Pensionistas' : ''))}}</td>
              <td>{{p.status && p.status=='1' ? 'Pendente' : (p.status && p.status=='2' ? 'Em Execução' : (p.status && p.status=='3' ? 'Executado' : ''))}}</td>
              <td>{{p.total_rows}}</td>
              <td>{{p.inserted_rows}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
