<div class="row text-left mt-3">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300">Anexos</h1>
      <button  type="button" class="btn btn-outline-primary" (click)="openModalXl(cadastroAnexo)">Novo Anexo</button>
    </div>
  </div>
</div>
<div class="row mb-2" *ngIf="anexos.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">

        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Referência</th>
            <th>Nome</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let anexo of anexos| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <td>{{anexo.referencia}}</td>
            <td>{{anexo.nome}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="download(anexo.chave, anexo.nome)">Download</button>
            </td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="deletar(anexo.chave, anexo.id)" >Deletar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px" *ngIf="anexos.length>0"
                          [(page)]="page"
                          [pageSize]="pageSize"  [maxSize]="12"
                          [collectionSize]="anexos.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="anexos.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pl-0 pr-0">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum arquivo anexado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="form-row">
  <div class="col" >
    <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidor" [queryParams]="{id: idServidor}" >Voltar</button>
  </div>
</div>



<ng-template #cadastroAnexo let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Novo Anexo</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #servForm="ngForm" novalidate>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="referencias">Referência</label>
                      <ng-select [appendTo]="'body'" [items]="referencias"   name="referencias"  placeholder="Referência" id="referencias" [(ngModel)]="anexo.referencia"></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="arquivo">Arquivo</label>
                      <input type="file" name="arquivo" id="arquivo" class="form-control-file" (change)="selecionaArquivo($event)" />

                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col" >
                  <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"

                          (click)="cadastrarAnexo(modal)" [disabled]="!anexo.arquivo || !anexo.referencia">Cadastrar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>


<ngx-spinner></ngx-spinner>
