<div>
    <h1 class="my-4">Bem-vindo a <span style="color: #279590">PLATAFORMA CENSUS</span></h1>
    <h3 class="my-4">{{cidade}}</h3>
    <h4 class="my-4" style="color: #0e6161; text-indent:4em; text-align: justify;">A <b>PLATAFORMA CENSUS</b> foi desenvolvida visando automatizar os processos de gerenciamento de base de dados, proporcionar  melhoria na gestão do RPPS e também pode ser utilizada como ferramenta de execução do censo previdenciário.
    </h4>
    <div class="row justify-content-between my-4" style="background-color: #e5f2df;">
        <div class="col-3 d-flex align-items-center">
                <h4 style="color: #2d6d9a"><b>Automação cadastral</b></h4>
        </div>
        <div class="col-9">
            <ul>
                <li>Atualização contínua das informações dos segurados ativos, inativos, pensionistas e dependentes.</li>
                <li>Automação da Base de Dados para Avaliação Atuarial, ou seja, não será necessário aqueles preenchimentos nas planilhas de EXCEL.</li>
                <li>Importar informações e Exportar a base de dados apenas com UM CLIQUE e já no LAYOUT padrão.</li>
                <li>Módulo para realização de censo previdenciário tanto de forma física como remoto (com reconhecimento facial).</li>
            </ul>
        </div>
    </div>
    <div class="row justify-content-between my-4">
        <div class="col-3 d-flex align-items-center">
            <h4 style="color: #2d6d9a"><b>Segurança na informação de dados</b></h4>
        </div>
        <div class="col-9">
            <ul>
                <li>Sistema criptografado e de acordo com as exigências da LGPD, contando com as mais recentes tecnologias de segurança de informação.</li>
            </ul>
        </div>
    </div>
</div>
