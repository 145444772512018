
<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Dados Bancários</h3>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <form class="card-body">
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="banco">Banco</label>
                    <ng-select [items]="bancos"   name="banco" bindLabel="descricao" bindValue="id"  placeholder="Banco" id="banco"  [(ngModel)]="bancario.banco"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tipo_conta">Tipo Conta</label>
                    <ng-select [items]="tiposConta"   name="tipo_conta" bindLabel="descricao" bindValue="id"  placeholder="Tipo Conta" id="tipo_conta"  [(ngModel)]="bancario.tipo_conta"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="agencia">Agência</label>
                    <input type="text" id="agencia" class="form-control" [(ngModel)]="bancario.agencia" mask="0000-99"   name="agencia" placeholder="Agência"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="conta">Conta</label>
                    <input type="text" id="conta" class="form-control" [(ngModel)]="bancario.conta" mask="999999999999999999999999999999" name="conta" placeholder="Conta"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="digito_conta">Dígito Conta</label>
                    <input type="text" id="digito_conta" class="form-control" [(ngModel)]="bancario.digito_conta" mask="99" name="digito_conta" placeholder="Dígito Conta"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="dac">DAC</label>
                    <input type="text" id="dac" class="form-control" [(ngModel)]="bancario.dac" mask="999999999999999999999999999999" name="dac" placeholder="DAC"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col" >
                <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidor" [queryParams]="{id: idServidor}" >Voltar</button>
              </div>
              <div class="col" >
                <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right" (click)="atualizarOuCadastrar()" [disabled]="!bancario.agencia || !bancario.conta || !bancario.banco || !bancario.tipo_conta || !bancario.digito_conta" >{{bancario.id ? 'Atualizar' : 'Cadastrar'}}</button>
              </div>
            </div>
          </form>
        </form>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
