import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import * as moment from 'moment'
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'app/helpers/excel.service';
import Me from 'app/helpers/me';


@Component({
  selector: "app-relatorios-base",
  templateUrl: "./relatorios-base.component.html",
  styleUrls: ["./relatorios-base.component.scss"],
})
export class RelatoriosBaseComponent implements OnInit {
  apiCall = this.api.new().silent();
  idCidade = null;
  cidade = null;
  servidoresAtivos = [];
  servidoresInativos = [];
  servidoresPensionistas = [];
  colunasEsperadasInativos = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_POPULACAO",
    "CO_TIPO_CARGO",
    "CO_TIPO_APOSENTADORIA",
    "ID_APOSENTADO_MATRICULA",
    "ID_APOSENTADO_CPF",
    "ID_APOSENT_PIS_PASEP",
    "CO_SEXO_APOSENTADO",
    "CO_EST_CIVIL_APOSENTADO",
    "DT_NASC_APOSENTADO",
    "DT_INICIO_APOSENTADORIA",
    "VL_APOSENTADORIA",
    "VL_CONTRIBUICAO",
    "IN_PARID_SERV",
    "CO_CONDICAO_APOSENTADO",
    "VL_COMPENS_PREVID",
    "NU_DEPENDENTES",
    "DT_NASC_CONJUGE",
    "CO_CONDICAO_CONJUGE",
    "DT_NASC_DEPENDENTE_1",
    "CO_CONDICAO_DEPENDENTE_1",
    "DT_NASC_DEPENDENTE_2",
    "CO_CONDICAO_DEPENDENTE_2",
  ];

  colunasEsperadasAposentados = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_INSTITUIDOR",
    "ID_INSTITUIDOR_MATRICULA",
    "ID_INSTITUIDOR_CPF",
    "ID_INSTITUIDOR_PIS_PASEP",
    "ID_PENSIONISTA_CPF",
    "CO_SEXO_PENSIONISTA",
    "DT_NASC_PENSIONISTA",
    "DT_INICIO_PENSAO",
    "VL_BENEF_PENSAO",
    "VL_CONTRIBUICAO",
    "VL_COMPENS_PREVID",
    "IN_PARID_SERV",
    "CO_CONDICAO",
    "CO_DURACAO",
  ];
  colunasEsperadasSimplificado = [
    "CO_IBGE",
    "ID_SERVIDOR_MATRICULA",
    "ID_SERVIDOR_CPF",
    "STATUS_SERVIDOR",
    "NOME_SERVIDOR",
  ];
  colunasEsperadasAtivos = [
    "NU_ANO",
    "CO_IBGE",
    "NO_ENTE",
    "SG_UF",
    "CO_COMP_MASSA",
    "CO_TIPO_FUNDO",
    "NU_CNPJ_ORGAO",
    "NO_ORGAO",
    "CO_PODER",
    "CO_TIPO_PODER",
    "CO_TIPO_POPULACAO",
    "CO_TIPO_CARGO",
    "CO_CRITERIO_ELEGIBILIDADE",
    "ID_SERVIDOR_MATRICULA",
    "ID_SERVIDOR_CPF",
    "ID_SERVIDOR_PIS_PASEP",
    "CO_SEXO_SERVIDOR",
    "CO_EST_CIVIL_SERVIDOR",
    "DT_NASC_SERVIDOR",
    "CO_SITUACAO_FUNCIONAL",
    "CO_TIPO_VINCULO",
    "DT_ING_SERV_PUB",
    "DT_ING_ENTE",
    "DT_ING_CARREIRA",
    "NO_CARREIRA",
    "DT_ING_CARGO",
    "NO_CARGO",
    "VL_BASE_CALCULO",
    "VL_REMUNERACAO",
    "NU_TEMPO_RGPS",
    "NU_TEMPO_RPPS",
    "NU_DEPENDENTES",
    "DT_NASC_CONJUGE",
    "CO_COND_CONJUGE",
    "DT_NASC_DEPENDENTE_1",
    "CO_COND_DEPENDENTE_1",
    "DT_NASC_DEPENDENTE_2",
    "CO_COND_DEPENDENTE_2",
    "IN_ABONO_PERMANENCIA",
    "DT_INICIO_ABONO",
    "DT_PROV_APOSENT",
  ];

  constructor(
    private spinner: NgxSpinnerService,
    private me: Me,
    private excelService: ExcelService,
    private app: App,
    private http: HttpClient,
    private api: Api
  ) {}

  async ngOnInit() {
    const self = this;
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    self.idCidade = json.user.cidade;
    const responseCidade = await self.apiCall
      .get("enderecos/cidade?id=" + self.idCidade)
      .pipe(take(1))
      .toPromise();
    if (responseCidade && responseCidade[0]) self.cidade = responseCidade[0];
  }

  async selecionaArquivo(type) {
    const self = this;
    const { value: file } = await Swal.fire({
      title:
        type == 1
          ? "Upload Servidores"
          : type == 2
          ? "Upload Aposentados"
          : "Upload Pensionistas",
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
    });

    if (!file) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar uma planilha!",
        "error"
      );
      return;
    }

    if (!file.name.endsWith("csv")) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar planilhas no formato .csv!",
        "error"
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = async () => {
      let text = reader.result;
      let csvJson = self.csvJSON(text);
      let lista = JSON.parse(csvJson);
      let newLista = [];
      for (let l of lista) {
        // @ts-ignore
        const trimmed = Object.entries(l).reduce((acc, curr) => {
          let [key, value] = curr;
          // Checking if the key is a string
          acc[typeof key === "string" ? key.trim() : key] = value;
          return acc;
        }, {});
        newLista.push(trimmed);
      }
      lista = [...newLista];
      let cols = lista ? Object.keys(lista[0]) : [];
      let totalCol = lista ? Object.keys(lista[0]).length : 0;
      let totalDeLinhas = lista.length;
      let tipo = file.type;
      let extension = file.name.split(".").pop().toLowerCase();
      const uploadFiles = await this.apiCall
        .post("servidor/arquivo/exact/url", {
          extension: file.name,
          type: tipo,
        })
        .pipe(take(1))
        .toPromise();

      if (type == 1) {
        if (totalCol != 41) {
          await this.app.alert(
            "Atenção!",
            "Quantidade de colunas do seu arquivo é diferente da esperada!",
            "error"
          );
          return;
        }
        const array2Sorted = this.colunasEsperadasAtivos.slice().sort();
        const isEqual =
          cols.length === this.colunasEsperadasAtivos.length &&
          cols
            .slice()
            .sort()
            .every(function (value, index) {
              return value === array2Sorted[index];
            });
        if (!isEqual) {
          await this.app.alert(
            "Atenção!",
            "O cabeçalho do seu arquivo é diferente do esperado!",
            "error"
          );
          return;
        }
      } else if (type == 2) {
        if (totalCol != 32) {
          await this.app.alert(
            "Atenção!",
            "Quantidade de colunas do seu arquivo é diferente da esperada!",
            "error"
          );
          return;
        }
        const array2Sorted = this.colunasEsperadasInativos.slice().sort();
        const isEqual =
          cols.length === this.colunasEsperadasInativos.length &&
          cols
            .slice()
            .sort()
            .every(function (value, index) {
              return value === array2Sorted[index];
            });
        if (!isEqual) {
          await this.app.alert(
            "Atenção!",
            "O cabeçalho do seu arquivo é diferente do esperado!",
            "error"
          );
          return;
        }
      } else if (type == 3) {
        if (totalCol != 24) {
          await this.app.alert(
            "Atenção!",
            "Quantidade de colunas do seu arquivo é diferente da esperada!",
            "error"
          );
          return;
        }
        const array2Sorted = this.colunasEsperadasAposentados.slice().sort();
        const isEqual =
          cols.length === this.colunasEsperadasAposentados.length &&
          cols
            .slice()
            .sort()
            .every(function (value, index) {
              return value === array2Sorted[index];
            });
        if (!isEqual) {
          await this.app.alert(
            "Atenção!",
            "O cabeçalho do seu arquivo é diferente do esperado!",
            "error"
          );
          return;
        }
      }
      let colunasDeData = cols.filter((x) => x.toString().startsWith("DT_"));
      const invalidDates = lista.filter(
        (l) =>
          colunasDeData.filter(
            (c) => l[c] && !moment(l[c], "DD/MM/YYYY").isValid()
          ).length > 0
      );
      if (invalidDates.length > 0) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém datas inválidas!",
          "error"
        );
        return;
      }
      let ibgeFound = [];
      for (let l of lista) {
        if (l.CO_IBGE && !ibgeFound.find((i) => i == l.CO_IBGE)) {
          ibgeFound.push(l.CO_IBGE);
        }
      }
      if (ibgeFound.length !== 1) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém mais de um código IBGE!",
          "error"
        );
        return;
      }
      if (self.cidade && self.cidade.ibge && ibgeFound[0] != self.cidade.ibge) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém código IBGE inválido! o código esperado para esta cidade é " +
            self.cidade.ibge,
          "error"
        );
        return;
      }
      if (!uploadFiles || !uploadFiles.result) {
        return;
      }
      var options = {
        headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
      };

      const resultado = await self.http
        .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
        .toPromise();
      let urlArquivo = uploadFiles.result.split("?")[0];

      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      await this.apiCall
        .post(
          `servidor/arquivo/planilha?tipo=${type}&cidade=${self.idCidade}&url=${urlArquivo}&total_rows=${totalDeLinhas}`
        )
        .pipe(take(1))
        .toPromise();
      await self.app.alert(
        "Tudo certo!",
        "Planilha cadastrada com sucesso!",
        "success"
      );

      await self.spinner.hide();
      await self.ngOnInit();
    };
    reader.readAsText(file as Blob, "ISO-8859-1");
  }
  async uploadSimplificado() {
    const self = this;
    const { value: file } = await Swal.fire({
      title: "Upload Simplificado Servidoresss",
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
    });

    if (!file) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar uma planilha!",
        "error"
      );
      return;
    }

    /*if (!file.name.endsWith("csv")) {
      await this.app.alert(
        "Atenção!",
        "Você deve informar planilhas no formato .csv!",
        "error"
      );
      return;
    }*/
    const reader = new FileReader();
    reader.onload = async () => {
      let text = reader.result;
      let csvJson = self.csvJSON(text);
      console.log(text)
      let lista = JSON.parse(csvJson);
      let newLista = [];
      for (let l of lista) {
        // @ts-ignore
        const trimmed = Object.entries(l).reduce((acc, curr) => {
          let [key, value] = curr;
          // Checking if the key is a string
          acc[typeof key === "string" ? key.trim() : key] = value;
          return acc;
        }, {});
        newLista.push(trimmed);
      }
      lista = [...newLista];
      let cols = lista ? Object.keys(lista[0]) : [];
      let totalCol = lista ? Object.keys(lista[0]).length : 0;
      let totalDeLinhas = lista.length;
      let tipo = file.type;
      let extension = file.name.split(".").pop().toLowerCase();
      const uploadFiles = await this.apiCall
        .post("servidor/arquivo/exact/url", {
          extension: file.name,
          type: tipo,
        })
        .pipe(take(1))
        .toPromise();

      if (totalCol != 5) {
        await this.app.alert(
          "Atenção!",
          "Quantidade de colunas do seu arquivo é diferente da esperada!",
          "error"
        );
        return;
      }
      const array2Sorted = this.colunasEsperadasSimplificado.slice().sort();
      const isEqual =
        cols.length === this.colunasEsperadasSimplificado.length &&
        cols
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          });
      if (!isEqual) {
        await this.app.alert(
          "Atenção!",
          "O cabeçalho do seu arquivo é diferente do esperado!",
          "error"
        );
        return;
      }

      let ibgeFound = [];
      for (let l of lista) {
        if (l.CO_IBGE && !ibgeFound.find((i) => i == l.CO_IBGE)) {
          ibgeFound.push(l.CO_IBGE);
        }
      }
      if (ibgeFound.length !== 1) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém mais de um código IBGE!",
          "error"
        );
        return;
      }
      if (self.cidade && self.cidade.ibge && ibgeFound[0] != self.cidade.ibge) {
        await this.app.alert(
          "Atenção!",
          "O seu arquivo contém código IBGE inválido! o código esperado para esta cidade é " +
            self.cidade.ibge,
          "error"
        );
        return;
      }
      if (!uploadFiles || !uploadFiles.result) {
        return;
      }
      var options = {
        headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
      };

      const resultado = await self.http
        .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
        .toPromise();
      let urlArquivo = uploadFiles.result.split("?")[0];

      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      await this.apiCall
        .post(
          `servidor/arquivo/planilha?tipo=${9}&cidade=${
            self.idCidade
          }&url=${urlArquivo}&total_rows=${totalDeLinhas}&simplificada=true`
        )
        .pipe(take(1))
        .toPromise();
      await self.app.alert(
        "Tudo certo!",
        "Planilha cadastrada com sucesso!",
        "success"
      );
      await self.spinner.hide();
      await self.ngOnInit();
    };
    reader.readAsText(file as Blob, "ISO-8859-1");
  }
  //var csv is the CSV file with headers
  csvJSON(csv) {
    var lines = csv.split("\r\n");
    var result = [];

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split(";");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(";");
      if (currentline.length > 1) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  async getRelatorioTempoServicoPassado() {
    const self = this;

    let tempos = [];

    if (!self.idCidade) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await self.apiCall
      .get("servidor/relatorio/tempo?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();
    for (let i in servidores) {
      const servidor = servidores[i];
      let data = {
        NOME_SERVIDOR: servidor.nome,
        CPF_CNPJ_EMPREGADOR: servidor.cnpj_cpf_empregador,
        DATA_INICIO: servidor.data_entrada,
        DATA_FIM: servidor.data_fim,
        TEMPO_BRUTO: servidor.tempo_bruto,
        NUMERO_CERTIDAO: servidor.numero_certidao,
        DATA_CERTIDAO: servidor.data_certidao,
      };
      tempos.push(data);
    }
    await self.spinner.hide();
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(tempos)),
      "servidores_tempo_passado"
    );
  }
  async getRelatorioAtendimento() {
    const self = this;

    if (!self.idCidade) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await self.apiCall
      .get("servidor/atendimento/relatorio?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();

    await self.spinner.hide();
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(servidores ? servidores : [])),
      "servidores_atendimento"
    );
  }

  async getRelatoriosCidade() {
    const self = this;

    let servidoresAtivos = [];
    let servidoresPensionistas = [];
    let servidoresAposentados = [];
    if (!self.idCidade) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await self.apiCall
      .get("servidor/relatorio/cidade?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();
    for (let i in servidores) {
      const servidor = servidores[i];
      //if ( servidor.status == '1' || (!servidor.status && servidor.statusServidor == "1")) {
      if (servidor.status == "1") {
        let data = {
          NU_ANO: servidor.ano,
          CO_IBGE: servidor.ibge,
          NO_ENTE: servidor.cidade,
          SG_UF: servidor.estado,
          CO_COMP_MASSA: "1",
          CO_TIPO_FUNDO: "1",
          NU_CNPJ_ORGAO: servidor.cnpj,
          NO_ORGAO: servidor.orgao,
          CO_PODER: servidor.poder,
          CO_TIPO_PODER: "",
          CO_TIPO_POPULACAO: "1",
          CO_TIPO_CARGO: "",
          CO_CRITERIO_ELEGIBILIDADE: "",
          ID_SERVIDOR_MATRICULA: servidor.matricula,
          ID_SERVIDOR_CPF: servidor.cpf,
          ID_SERVIDOR_PIS_PASEP: servidor.pis,
          CO_SEXO_SERVIDOR: servidor.sexo,
          CO_EST_CIVIL_SERVIDOR: servidor.estadoCivil,
          DT_NASC_SERVIDOR: servidor.dataNascimento,
          CO_SITUACAO_FUNCIONAL: "1",
          CO_TIPO_VINCULO: "1",
          DT_ING_SERV_PUB: servidor.dataIngressoServicoPublico,
          DT_ING_ENTE: servidor.dataIngressoEnte,
          DT_ING_CARREIRA: servidor.dataIngressoCarreiraAtual,
          NO_CARREIRA: servidor.carreiraAtual,
          DT_ING_CARGO: servidor.dataInicioCargoAtual,
          NO_CARGO: servidor.cargoAtual,
          VL_BASE_CALCULO: servidor.salarioBaseMensal,
          VL_REMUNERACAO: servidor.salarioTotal,
          NU_TEMPO_RGPS: servidor.tempoServicoAnteriorTotalRgps,
          NU_TEMPO_RPPS: servidor.tempoServicoAnteriorTotalRpps,
          NU_DEPENDENTES: servidor.totalDependentes,
          DT_NASC_CONJUGE: servidor.dataNascimentoConjuge,
          CO_COND_CONJUGE: "1",
          DT_NASC_DEPENDENTE_1: servidor.dataNascimentoDependente2,
          CO_COND_DEPENDENTE_1: "1",
          DT_NASC_DEPENDENTE_2: servidor.dataNascimentoDependente3,
          CO_COND_DEPENDENTE_2: "1",
          "IN_ABONO_PERMANENCIA ": "",
          DT_INICIO_ABONO: "",
          DT_PROV_APOSENT: "",
        };
        servidoresAtivos.push(data);
        // } else if (servidor.status == '3' || (!servidor.status && servidor.statusServidor == "4")) {
      } else if (servidor.status == "3") {
        let data = {
          NU_ANO: servidor.ano,
          CO_IBGE: servidor.ibge,
          NO_ENTE: servidor.cidade,
          SG_UF: servidor.estado,
          CO_COMP_MASSA: "1",
          CO_TIPO_FUNDO: "1",
          CNPJ_ORGAO: servidor.cnpj,
          NO_ORGAO: servidor.orgao,
          CO_PODER: servidor.poder,
          CO_TIPO_PODER: "",
          CO_TIPO_INSTITUIDOR: "",
          ID_INSTITUIDOR_MATRICULA: "",
          ID_INSTITUIDOR_CPF: "",
          ID_INSTITUIDOR_PIS_PASEP: "",
          ID_PENSIONISTA_CPF: servidor.cpf,
          CO_SEXO_PENSIONISTA: servidor.sexo,
          DT_NASC_PENSIONISTA: servidor.dataNascimento,
          DT_INICIO_PENSAO: servidor.dataIngressoCarreiraAtual,
          VL_BENEF_PENSAO: servidor.salarioBaseMensal,
          VL_CONTRIBUICAO: "",
          VL_COMPENS_PREVID: "",
          IN_PARID_SERV: "",
          CO_CONDICAO: "",
          CO_DURACAO: "",
        };
        servidoresPensionistas.push(data);
        // } else if (servidor.status == '2' || (!servidor.status && servidor.statusServidor == "3")) {
      } else if (servidor.status == "2") {
        let data = {
          NU_ANO: servidor.ano,
          CO_IBGE: servidor.ibge,
          NO_ENTE: servidor.cidade,
          SG_UF: servidor.estado,
          CO_COMP_MASSA: "1",
          CO_TIPO_FUNDO: "1",
          CNPJ_ORGAO: servidor.cnpj,
          NO_ORGAO: servidor.orgao,
          CO_PODER: servidor.poder,
          CO_TIPO_PODER: "",
          CO_TIPO_POPULACAO: "4",
          CO_TIPO_CARGO: "",
          CO_TIPO_APOSENTADORIA: "",
          ID_APOSENTADO_MATRICULA: servidor.matricula,
          ID_APOSENTADO_CPF: servidor.cpf,
          ID_APOSENT_PIS_PASEP: servidor.pis,
          CO_SEXO_APOSENTADO: servidor.sexo,
          CO_EST_CIVIL_APOSENTADO: servidor.estadoCivil,
          DT_NASC_APOSENTADO: servidor.dataNascimento,
          DT_INICIO_APOSENTADORIA: servidor.dataIngressoCarreiraAtual,
          VL_APOSENTADORIA: servidor.salarioBaseMensal,
          VL_CONTRIBUICAO: "",
          IN_PARID_SERV: "",
          CO_CONDICAO_APOSENTADO: "",
          VL_COMPENS_PREVID: "",
          NU_DEPENDENTES: servidor.totalDependentes,
          DT_NASC_CONJUGE: servidor.dataNascimentoConjuge,
          CO_CONDICAO_CONJUGE: "1",
          DT_NASC_DEPENDENTE_1: servidor.dataNascimentoDependente2,
          CO_CONDICAO_DEPENDENTE_1: "1",
          DT_NASC_DEPENDENTE_2: servidor.dataNascimentoDependente3,
          CO_CONDICAO_DEPENDENTE_2: "1",
        };
        servidoresAposentados.push(data);
      }
    }
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(servidoresAtivos)),
      "servidores"
    );
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(servidoresAposentados)),
      "aposentados"
    );
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(servidoresPensionistas)),
      "pensionistas"
    );
    self.spinner.hide();
  }

  async getRelatorioPendencias() {
    const self = this;

    let lista = [];
    if (!self.idCidade) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await self.apiCall
      .get("servidor/relatorio/cidade/pendencias?id_cidade=" + self.idCidade)
      .pipe(take(1))
      .toPromise();
    for (let i in servidores) {
      const servidor = servidores[i];
      if (servidor.pendencias) {
        let data = {
          NOME: servidor.nome,
          STATUS:
            servidor.statusServidor == "1" || servidor.status == "1"
              ? "Ativo"
              : servidor.statusServidor == "4" || servidor.status == "3"
              ? "Pensionista"
              : servidor.statusServidor == "3" || servidor.status == "2"
              ? "Aposentado"
              : servidor.status == "4"
              ? "Exonerado"
              : "",
          PENDÊNCIA: servidor.pendencias,
          OBSERVAÇÃO: servidor.observacoes,
        };
        lista.push(data);
      }
    }
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(lista)),
      "servidores"
    );
    self.spinner.hide();
  }
  async exportarBancoDeDadosFormatoSPREV() {
    const self = this;
    if (!self.idCidade) {
      return;
    }
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const servidores = await this.apiCall
      .get(`servidor/relatorio/brprev?id_cidade=${self.idCidade}`)
      .pipe(take(1))
      .toPromise();
    if (!servidores) {
      this.spinner.hide();
      return;
    }
    self.servidoresAtivos = [];
    self.servidoresPensionistas = [];
    self.servidoresInativos = [];
    let servidoresPorAno =  servidores.filter((s) => s.status != 5);
    for (let servidor of servidoresPorAno) {
      if (servidor.status !== null && servidor.status == 1) {
        let data = {
          ANO: servidor.ano,
          MÊS: servidor.mes,
          "CO_IBGE": servidor.ibge,
          "NOME_DO_ENTE": servidor.nome_cidade,
          "SIGLA_ESTADO": servidor.estado_naturalidade,
          "COMPOSIÇÃO DA MASSA (A)": "1",
          "TIPO DO FUNDO (B)": servidor.fundo,
          CNPJ: servidor.cnpj,
          "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
          PODER: servidor.poder == null || servidor.poder == undefined ? "" : servidor.poder,
          TIPO: servidor.administracao == null || servidor.administracao == undefined ? "" : servidor.administracao,
          "A - POPULAÇÃO COBERTA": servidor.carreira == '21' ? "Militares em atividade" : "Ativos",
          "B- POPULAÇÃO COBERTA": servidor.carreira,
          "C - POPULAÇÃO COBERTA": servidor.populacao_coberta_c,
          Matrícula: servidor.matricula,
          CPF: servidor.cpf,
          "PIS-PASEP": servidor.pis,
          SEXO: servidor.sexo,
          "ESTADO CIVIL": servidor.estadoCivil,
          "DATA DE NASCIMENTO": servidor.dataNascimento,
          "COD SITUAÇÃO FUNCIONAL": "",
          "TIPO DE VÍNCULO": "",
          "DATA DE INGRESSO NO SERV_PUBLICO": servidor.data_ingresso_serv,
          "DATA DE INGRESSO NO ENTE": servidor.dataIngressoEnte,
          "DATA DE INGRESSO NA CARREIRA ATUAL":
          servidor.dataIngressoCarreiraAtual,
          "IDENTIFICAÇÃO DA CARREIRA ATUAL": servidor.carreiraAtual,
          "DATA DE INÍCIO DE EXERCÍCIO NO CARGO ATUAL":
            servidor.dataInicioCargoAtual,
          "IDENTIFICAÇÃO DO CARGO ATUAL": servidor.cargoAtual,
          "BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO":
            servidor.salarioBaseMensal,
          "REMUNERAÇÃO MENSAL TOTAL DO SERVIDOR ATIVO": servidor.salarioTotal,
          "CONTRIBUIÇÃO MENSAL": "",
          "PRIMEIRA BASE DE CÁLCULO MENSAL DO SERVIDOR ATIVO": "",
          "TEMPO DE CONTRIBUIÇÃO DO SERVIDOR ATIVO ANTERIOR À ADMISSÃO NO ENTE PARA O RGPS":
            servidor.tempoServicoAnteriorTotal,
          // "TEMPO DE CONTRIBUIÇÃO DO SERVIDOR ATIVO ANTERIOR À ADMISSÃO NO ENTE PARA OUTROS RPPS":
          //     servidor.tempoServicoAnteriorTotal,
          "NÚMERO DE DEPENDENTES DO SERVIDOR ATIVO": servidor.totalDependentes,
          "DATA DE NASCIMENTO DO CÔNJUGE DO SERVIDOR ATIVO":
            servidor.dataNascimentoConjuge,
          "CONDIÇÃO DO CÔNJUGE": "1",
          "DATA DE NASCIMENTO DO DEPENDENTE 2":
            servidor.dataNascimentoDependente2,
          "CONDIÇÃO DO DEPENDENTE 2": "1",
          "DATA DE NASCIMENTO DO DEPENDENTE 3":
            servidor.dataNascimentoDependente3,
          "CONDIÇÃO DO DEPENDENTE 3": "1",
          "SEGURADO EM ABONO DE PERMANÊNCIA ": servidor.abono_permanencia,
          "DATA DE INÍCIO DE ABONO DE PERMANÊNCIA DO SERVIDOR ATIVO": servidor.data_abono,
          "PREVIDÊNCIA COMPLEMENTAR": "",
          "TETO REMUNERATÓRIO ESPECÍFICO": "",
        };
        self.servidoresAtivos.push(data);
      } else if (servidor.status !== null && servidor.status == 3) {
        let data = {
          ANO: servidor.ano,
          MÊS: servidor.mes,
          "CO_IBGE": servidor.ibge,
          "NOME_DO_ENTE": servidor.nome_cidade,
          "SG_UF": servidor.estado_naturalidade,
          "CO_COMP_MASSA": "1",
          "CO_TIPO_FUNDO": servidor.fundo,
          CNPJ: servidor.cnpj,
          "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
          "CO_PODER (CÓDIGO DO PODER)": servidor.poder,
          "CO_TIPO_PODER (CÓDIGO DO TIPO DE PODER)": servidor.administracao,
          "CO_TIPO_INSTITUIDOR (CÓDIGO DO TIPO DE INSTITUIDOR)": servidor.administracao,
          "ID MATRICULA INSTITUIDOR": servidor.matricula_pensao,
          "INSTITUIDOR CPF": servidor.cpf_pensao,
          "INSTITUIDOR PIS PASEP": servidor.pis_pensao,
          "SEXO INSTITUIDOR": servidor.sexo,
          "DT_NASC_INSTITUIDOR":  servidor.data_nascimento_pensao,
          "DT_OBITO_INSTITUIDOR": servidor.data_falecimento_pensao,
          "MATRICULA_PENSIONISTA": servidor.matricula,
          "CPF_PENSIONISTA": servidor.cpf,
          "SEXO": servidor.sexo,
          "DT_NASC_PENSIONISTA": servidor.dataNascimento,
          "RELAÇÃO DO PENSIONISTA COM O INSTITUIDOR": servidor.relacao_titular_pensao,
          "DATA INICIO PENSAO":  servidor.concessao_aposentadoria,
          "VALOR BENEF PENSAO": servidor.salarioBaseMensal,
          "VALOR TOTAL DA PENSÃO": servidor.salarioBaseMensal,
          "VL_PCT_QUOTA": "",
          "VALOR CONTRIBUICAO": "",
          "VALOR MENSAL DA COMPENSAÇÃO PREVIDENCIÁRIA": "",
          "PARIDADE": servidor.paridade_aposentadoria,
          "CONDIÇÃO DO PENSIONISTA": servidor.deficiente,
          "DURAÇÃO DO BENEFÍCIO": servidor.tipo_pensao,
          "TEMPO DURAÇÃO DO BENEFÍCIO": "",
          "PREVIDÊNCIA COMPLEMENTAR": "",
          "TETO REMUNERATÓRIO ESPECÍFICO": "",
        };
        self.servidoresPensionistas.push(data);
      } else if (servidor.status != 4) {
        let data = {
          ANO: servidor.ano,
          MÊS: servidor.mes,
          "CO_IBGE": servidor.ibge,
          "NOME_DO_ENT": servidor.nome_cidade,
          "SIGLA_ESTADO": servidor.estado_naturalidade,
          "COMPOSIÇÃO DA MASSA (A)":  "1",
          "TIPO DE FUNDO (B)": servidor.fundo,
          CNPJ: servidor.cnpj,
          "NOME DO ÓRGÃO/ENTIDADE": servidor.orgao,
          PODER: servidor.poder == null || servidor.poder == undefined ? "" : servidor.poder ,
          "TIPO_AUTARQUIA": servidor.administracao,
          "CO_TIPO_POPULACAO": servidor.co_tipo_populacao,
          "TIPO_CARGO": servidor.tipo_cargo,
          "CO_TIPO_APOSENTADORIA": servidor.tipo_aposentadoria == null || servidor.tipo_aposentadoria == undefined ? "" : servidor.tipo_aposentadoria,
          "MATRÍCULA": servidor.matricula,
          "CPF": servidor.cpf,
          "PIS-PASEP": servidor.pis,
          "SEXO DO APOSENTADO": servidor.sexo,
          "ESTADO CIVIL": servidor.estadoCivil,
          "DATA DE NASCIMENTO": servidor.dataNascimento,
          "DT_ING_SERV_PUB": servidor.data_ingresso_serv,
          "DATA DE INGRESSO NO ENTE": servidor.dataIngressoEnte,
          "DT_INICIO_APOSENTADORIA": servidor.concessao_aposentadoria,
          "VL_APOSENTADORIA":
            servidor.salarioBaseMensal,
          "VL_CONTRIBUICAO": "",
          "VL_COMPENS_PREVID": "",
          "IN_PARID_SERV": servidor.paridade_aposentadoria,
          "CO_CONDICAO_APOSENTADO": servidor.deficiente,
          "NU_TEMPO_RGPS_MUN": "",
          "NU_TEMPO_RPPS": "",
          "NU_DEPENDENTES": servidor.totalDependentes,
          "DATA DE NASCIMENTO DO CÔNJUGE":
            servidor.dataNascimentoConjuge,
          "CONDIÇÃO DO CÔNJUGE": "1",
          "DATA DE NASCIMENTO DO DEPENDENTE 2":
            servidor.dataNascimentoDependente2,
          "CONDIÇÃO DO DEPENDENTE 2": "1",
          "DATA DE NASCIMENTO DO DEPENDENTE 3":
            servidor.dataNascimentoDependente3,
          "CONDIÇÃO DO DEPENDENTE 3": "1",
          "PREVIDÊNCIA COMPLEMENTAR": "",
          "TETO REMUNERATÓRIO ESPECÍFICO": "",
        };
        self.servidoresInativos.push(data);
      }
    }
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(self.servidoresAtivos)),
      "servidoresativos"
    );
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(self.servidoresInativos)),
      "servidoresinativos"
    );
    self.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(self.servidoresPensionistas)),
      "pensionistas"
    );
    this.spinner.hide();
  }
}
