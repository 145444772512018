<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Documentos</h3>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <form class="card-body">
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cpf">CPF <b *ngIf="servidor && servidor.status && (servidor.status==1)" style="color: red;">*</b></label>
                    <input type="text" id="cpf" class="form-control" [(ngModel)]="documentos.cpf" mask="000.000.000-00" name="cpf" placeholder="CPF"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="rg">RG <b *ngIf="servidor && servidor.status && (servidor.status==1)" style="color: red;">*</b></label>
                    <input type="text" id="rg" class="form-control" [(ngModel)]="documentos.rg" name="rg" placeholder="RG"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_emissao_rg">Emissão RG</label>
                    <input type="text" id="data_emissao_rg" class="form-control" mask="00/00/0000" [(ngModel)]="documentos.data_emissao_rg" name="data_emissao_rg" placeholder="Data Emissão RG"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="orgaosExpedidores">Orgão Expedidor RG <b *ngIf="servidor && servidor.status && (servidor.status==1)" style="color: red;">*</b></label>
                    <ng-select [items]="orgaosExpedidores"   name="orgaosExpedidores" bindLabel="descricao" bindValue="id"  placeholder="Orgão Expedidor RG" id="orgaosExpedidores"  [(ngModel)]="documentos.orgao_expedidor_rg"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="uf_rg">UF RG <b *ngIf="servidor && servidor.status && (servidor.status==1)" style="color: red;">*</b></label>
                    <ng-select [items]="estados"  name="uf_rg" bindLabel="estado" bindValue="estado"  placeholder="UF RG" id="uf_rg" [(ngModel)]="documentos.uf_rg"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nit">NIT <b *ngIf="servidor && servidor.status && (servidor.status==1)" style="color: red;">*</b></label>
                    <input type="text" id="nit" class="form-control" [(ngModel)]="documentos.nit" mask="000.00000.00-0"  name="nit" placeholder="NIT"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cpts">CPTS</label>
                    <input type="text" id="cpts" class="form-control" [(ngModel)]="documentos.cpts" maxlength="15" name="cpts" placeholder="CPTS"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="serie_cpts">Série CPTS</label>
                    <input type="text" id="serie_cpts" class="form-control" [(ngModel)]="documentos.serie_cpts" name="serie_cpts" mask="0000" placeholder="Série CPTS"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_emissao_cpts">Data Emissão CPTS</label>
                    <input type="text" id="data_emissao_cpts" class="form-control" mask="00/00/0000" [(ngModel)]="documentos.data_emissao_cpts" name="data_emissao_cpts" placeholder="Data Emissão CPTS"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="titulo_eleitor">Título de Eleitor</label>
                    <input type="text" id="titulo_eleitor" class="form-control" [(ngModel)]="documentos.titulo_eleitor" mask="99999999999999"  name="titulo_eleitor" placeholder="Título de Eleitor"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="zona_titulo_eleitor">Zona Título de Eleitor</label>
                    <input type="text" id="zona_titulo_eleitor" class="form-control" [(ngModel)]="documentos.zona_titulo_eleitor" mask="000" name="zona_titulo_eleitor" placeholder="Zona Título de Eleitor"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="secao_titulo_eleitor">Seção Título de Eleitor</label>
                    <input type="text" id="secao_titulo_eleitor" class="form-control" [(ngModel)]="documentos.secao_titulo_eleitor" name="secao_titulo_eleitor" mask="0000" placeholder="Seção Título de Eleitor"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="uf_rg">UF Título de Eleitor</label>
                    <ng-select [items]="estados"  name="uf_titulo_eleitor" bindLabel="estado" bindValue="estado"  placeholder="UF Título de Eleitor" id="uf_titulo_eleitor" [(ngModel)]="documentos.uf_titulo_eleitor"></ng-select>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="form-row">
              <div class="col" >
                <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidor" [queryParams]="{id: idServidor}" >Voltar</button>
              </div>
              <div class="col" >
                <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right" (click)="atualizarOuCadastrar()" [disabled]="disableDocumentos()" >{{documentos.id ? 'Atualizar' : 'Cadastrar'}}</button>
              </div>
            </div>
          </form>
        </form>
      </div>
      </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>
