<div class="row text-left mt-3">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300">Dependentes</h1>
      <button  type="button" class="btn btn-outline-primary" (click)="openModalXl(cadastroDependente, null)">Novo Dependente</button>
    </div>
  </div>
</div>
<div class="row mb-2" *ngIf="dependentesToShow.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">

        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Nome</th>
            <th>Cpf</th>
            <th>Tipo</th>
            <th>Idade</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let dependente of dependentesToShow | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <td>{{dependente.nome}}</td>
            <td>{{dependente.cpf}}</td>
            <td>{{dependente.tipo_dependencia_label}}</td>
            <td [ngStyle]="{ 'color':  verificaFilhos(dependente) ? 'red' : 'black', 'font-weight': verificaFilhos(dependente) ? 'bold' : 'normal' }">{{dependente.idade + ' anos'}}</td>
            <td>
              <button *ngIf="dependente.matricula"  type="button" class="btn btn-outline-primary" (click)="baixarMatricula(dependente.matricula)">Download Matrícula</button>
            </td>
           
            <td>
              <input #url type="file" style="display: none" (change)="anexarComprovanteMatricula(dependente, $event)">
              
              <!-- Botão de "Comprovante Matrícula" quando dependente.matricula for falso -->
              <button *ngIf="!dependente.matricula" type="button" class="btn btn-outline-primary" (click)="url.click()">Comprovante Matrícula</button>
              
              <!-- Botão de "Deletar Matrícula" quando dependente.matricula for verdadeiro -->
              <button *ngIf="dependente.matricula" type="button" class="btn btn-outline-danger" (click)="deletarArquivo(dependente)">Deletar Matrícula</button>
            </td>


            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="openModalXl(cadastroDependente, dependente)">Visualizar</button>
            </td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="deletarDependente(dependente.id)">Deletar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px" *ngIf="dependentesToShow.length>0"
                          [(page)]="page"
                          [pageSize]="pageSize"  [maxSize]="12"
                          [collectionSize]="dependentesToShow.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="dependentesToShow.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pl-0 pr-0">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum dependente cadastrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="form-row">
  <div class="col" >
    <button  type="button" class="btn btn-outline-primary" routerLink="/page/servidor" [queryParams]="{id: idServidor}" >Voltar</button>
  </div>
</div>


<ng-template #cadastroDependente let-modal>
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Novo Dependente</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form #servForm="ngForm" novalidate>
              <h4 class="text-center">Dados do Dependente</h4>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Nome <b style="color: red;">*</b></label>
                      <input type="text" id="nome" class="form-control" [(ngModel)]="dependente.nome" name="nome" placeholder="Nome Completo"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_nascimento">Nascimento <b style="color: red;">*</b></label>
                      <input type="text" id="data_nascimento" class="form-control" mask="00/00/0000" [(ngModel)]="dependente.data_nascimento" name="data_nascimento" placeholder="Data Nascimento"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="estado_civil">Estado Civil <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="estadosCivis"   name="estado_civil" bindLabel="descricao" bindValue="id"  placeholder="Estado Civil" id="estado_civil" [(ngModel)]="dependente.estado_civil"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="sexo">Sexo <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="sexos"   name="sexo" bindLabel="name"  placeholder="Sexo" bindValue="id" id="sexo" [(ngModel)]="dependente.sexo"></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="naturalizado">Deficiente <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="naturalizados"   name="deficiente" bindLabel="name"  placeholder="Deficiente" bindValue="id" id="deficiente" [(ngModel)]="dependente.deficiente"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Estado Naturalidade <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="estados"  name="estado" (change)="toggleEstado(true)"  bindLabel="estado" bindValue="estado"  placeholder="Estado" id="estado" [(ngModel)]="dependente.estado_naturalidade"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nome">Cidade Naturalidade <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="cidadesNaturalidade" [disabled]="!cidadesNaturalidade || cidadesNaturalidade.length==0"  name="cidade"  bindLabel="nome" bindValue="id"  placeholder="Cidade" id="cidade" [(ngModel)]="dependente.cidade_naturalidade"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="nacionalidade">Nacionalidade <b style="color: red;">*</b></label>
                      <input type="text" id="nacionalidade" class="form-control" [(ngModel)]="dependente.nacionalidade" name="nacionalidade" placeholder="Nacionalidade"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="naturalizado">Naturalizado <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="naturalizados"   name="naturalizado" bindLabel="name"  placeholder="Naturalizado" bindValue="id" id="naturalizado" [(ngModel)]="dependente.naturalizado"></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="escolaridade">Escolaridade <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="escolaridades"   name="escolaridade" bindLabel="descricao" bindValue="id"  placeholder="Escolaridade" id="escolaridade" [(ngModel)]="dependente.escolaridade"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="mae">Nome Mãe <b style="color: red;">*</b></label>
                      <input type="text" id="mae" class="form-control" [(ngModel)]="dependente.nome_mae" name="mae" placeholder="Nome Mãe"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="pai">Nome Pai</label>
                      <input type="text" id="pai" class="form-control" [(ngModel)]="dependente.nome_pai" name="pai" placeholder="Nome Pai"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
              <h4 class="text-center">Documentos do Dependente</h4>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cpf">CPF <b style="color: red;">*</b></label>
                      <input type="text" id="cpf" class="form-control" [(ngModel)]="dependente.cpf" mask="000.000.000-00" name="cpf" placeholder="CPF"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              <!--   <div class="col" *ngIf="servidor && servidor.status && servidor.status!=1" >
                  <div class="form-group">
                    <div class="controls">
                      <label for="cpf">PIS/PASEP</label>
                      <input type="text" id="pis_pasep" class="form-control" [(ngModel)]="dependente.pis_pasep" mask="0*" name="pis_pasep" placeholder="PIS/PASEP"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col" *ngIf="servidor && servidor.status && servidor.status!=1" >
                  <div class="form-group">
                    <div class="controls">
                      <label for="rg">RG</label>
                      <input type="text" id="rg" class="form-control" [(ngModel)]="dependente.rg" name="rg" placeholder="RG"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>  -->

              </div>
              <!-- <div class="form-row" *ngIf="servidor && servidor.status && servidor.status!=1" >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="data_emissao_rg">Emissão RG</label>
                      <input type="text" id="data_emissao_rg" class="form-control" mask="00/00/0000" [(ngModel)]="dependente.data_emissao_rg" name="data_emissao_rg" placeholder="Data Emissão RG"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="orgaosExpedidores">Orgão Expedidor RG</label>
                     <ng-select [appendTo]="'body'" [items]="orgaosExpedidores"   name="orgaosExpedidores" bindLabel="descricao" bindValue="id"  placeholder="Orgão Expedidor RG" id="orgaosExpedidores"  [(ngModel)]="dependente.orgao_expedidor_rg"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="uf_rg">UF RG</label>
                     <ng-select [appendTo]="'body'" [items]="estados"  name="uf_rg" bindLabel="estado" bindValue="estado"  placeholder="UF RG" id="uf_rg" [(ngModel)]="dependente.uf_rg"></ng-select>
                    </div>
                  </div>
                </div>
              </div> -->
              <h4 class="text-center">Endereço e Contatos do Dependente</h4>
              <div class="form-row">
                <div class="col">
                  <div class="form-check">
                    <input (change)="copiaEndereco($event)" [checked]="checkEndereco" type="checkbox" class="form-check-input" id="copia_end_serv">
                    <label class="form-check-label" for="copia_end_serv"><b>Copiar Endereço Servidor</b></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <input (change)="copiaContatos($event)" [checked]="checkContatos" type="checkbox" class="form-check-input" id="copia_cont_serv">
                    <label class="form-check-label" for="copia_cont_serv"><b>Copiar Contatos Servidor</b></label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cep">CEP <b style="color: red;">*</b></label>
                      <input type="text" id="cep" class="form-control" [(ngModel)]="dependente.cep" mask="00.000-000" name="cep" placeholder="CEP" (change)="consultaCep()"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tiposlogradouro">Tipo Logradouro <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="tiposlogradouro"   name="tiposlogradouro" bindLabel="descricao" bindValue="id"  placeholder="Tipo Logradouro" id="tiposlogradouro"  [(ngModel)]="dependente.tipo_logradouro"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="logradouro">Logradouro <b style="color: red;">*</b></label>
                      <input type="text" id="logradouro" class="form-control" [(ngModel)]="dependente.logradouro" name="logradouro" placeholder="Logradouro"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="numero">Número <b style="color: red;">*</b></label>
                      <input type="text" id="numero" class="form-control" [(ngModel)]="dependente.numero" name="numero" placeholder="Número"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="bairro">Bairro <b style="color: red;">*</b></label>
                      <input type="text" id="bairro" class="form-control" [(ngModel)]="dependente.bairro"   name="bairro" placeholder="Bairro"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="complemento">Complemento <b style="color: red;">*</b></label>
                      <input type="text" id="complemento" class="form-control" [(ngModel)]="dependente.complemento" name="complemento" placeholder="Complemento"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="estado1">Estado <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="estados"  name="estado1" (change)="toggleEstado(false)"  bindLabel="estado" bindValue="estado"  placeholder="Estado" id="estado1" [(ngModel)]="dependente.estado"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="cidade1">Cidade <b style="color: red;">*</b></label>
                     <ng-select [appendTo]="'body'" [items]="cidades" [disabled]="!cidades || cidades.length==0"  name="cidade1"  bindLabel="nome" bindValue="id"  placeholder="Cidade" id="cidade1" [(ngModel)]="dependente.cidade"></ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="telefone">Telefone</label>
                      <input type="text" id="telefone" class="form-control" [(ngModel)]="dependente.telefone" mask="(00) 0000-0000" name="telefone" placeholder="Telefone"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="celular">Celular</label>
                      <input type="text" id="celular" class="form-control" [(ngModel)]="dependente.celular" mask="(00) 00000-0000" name="celular" placeholder="Celular"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="email">Email</label>
                      <input type="text" id="email" class="form-control"  [(ngModel)]="dependente.email" name="email" placeholder="Email"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div>
             <!--  <h4 *ngIf="servidor && servidor.status && servidor.status!=1"  class="text-center">Certidão de Nascimento do Dependente</h4>
              <div class="form-row" *ngIf="servidor && servidor.status && servidor.status!=1" >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="numero_certidao">Número Certidão</label>
                      <input type="text" id="numero_certidao" class="form-control" [(ngModel)]="dependente.numero_certidao" name="numero_certidao" placeholder="Número Certidão"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="livro">Livro</label>
                      <input type="text" id="livro" class="form-control" [(ngModel)]="dependente.livro" name="livro" placeholder="Livro"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="folha">Folha</label>
                      <input type="text" id="folha" class="form-control"  [(ngModel)]="dependente.folha" name="folha" placeholder="Folha"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
              </div> -->
              <h4 class="text-center">Dados da Dependência</h4>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="tiposDependencia">Tipo Dependência</label>
                     <ng-select [appendTo]="'body'" [items]="tiposDependencia"   name="tiposDependencia" bindLabel="descricao" bindValue="id"  placeholder="Tipo Dependência" id="tiposDependencia" [(ngModel)]="dependente.tipo_dependencia"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="motivosInicio">Motivo Início Dependência</label>
                     <ng-select [appendTo]="'body'" [items]="motivosInicio"   name="motivosInicio" bindLabel="descricao" bindValue="id"  placeholder="Motivo Início Dependência" id="motivosInicio" [(ngModel)]="dependente.motivo_inicio"></ng-select>
                    </div>
                  </div>
                </div>
                <!-- <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="motivosFim">Motivo Fim Dependência</label>
                     <ng-select [appendTo]="'body'" [items]="motivosFim"   name="motivosFim" bindLabel="descricao" bindValue="id"  placeholder="Motivo Fim Dependência" id="motivosFim" [(ngModel)]="dependente.motivo_fim"></ng-select>
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="inicio_dependencia">Início Dependência</label>
                      <input type="text" id="inicio_dependencia" class="form-control" mask="00/00/0000" [(ngModel)]="dependente.inicio_dependencia" name="inicio_dependencia" placeholder="Início Dependência"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="fim_dependencia">Fim Dependência</label>
                      <input type="text" id="fim_dependencia" class="form-control" mask="00/00/0000" [(ngModel)]="dependente.fim_dependencia" name="fim_dependencia" placeholder="Fim Dependência"
                             required aria-invalid="false">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="fins_previdenciarios">Fins Previdênciários</label>
                     <ng-select [appendTo]="'body'" [items]="naturalizados"   name="fins_previdenciarios" bindLabel="name" bindValue="id"  placeholder="Fins Previdênciários" id="fins_previdenciarios" [(ngModel)]="dependente.fins_previdenciarios"></ng-select>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="form-row">
                <div class="col" >
                  <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right"
                          (click)="atualizarOuCadastrar(modal)"
                          [disabled]="
                          !dependente.nome
                          || !dependente.data_nascimento
                          || !dependente.cpf
                          || !dependente.estado_civil
                          || !dependente.sexo
                          || !dependente.estado_naturalidade
                          || !dependente.cidade_naturalidade
                          || !dependente.nacionalidade
                          || !dependente.naturalizado
                          || !dependente.escolaridade
                          || !dependente.nome_mae
                          || !dependente.deficiente
                          || !dependente.tipo_logradouro
                          || !dependente.logradouro
                          || !dependente.numero
                          || !dependente.complemento
                          || !dependente.bairro
                          || !dependente.estado
                          || !dependente.cidade
                          || !dependente.cep"
                  >{{dependente.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>



<ngx-spinner></ngx-spinner>
