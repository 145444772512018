import { Component, OnInit } from "@angular/core";
import Me from "app/helpers/me";

@Component({
  selector: "app-servidor-home",
  templateUrl: "./servidor-home.component.html",
  styleUrls: ["./servidor-home.component.scss"],
})
export class ServidorHomeComponent implements OnInit {
  cidade: string;
  constructor(private me: Me) {}

  ngOnInit(): void {
    const self = this;
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    self.cidade = json.user.nomeCidade.toUpperCase();
  }
}
