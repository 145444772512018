<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Usuários do Município</h3>
      <button  type="button" class="btn btn-outline-primary" (click)="download('model.xlsx', 'model.xlsx')">Baixar Modelo</button>
    </div>
  </div>
</div>
<form  enctype="multipart/form-data" >
  <div class="row" style="margin-top: 20px">
    <div class="col-sm">
      <div class="form-group">
        <label for="arquivo">Upload Novos Usuários:</label>
        <input type="file" name="arquivo"  class="form-control" (change)="selecionaArquivo($event)" />
      </div>
    </div>
  </div>
</form>
<div class="row mb-2" *ngIf="usuarios.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">

        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Usuário</th>
            <th>Senha</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let usuario of usuarios| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <ng-template [ngIf]="usuario.servidor">
              <td  style="text-align: center">
                <a [routerLink]="['/servidor']"  [queryParams]="{id: usuario.servidor}">
                  {{usuario.user}}
                </a>
              </td>
            </ng-template>
            <ng-template [ngIf]="!usuario.servidor">
              <td  style="text-align: center">
                {{usuario.user}}
              </td>
            </ng-template>
            <td>{{usuario.password}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="openModal(atualizarSenha, usuario)">Atualizar Senha</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px" *ngIf="usuarios.length>0"
                          [(page)]="page"
                          [pageSize]="pageSize"  maxSize="12"
                          [collectionSize]="usuarios.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="usuarios.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum usuário encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #atualizarSenha let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Nova Senha</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="novaSenha">Nova Senha</label>
                <input type="text" id="novaSenha" class="form-control" name="novaSenha" [(ngModel)]="atualizacaoSenha.novaSenha" #inputSenha="ngModel" required>
              </div>
              <div class="form-group mb-2">
                <label for="confirmaNovaSenha">Confirme a Nova Senha</label>
                <input type="text" id="confirmaNovaSenha" class="form-control" name="confirmaNovaSenha" [(ngModel)]="atualizacaoSenha.confirmaNovaSenha" #inputConfir="ngModel" required>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block btn-secondary" (click)="modal.close('Close click')">Cancelar</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="updateSenha(modal)" [disabled]="!atualizacaoSenha || !atualizacaoSenha.novaSenha || !atualizacaoSenha.confirmaNovaSenha || (atualizacaoSenha.novaSenha!=atualizacaoSenha.confirmaNovaSenha)">Atualizar</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ngx-spinner></ngx-spinner>
