import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as cep from "cep-promise";
import * as moment from "moment";

@Component({
  selector: 'app-dadosbancarios',
  templateUrl: './dadosbancarios.component.html',
  styleUrls: ['./dadosbancarios.component.scss']
})
export class DadosbancariosComponent implements OnInit {

  tiposConta = [
    {id: '1', descricao: 'Conta Corrente'},
    {id: '2', descricao: 'Poupança'},
    {id: '3', descricao: 'Cartão Salário'},

  ];

  bancos = [
    {id: '1', descricao: '000 - Banco Bankpar S.A.'},
    {id: '2', descricao: '001 - Banco do Brasil S.A.'},
    {id: '3', descricao: '003 - Banco da Amazônia S.A.'},
    {id: '4', descricao: '004 - Banco do Nordeste do Brasil S.A.'},
    {id: '5', descricao: '012 - Banco Standard de Investimentos S.A.'},
    {id: '6', descricao: '014 - Natixis Brasil S.A. Banco Múltiplo'},
    {id: '7', descricao: '019 - Banco Azteca do Brasil S.A.'},
    {id: '8', descricao: '021 - BANESTES S.A. Banco do Estado do Espírito Santo'},
    {id: '9', descricao: '024 - Banco de Pernambuco S.A. - BANDEPE'},
    {id: '10', descricao: '025 - Banco Alfa S.A.'},
    {id: '11', descricao: '029 - Banco Banerj S.A.'},
    {id: '12', descricao: '031 - Banco Beg S.A.'},
    {id: '13', descricao: '033 - Banco Santander (Brasil) S.A.'},
    {id: '14', descricao: '036 - Banco Bradesco BBI S.A.'},
    {id: '15', descricao: '037 - Banco do Estado do Pará S.A.'},
    {id: '16', descricao: '039 - Banco do Estado do Piauí S.A. - BEP'},
    {id: '17', descricao: '040 - Banco Cargill S.A.'},
    {id: '18', descricao: '041 - Banco do Estado do Rio Grande do Sul S.A.'},
    {id: '19', descricao: '044 - Banco BVA S.A.'},
    {id: '20', descricao: '045 - Banco Opportunity S.A.'},
    {id: '21', descricao: '047 - Banco do Estado de Sergipe S.A.'},
    {id: '22', descricao: '062 - Hipercard Banco Múltiplo S.A.'},
    {id: '23', descricao: '063 - Banco Ibi S.A. Banco Múltiplo'},
    {id: '24', descricao: '064 - Goldman Sachs do Brasil Banco Múltiplo S.A.'},
    {id: '25', descricao: '065 - Banco Bracce S.A.'},
    {id: '26', descricao: '066 - Banco Morgan Stanley S.A.'},
    {id: '27', descricao: '069 - BPN Brasil Banco Múltiplo S.A.'},
    {id: '28', descricao: '070 - BRB - Banco de Brasília S.A.'},
    {id: '29', descricao: '072 - Banco Rural Mais S.A.'},
    {id: '30', descricao: '073 - BB Banco Popular do Brasil S.A.'},
    {id: '31', descricao: '074 - Banco J. Safra S.A.'},
    {id: '32', descricao: '075 - Banco CR2 S.A.'},
    {id: '33', descricao: '076 - Banco KDB S.A.'},
    {id: '34', descricao: '078 - BES Investimento do Brasil S.A.-Banco de Investimento'},
    {id: '35', descricao: '079 - JBS Banco S.A.'},
    {id: '36', descricao: '084 - Unicred Norte do Paraná'},
    {id: '37', descricao: '096 - Banco BM&amp;F de Serviços de Liquidação e Custódia S.A'},
    {id: '38', descricao: '104 - Caixa Econômica Federal'},
    {id: '39', descricao: '107 - Banco BBM S.A.'},
    {id: '40', descricao: '168 - HSBC Finance (Brasil) S.A. - Banco Múltiplo'},
    {id: '41', descricao: '184 - Banco Itaú BBA S.A.'},
    {id: '42', descricao: '204 - Banco Bradesco Cartões S.A.'},
    {id: '43', descricao: '208 - Banco BTG Pactual S.A.'},
    {id: '44', descricao: '212 - Banco Matone S.A.'},
    {id: '45', descricao: '213 - Banco Arbi S.A.'},
    {id: '46', descricao: '214 - Banco Dibens S.A.'},
    {id: '47', descricao: '215 - Banco Comercial e de Investimento Sudameris S.A.'},
    {id: '48', descricao: '217 - Banco John Deere S.A.'},
    {id: '49', descricao: '218 - Banco Bonsucesso S.A.'},
    {id: '50', descricao: '222 - Banco Credit Agricole Brasil S.A.'},
    {id: '51', descricao: '224 - Banco Fibra S.A.'},
    {id: '52', descricao: '225 - Banco Brascan S.A.'},
    {id: '53', descricao: '229 - Banco Cruzeiro do Sul S.A.'},
    {id: '54', descricao: '230 - Unicard Banco Múltiplo S.A.'},
    {id: '55', descricao: '233 - Banco GE Capital S.A.'},
    {id: '56', descricao: '237 - Banco Bradesco S.A.'},
    {id: '57', descricao: '241 - Banco Clássico S.A.'},
    {id: '58', descricao: '243 - Banco Máxima S.A.'},
    {id: '59', descricao: '246 - Banco ABC Brasil S.A.'},
    {id: '60', descricao: '248 - Banco Boavista Interatlântico S.A.'},
    {id: '61', descricao: '249 - Banco Investcred Unibanco S.A.'},
    {id: '62', descricao: '250 - Banco Schahin S.A.'},
    {id: '63', descricao: '254 - Paraná Banco S.A.'},
    {id: '64', descricao: '263 - Banco Cacique S.A.'},
    {id: '65', descricao: '265 - Banco Fator S.A.'},
    {id: '66', descricao: '266 - Banco Cédula S.A.'},
    {id: '67', descricao: '300 - Banco de La Nacion Argentina'},
    {id: '68', descricao: '318 - Banco BMG S.A.'},
    {id: '69', descricao: '320 - Banco Industrial e Comercial S.A.'},
    {id: '70', descricao: '341 - Itaú Unibanco S.A.'},
    {id: '71', descricao: '356 - Banco Real S.A.'},
    {id: '72', descricao: '366 - Banco Société Générale Brasil S.A.'},
    {id: '73', descricao: '370 - Banco WestLB do Brasil S.A.'},
    {id: '74', descricao: '376 - Banco J. P. Morgan S.A.'},
    {id: '75', descricao: '389 - Banco Mercantil do Brasil S.A.'},
    {id: '76', descricao: '394 - Banco Bradesco Financiamentos S.A.'},
    {id: '77', descricao: '399 - HSBC Bank Brasil S.A. - Banco Múltiplo'},
    {id: '78', descricao: '409 - UNIBANCO - União de Bancos Brasileiros S.A.'},
    {id: '79', descricao: '412 - Banco Capital S.A.'},
    {id: '80', descricao: '422 - Banco Safra S.A.'},
    {id: '81', descricao: '453 - Banco Rural S.A.'},
    {id: '82', descricao: '456 - Banco de Tokyo-Mitsubishi UFJ Brasil S.A.'},
    {id: '83', descricao: '464 - Banco Sumitomo Mitsui Brasileiro S.A.'},
    {id: '84', descricao: '473 - Banco Caixa Geral - Brasil S.A.'},
    {id: '85', descricao: '477 - Citibank N.A.'},
    {id: '86', descricao: '479 - Banco ItaúBank S.A'},
    {id: '87', descricao: '487 - Deutsche Bank S.A. - Banco Alemão'},
    {id: '88', descricao: '488 - JPMorgan Chase Bank'},
    {id: '89', descricao: '492 - ING Bank N.V.'},
    {id: '90', descricao: '494 - Banco de La Republica Oriental del Uruguay'},
    {id: '91', descricao: '495 - Banco de La Provincia de Buenos Aires'},
    {id: '92', descricao: '505 - Banco Credit Suisse (Brasil) S.A.'},
    {id: '93', descricao: '600 - Banco Luso Brasileiro S.A.'},
    {id: '94', descricao: '604 - Banco Industrial do Brasil S.A.'},
    {id: '95', descricao: '610 - Banco VR S.A.'},
    {id: '96', descricao: '611 - Banco Paulista S.A.'},
    {id: '97', descricao: '612 - Banco Guanabara S.A.'},
    {id: '98', descricao: '613 - Banco Pecúnia S.A.'},
    {id: '99', descricao: '623 - Banco Panamericano S.A.'},
    {id: '100', descricao: '626 - Banco Ficsa S.A.'},
    {id: '101', descricao: '630 - Banco Intercap S.A.'},
    {id: '102', descricao: '633 - Banco Rendimento S.A.'},
    {id: '103', descricao: '634 - Banco Triângulo S.A.'},
    {id: '104', descricao: '637 - Banco Sofisa S.A.'},
    {id: '105', descricao: '638 - Banco Prosper S.A.'},
    {id: '106', descricao: '641 - Banco Alvorada S.A.'},
    {id: '107', descricao: '643 - Banco Pine S.A.'},
    {id: '108', descricao: '652 - Itaú Unibanco Holding S.A.'},
    {id: '109', descricao: '653 - Banco Indusval S.A.'},
    {id: '110', descricao: '654 - Banco A.J.Renner S.A.'},
    {id: '111', descricao: '655 - Banco Votorantim S.A.'},
    {id: '112', descricao: '707 - Banco Daycoval S.A.'},
    {id: '113', descricao: '719 - Banif-Banco Internacional do Funchal (Brasil)S.A.'},
    {id: '114', descricao: '721 - Banco Credibel S.A.'},
    {id: '115', descricao: '724 - Banco Porto Seguro S.A.'},
    {id: '116', descricao: '734 - Banco Gerdau S.A.'},
    {id: '117', descricao: '735 - Banco Pottencial S.A.'},
    {id: '118', descricao: '738 - Banco Morada S.A.'},
    {id: '119', descricao: '739 - Banco BGN S.A.'},
    {id: '120', descricao: '740 - Banco Barclays S.A.'},
    {id: '121', descricao: '741 - Banco Ribeirão Preto S.A.'},
    {id: '122', descricao: '743 - Banco Semear S.A.'},
    {id: '123', descricao: '744 - BankBoston N.A.'},
    {id: '124', descricao: '745 - Banco Citibank S.A.'},
    {id: '125', descricao: '746 - Banco Modal S.A.'},
    {id: '126', descricao: '747 - Banco Rabobank International Brasil S.A.'},
    {id: '127', descricao: '748 - Banco Cooperativo Sicredi S.A.'},
    {id: '128', descricao: '749 - Banco Simples S.A.'},
    {id: '129', descricao: '751 - Dresdner Bank Brasil S.A. - Banco Múltiplo'},
    {id: '130', descricao: '752 - Banco BNP Paribas Brasil S.A.'},
    {id: '131', descricao: '753 - NBC Bank Brasil S.A. - Banco Múltiplo'},
    {id: '132', descricao: '755 - Bank of America Merrill Lynch Banco Múltiplo S.A.'},
    {id: '133', descricao: '756 - Banco Cooperativo do Brasil S.A. - BANCOOB'},
    {id: '134', descricao: '757 - Banco KEB do Brasil S.A.'},
    {id: '135', descricao: '077-9 - Banco Intermedium S.A.'},
    {id: '136', descricao: '081-7 - Concórdia Banco S.A.'},
    {id: '137', descricao: '082-5 - Banco Topázio S.A.'},
    {id: '138', descricao: '083-3 - Banco da China Brasil S.A.'},
    {id: '139', descricao: '085-x - Cooperativa Central de Crédito Urbano-CECRED'},
    {id: '140', descricao: '086-8 - OBOE Crédito Financiamento e Investimento S.A.'},
    {id: '141', descricao: '087-6 - Cooperativa Unicred Central Santa Catarina'},
    {id: '142', descricao: '088-4 - Banco Randon S.A.'},
    {id: '143', descricao: '089-2 - Cooperativa de Crédito Rural da Região de Mogiana'},
    {id: '144', descricao: '090-2 - Cooperativa Central de Economia e Crédito Mutuo das Unicreds'},
    {id: '145', descricao: '091-4 - Unicred Central do Rio Grande do Sul'},
    {id: '146', descricao: '092-2 - Brickell S.A. Crédito, financiamento e Investimento'},
    {id: '147', descricao: '094-2 - Banco Petra S.A.'},
    {id: '148', descricao: '097-3 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda.'},
    {id: '149', descricao: '098-1 - Credicorol Cooperativa de Crédito Rural'},
    {id: '150', descricao: '099-x - Cooperativa Central de Economia e Credito Mutuo das Unicreds'},
    {id: '151', descricao: 'M03 - Banco Fiat S.A.'},
    {id: '152', descricao: 'M06 - Banco de Lage Landen Brasil S.A.'},
    {id: '153', descricao: 'M07 - Banco GMAC S.A.'},
    {id: '154', descricao: 'M08 - Banco Citicard S.A.'},
    {id: '155', descricao: 'M09 - Banco Itaucred Financiamentos S.A.'},
    {id: '156', descricao: 'M10 - Banco Moneo S.A.'},
    {id: '157', descricao: 'M11 - Banco IBM S.A.'},
    {id: '158', descricao: 'M12 - Banco Maxinvest S.A.'},
    {id: '159', descricao: 'M13 - Banco Tricury S.A.'},
    {id: '160', descricao: 'M14 - Banco Volkswagen S.A.'},
    {id: '161', descricao: 'M15 - Banco BRJ S.A.'},
    {id: '162', descricao: 'M16 - Banco Rodobens S.A.'},
    {id: '163', descricao: 'M17 - Banco Ourinvest S.A.'},
    {id: '164', descricao: 'M18 - Banco Ford S.A.'},
    {id: '165', descricao: 'M19 - Banco CNH Capital S.A.'},
    {id: '166', descricao: 'M20 - Banco Toyota do Brasil S.A.'},
    {id: '167', descricao: 'M21 - Banco Daimlerchrysler S.A.'},
    {id: '168', descricao: 'M22 - Banco Honda S.A.'},
    {id: '169', descricao: 'M23 - Banco Volvo (Brasil) S.A.'},
    {id: '170', descricao: 'M24 - Banco PSA Finance Brasil S.A.'},
    {id: '171', descricao: '001 - Outros'},
    {id: '172', descricao: 'O01 - Outros'},
    {id: '173', descricao: 'O01 - Outros'},
    {id: '174', descricao: 'O01 - Outros'},
    {id: '175', descricao: 'O01 - Outros'},
    {id: '171', descricao: 'O01 - Outros'},
    {id: '172', descricao: '121 - Banco Agibank S.A.'},
    {id: '173', descricao: '077 - Banco Inter S.A.'},
    {id: '174', descricao: '260 - Nu Pagamentos S.A. (Nubank)'},
    {id: '175', descricao: '212 - Banco Original S.A.'},
    {id: '176', descricao: '336 - Banco C6'},
    {id: '177', descricao: '323 - MERCADO PAGO'},
    {id: '178', descricao: '290 - PAGSEGURO'},
  ]

  apiCall = this.api.new().silent();
  bancario = {
    banco: null,
    tipo_conta: null,
    agencia: null,
    conta: null,
    id: null,
    id_servidor: null,
    digito_conta: null,
    digito_agencia: null,
    dac: null,
  }
  idServidor
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }

  async ngOnInit(){
    const self = this
    self.bancario = null
    self.idServidor = null
    self.bancario = {
      banco: null,
      tipo_conta: null,
      agencia: null,
      conta: null,
      id: null,
      id_servidor: null,
      digito_conta: null,
      digito_agencia: null,
      dac: null,
    }
    this.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        this.idServidor = parametros['id'];
      }
    });

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    const bancario = await self.apiCall.get(`servidor/dadosbancarios?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise()

    if(bancario) self.bancario = JSON.parse(JSON.stringify(bancario));

    self.spinner.hide()
  }


  async atualizarOuCadastrar(){
    const self = this
    if(!self.bancario || !self.idServidor){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(self.bancario.id){
      await self.apiCall.get(`servidor/dadosbancarios/editar?id_bancario=${self.bancario.id}&banco=${self.bancario.banco}&tipo_conta=${self.bancario.tipo_conta}&agencia=${self.bancario.agencia}&digito_agencia=${self.bancario.digito_agencia}&conta=${self.bancario.conta}&digito_conta=${self.bancario.digito_conta}&dac=${self.bancario.dac}`).pipe(take(1)).toPromise()
    }else{
      await self.apiCall.get(`servidor/dadosbancarios/novo?id_servidor=${self.idServidor}&banco=${self.bancario.banco}&tipo_conta=${self.bancario.tipo_conta}&agencia=${self.bancario.agencia}&digito_agencia=${self.bancario.digito_agencia}&conta=${self.bancario.conta}&digito_conta=${self.bancario.digito_conta}&dac=${self.bancario.dac}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.bancario.id ? 'Dados bancários atualizados com sucesso!' : 'Dados bancários cadastrados com sucesso!', 'success')
    await self.ngOnInit()
  }

}
