import { Component, OnInit } from '@angular/core';
import Api from 'app/helpers/api';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-registroatividades',
  templateUrl: './registroatividades.component.html',
  styleUrls: ['./registroatividades.component.scss']
})
export class RegistroatividadesComponent implements OnInit {

  apiCall = this.api.new().silent();
  usuario_log: any[] = [];
  pageAd = 1;
  pageSizeAd = 10;

  constructor(
    private api: Api,
    private spinner: NgxSpinnerService,
  ) {}

  async ngOnInit() {
    this.spinner.show(undefined, {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });

    try {
      this.usuario_log = await this.apiCall.get('users/log').pipe(take(1)).toPromise();
      console.log('Dados carregados:', this.usuario_log);
    } catch (error) {
      console.error('Erro ao carregar dados', error);
    } finally {
      this.spinner.hide();
    }
  }

  get startItem(): number {
    return (this.pageAd - 1) * this.pageSizeAd + 1;
  }

  get endItem(): number {
    return Math.min(this.pageAd * this.pageSizeAd, this.usuario_log.length);
  }

  get totalItems(): number {
    return this.usuario_log.length;
  }

  prepareDate(data: string | null): string {
    if (data) {
      return moment(data).format('DD-MM-YYYY HH:mm:ss'); // Formato para data e hora
    } else {
      return '';
    }
  }

}
