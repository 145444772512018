<div class="row">
  <div class="col" (click)="navigateTo('page/servidor/pessoal')">
    <div class="card bg-primary" style="cursor: pointer">
      <div class="card-content">
        <div class="card-body p-0">
          <div class="media p-3" style="padding-bottom: 0 !important;">
            <div class="media-body white text-left">
              <h4 class="font-medium-6 white mb-0">DADOS PESSOAIS</h4>
              <p style="font-size: 0.85rem;">ENDEREÇO | DOCUMENTOS | CONTATO</p>
            </div>
            <div class="media-right white text-right">
              <i class="fa fa-user font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col" *ngIf="servidor && (!servidor.status || (servidor.status && servidor.status!=3))" (click)="servidor.status==2 ? navigateTo('page/servidor/dependentes') : multipleNavigate(contentPrefeitura)">
    <div class="card bg-primary" style="cursor: pointer">
      <div class="card-content">
        <div class="card-body p-0">
          <div class="media p-3" style="padding-bottom: 0 !important;">
            <div class="media-body white text-left">
              <h4 class="font-medium-6 white mb-0">DADOS PREFEITURA</h4>
              <p style="font-size: 0.85rem;">{{servidor.status && servidor.status==2 ? 'DEPENDENTES' : 'DEPENDENTES | MOVIMENTOS FUNCIONAIS | TEMPO SERV. PASSADO'}}</p>
            </div>
            <div class="media-right white text-right">
              <i class="fa fa-book font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="card bg-primary"style="cursor: pointer" (click)="navigateTo('page/servidor/anexo')">
      <div class="card-content">
        <div class="card-body p-0">
          <div class="media p-3">
            <div class="media-body white text-left">
              <h4 class="font-medium-6 white mb-0">ANEXOS</h4>
            </div>
            <div class="media-right white text-right">
              <i class="fa fa-paperclip font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card bg-primary" style="cursor: pointer" (click)="(!servidor || !servidor.status || (servidor && servidor.status && servidor.status!=1 && servidor.status!=2)) ? multipleNavigate(contentOutros) : navigateTo('page/servidor/representante')">
      <div class="card-content">
        <div class="card-body p-0">
          <div class="media p-3">
            <div class="media-body white text-left">
              <h4 class="font-medium-6 white mb-0">{{!servidor || !servidor.status || (servidor && servidor.status && servidor.status!=1 && servidor.status!=2) ? 'ÓBITO | REPRESENTANTE' : 'REPRESENTANTE'}}</h4>
            </div>
            <div class="media-right white text-right">
              <i class="fa fa-id-badge font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Servidor</h3>
    </div>
  </div>
</div>
<div class="row mb-2" *ngIf="servidor">
  <div class="col " *ngIf="servidor.inicio_atendimento==null" >
    <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary float-left" (click)="iniciarAtendimento()" >Iniciar Atendimento</button>
  </div>
  <div class="col " *ngIf="servidor.inicio_atendimento!=null && servidor.fim_atendimento!=null" >
    <button  type="button" class="btn btn-lg btn-primary float-left" (click)="openModal(comprovante)" >Comprovante de Atendimento</button>
    <button  type="button" class="btn btn-lg btn-primary float-left ml-1" (click)="reiniciarAtendimento()" >Reiniciar Atendimento</button>
  </div>
  <div class="col " *ngIf="servidor.inicio_atendimento!=null && servidor.fim_atendimento==null" >
    <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary float-right" (click)="finalizarAtendimento()" >Finalizar Atendimento</button>
  </div>
</div>
<div class="row mb-2" *ngIf="servidor">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <form #servForm="ngForm" novalidate>
            <div class="form-row">
              <div class="col">
                <div class="media">
                  <img [src]="servidor && servidor.imagem ? servidor.imagem : 'assets/img/portrait/small/no-avatar.svg'" 
                       (click)="servidor && servidor.imagem ? viewMidia(servidor.imagem, contentImg) : null" 
                       alt="profile-img" 
                       class="rounded-circle mr-3"  
                       [ngClass]="{'cursor-pointer': servidor && servidor.imagem}" 
                       height="100"
                       width="100">
                  <div class="media-body">
                    <div class="row justify-content-between px-0 mb-sm-2">
                      <div class="col-sm-auto mb-2 mb-sm-0">
                        <label class="btn btn-sm bg-light-primary mb-sm-0" for="select-files">Upload Imagem</label>
                        <input type="file" accept="image/*" (change)="uploadImage($event)" id="select-files" hidden>
                        <button type="button" class="btn btn-sm bg-light-primary mb-sm-0 ml-2" (click)="openModalCamera(takepicture)">TIRAR FOTO</button>
                      </div>
                      <div class="col-sm-auto" *ngIf="servidor && servidor.agenda !== null">
                        <div class="form-group">
                          <div class="controls">
                            <ng-select [items]="statusAgenda" 
                                       name="statusAgenda" 
                                       bindLabel="descricao" 
                                       bindValue="id" 
                                       placeholder="" 
                                       id="status_agenda" 
                                       style="width: 280px;"
                                       [(ngModel)]="servidor.agenda"
                                       (change)="updateAgendaStatus($event)" >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="text-muted mb-0 mt-1 mt-sm-0">
                      <small>Permitido apenas JPG, JPEG ou PNG. Tamanho máximo de arquivo de 2mb.</small>
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
            <div class="form-row" *ngIf="servidor.atendimento!=null">
              <b>{{servidor.atendimento}}</b>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome">Nome <b  style="color: red;">*</b></label>
                    <input type="text" id="nome" class="form-control" [(ngModel)]="servidor.nome" name="nome" placeholder="Nome Completo"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_nascimento">Nascimento <b  style="color: red;">*</b></label>
                    <input type="text" id="data_nascimento" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_nascimento" name="data_nascimento" placeholder="Data Nascimento"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="estado_civil">Estado Civil <b  style="color: red;">*</b></label>
                    <ng-select [items]="estadosCivis"   name="estado_civil" bindLabel="descricao" bindValue="id"  placeholder="Estado Civil" id="estado_civil" (change)="filter()" [(ngModel)]="servidor.estado_civil"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="sexo">Sexo <b  style="color: red;">*</b></label>
                    <ng-select [items]="sexos"   name="sexo" bindLabel="name"  placeholder="Sexo" bindValue="id" id="sexo" [(ngModel)]="servidor.sexo"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome">Estado <b  style="color: red;">*</b></label>
                    <ng-select [items]="estados"  name="estado" (change)="toggleEstado()"  bindLabel="estado" bindValue="estado"  placeholder="Estado" id="estado" [(ngModel)]="servidor.estado_naturalidade"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome">Cidade <b  style="color: red;">*</b></label>
                    <ng-select [items]="cidades" [disabled]="!cidades || cidades.length==0"  name="cidade"  bindLabel="nome" bindValue="id"  placeholder="Cidade" id="cidade" [(ngModel)]="servidor.cidade_naturalidade"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nacionalidade">Nacionalidade <b  style="color: red;">*</b></label>
                    <input type="text" id="nacionalidade" class="form-control" [(ngModel)]="servidor.nacionalidade" name="nacionalidade" placeholder="Nacionalidade"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="naturalizado">Naturalizado <b  style="color: red;">*</b></label>
                    <ng-select [items]="naturalizados"   name="naturalizado" bindLabel="name"  placeholder="Naturalizado" bindValue="id" id="naturalizado" [(ngModel)]="servidor.naturalizado"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="escolaridade">Status <b  style="color: red;">*</b></label>
                    <ng-select [items]="statusServidor"   name="status" bindLabel="name" bindValue="id"  placeholder="Status" id="status" [(ngModel)]="servidor.status"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="escolaridade">Escolaridade <b  style="color: red;">*</b></label>
                    <ng-select [items]="escolaridades"   name="escolaridade" bindLabel="descricao" bindValue="id"  placeholder="Escolaridade" id="escolaridade" [(ngModel)]="servidor.escolaridade"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="mae">Nome Mãe <b  style="color: red;">*</b></label>
                    <input type="text" id="mae" class="form-control" [(ngModel)]="servidor.nome_mae" name="mae" placeholder="Nome Mãe"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="pai">Nome Pai <b  style="color: red;">*</b></label>
                    <input type="text" id="pai" class="form-control" [(ngModel)]="servidor.nome_pai" name="pai" placeholder="Nome Pai"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <!-- <div class="col" *ngIf="servidor && servidor.status && servidor.status!=2  && servidor.status!=3" >
                <div class="form-group">
                  <div class="controls">
                    <label for="data_ingresso">Data de Admissão</label>
                    <input type="text" id="data_ingresso" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_ingresso_servico_publico" name="data_ingresso" placeholder="Data de Admissão"
                           required aria-invalid="false">
                  </div>
                </div>
              </div> -->
              <div class="col" *ngIf="servidor && servidor.status && servidor.status==5" >
                <div class="form-group">
                  <div class="controls">
                    <label for="docingresso">Documento de Ingresso</label>
                    <input type="text" id="docingresso" class="form-control" [(ngModel)]="servidor.documento_ingresso_servico_publico" name="docingresso" placeholder="Documento de Ingresso"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="deficiente">Pessoa com deficiência - PCD <b  style="color: red;">*</b></label>
                    <ng-select [items]="naturalizados"   name="deficiente" bindLabel="name"  placeholder="Deficiente" bindValue="id" id="deficiente" [(ngModel)]="servidor.deficiente"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col" *ngIf="servidor && (servidor.status==3)">
                <div class="form-group">
                  <div class="controls">
                    <label for="tipo_fundo">Tipo de Vínculo <b  style="color: red;">*</b></label>
                    <ng-select [items]="tipoVinculo"  name="tipo_vinculo" bindLabel="name"  placeholder="Tipo de Vínculo" id="tipo_vinculo" [(ngModel)]="servidor.tipo_vinculo"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col" *ngIf="servidor && (servidor.status==2 || servidor.status==3)">
                <div class="form-group">
                  <div class="controls">
                    <label for="tipo_fundo">Tipo de Fundo <b  style="color: red;">*</b></label>
                    <ng-select [items]="tipoFundo"   name="tipo_fundo" bindLabel="name"  placeholder="Tipo de Fundo" id="tipo_fundo" [(ngModel)]="servidor.tipo_fundo"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tipo_fundo">Data Falecimento</label>
                    <input type="text" id="data_falecimento" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_falecimento" name="data_falecimento" placeholder="Data do Falecimento"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mb-1" *ngIf="servidor?.status && servidor?.status !== 2 && servidor?.status !== 3 && servidor?.data_ingresso_servico_publico">
              <div class="col">
                Tempo de contribuição no município: {{ getTotalDaysFromDateToToday(servidor?.data_ingresso_servico_publico) }} dias
              </div>
            </div>
            <div class="form-row" *ngIf="servidor && servidor.status && servidor.status==2" >
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="valoraposentadoria">Valor Atual da Aposentadoria</label>
                    <input type="text" id="valoraposentadoria" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." decimalMarker="," [dropSpecialCharacters]="false"  [(ngModel)]="servidor.valor_aposentadoria" name="valoraposentadoria" placeholder="Valor Atual da Aposentadoria"
                    required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tiposaposentadoria">Tipo de Aposentadoria</label>
                    <ng-select [items]="tiposaposentadoria"   name="tiposaposentadoria" bindLabel="name" groupBy="group" placeholder="Tipo de Aposentadoria" bindValue="value" id="tiposaposentadoria" [(ngModel)]="servidor.tipo_aposentadoria"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="valoraposentadoria">Cargo em que aposentou</label>
                    <input type="text" id="cargo" class="form-control" [(ngModel)]="servidor.cargo_aposentadoria" name="cargo" placeholder="Cargo em que aposentou"
                    required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="pis_pasep">PIS do Aposentado</label>
                    <input type="text" id="pis_pasep" class="form-control" [(ngModel)]="servidor.pis_aposentado" mask="0*" name="pis_pasep" placeholder="PIS do Aposentado"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="servidor && servidor.status && servidor.status==2" >
                <div class="col">
                  <div class="form-group">
                    <div class="controls">
                      <label for="matricula_beneficiario">Matrícula</label>
                    <input type="text" id="matricula_beneficiario" class="form-control" [(ngModel)]="servidor.matricula_beneficiario" name="matricula_beneficiario" placeholder="Matrícula"
                    required aria-invalid="false">
                    </div>
                  </div>
                </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_ingresso">Data de Ingresso no Serviço Público</label>
                    <input type="text" id="data_ingresso" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_ingresso_servico_publico" name="data_ingresso" placeholder="Data de Ingresso no Serviço Público"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_concessao">Data de Concessão do Benefício</label>
                    <input type="text" id="data_concessao" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.concessao_aposentadoria" name="data_concessao" placeholder="Data de Concessão do Benefício"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="paridade">Paridade</label>
                    <ng-select [items]="naturalizados"   name="paridade" bindLabel="name"  placeholder="Paridade" bindValue="id" id="paridade" [(ngModel)]="servidor.paridade_aposentadoria"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="servidor && servidor.status && servidor.status==3" >
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="valor_beneficio">Valor do Benefício</label>
                    <input type="text" id="valor_beneficio" class="form-control" prefix="R$" mask="separator.2" thousandSeparator="." decimalMarker="," [dropSpecialCharacters]="false"  [(ngModel)]="servidor.valor_beneficio" name="valor_beneficio" placeholder="Valor do Benefício"
                    required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="tipo_pensao">Tipo de Pensão</label>
                    <ng-select [items]="tipospensao"   name="tipo_pensao" bindLabel="name" placeholder="Tipo de Pensão" bindValue="value" id="tipo_pensao" [(ngModel)]="servidor.tipo_pensao"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="idade">Idade</label>
                    <input type="text" id="idade" class="form-control" [(ngModel)]="servidor.idade" disabled name="idade" placeholder="Idade"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="matricula_beneficiario">Matrícula do Beneficiário</label>
                    <input type="text" id="matricula_beneficiario" class="form-control" [(ngModel)]="servidor.matricula_beneficiario" name="matricula_beneficiario" placeholder="Matrícula do Beneficiário"
                    required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="servidor && servidor.status && servidor.status==3" >
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_concessao">Data de Concessão do Benefício</label>
                    <input type="text" id="data_concessao" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.concessao_aposentadoria" name="data_concessao" placeholder="Data de Concessão do Benefício"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_falecimento_pensao">Data de Falecimento do Instituidor da Pensão</label>
                    <input type="text" id="data_falecimento_pensao" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_falecimento_pensao" name="data_falecimento_pensao" placeholder="Data de Falecimento do Instituidor da Pensão"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="data_nascimento_pensao">Data de Nascimento do Instituidor da Pensão</label>
                    <input type="text" id="data_nascimento_pensao" class="form-control" mask="00/00/0000" [(ngModel)]="servidor.data_nascimento_pensao" name="data_nascimento_pensao" placeholder="Data de Nascimento do Instituidor da Pensão"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="servidor && servidor.status && servidor.status==3" >
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome_pensao">Nome do Instituidor <b  style="color: red;">*</b></label>
                    <input type="text" id="nome_pensao" class="form-control" [(ngModel)]="servidor.nome_pensao" name="nome_pensao" placeholder="Nome do Instituidor"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="matricula_pensao">Matrícula do Instituidor</label>
                    <input type="text" id="matricula_pensao" class="form-control" [(ngModel)]="servidor.matricula_pensao" name="matricula_pensao" placeholder="Matrícula do Instituidor"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="pis_pasep">PIS do Instituidor</label>
                    <input type="text" id="pis_pasep" class="form-control" [(ngModel)]="servidor.pis_pensao" mask="0*" name="pis_pasep" placeholder="PIS do Instituidor"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cpf_pensao">CPF do Instituidor <b  style="color: red;">*</b></label>
                    <input type="text" id="cpf_pensao" class="form-control" [(ngModel)]="servidor.cpf_pensao" name="cpf_pensao" placeholder="CPF do Instituidor"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="paridade">Paridade</label>
                    <ng-select [items]="naturalizados"   name="paridade" bindLabel="name"  placeholder="Paridade" bindValue="id" id="paridade" [(ngModel)]="servidor.paridade_aposentadoria"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="servidor && servidor.status && servidor.status==4" >
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="motivo_exoneracao">Motivo Exoneração</label>
                    <input type="text" id="motivo_exoneracao" class="form-control" [(ngModel)]="servidor.motivo_exoneracao" name="motivo_exoneracao" placeholder="Motivo Exoneração"
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="pendencias">Pendências</label>
                    <input type="text" id="pendencias" class="form-control" [(ngModel)]="servidor.pendencias" name="pendencias" placeholder="Escreva a pendência ou observação do Servidor cadastrado. Documento faltante e/ou busca a ser feita ou confirmada para êxito no cadastro."
                           required aria-invalid="false">
                  </div>
                </div>
              </div>
              <div class="col" *ngIf="servidor && servidor.status == 3">
                <div class="form-group">
                  <div class="controls">
                    <label for="relacao_titular_pensao">Relação com o Titular</label>
                    <ng-select [items]="relacaoTitularPensao"  name="relacao_titular" bindLabel="name" placeholder="Relação com o Titular" bindValue="id" id="relacao_titular_pensao" [(ngModel)]="servidor.relacao_titular_pensao"></ng-select>
                  </div>
                </div>
              </div>
              <div class="col" >
                <div class="form-group">
                  <div class="controls">
                    <label for="orgao">Orgão <b  style="color: red;">*</b></label>
                    <ng-select [items]="orgaos"  name="orgao" bindLabel="descricao" placeholder="Orgão" bindValue="id" id="orgao" [(ngModel)]="servidor.orgao"></ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="observacoes">observações</label>
                    <textarea maxlength="1000"   id="observacoes" class="form-control" [(ngModel)]="servidor.observacoes" name="observacoes" placeholder="Observações"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col" >
                <button type="button" class="btn btn-lg btn-outline-primary mb-2 mb-md-0 float-left" routerLink="/page/servidores" >Voltar</button>
              </div>
              <div class="col" >
                <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 float-right" [disabled]="disableServidor()" 
                (click)="editarServidor()" >Atualizar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>
</div>
<ng-template #contentImg>
  <IMG style="width: 100%; height: 100%;" [src]="urlToShow">
</ng-template>
<ng-template #contentPrefeitura let-modal>
  <div class="row m-2">
    <div class="col text-center" >
      <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary" (click)="modal.close('Close click'); navigateTo('page/servidor/dependentes')" >Dependentes</button>
    </div>
  </div>
  <div class="row m-2">
    <div class="col text-center" >
      <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary" (click)="modal.close('Close click'); navigateTo('page/servidor/movimento')" >Movimentos funcionais</button>
    </div>
  </div>
  <div class="row m-2">
    <div class="col text-center">
      <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary" (click)="modal.close('Close click'); navigateTo('page/servidor/tempos')" >Tempo de Serviço Passado</button>
    </div>
  </div>
</ng-template>
<ng-template #contentTempos let-modal>
  <div class="row m-2">
    <div class="col text-center">
      <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary" (click)="modal.close('Close click'); navigateToTempos(1)" >Cadastro CTC (RPPS)</button>
    </div>
  </div>
  <div class="row m-2">
    <div class="col text-center">
      <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary" (click)="modal.close('Close click'); navigateToTempos(2)" >Cadastro CTC (INSS)</button>
    </div>
  </div>
</ng-template>
<ng-template #contentOutros let-modal>
  <div class="row m-2">
    <div class="col text-center" >
      <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary" (click)="modal.close('Close click'); navigateTo('page/servidor/obito')" >Óbito</button>
    </div>
  </div>
  <div class="row m-2">
    <div class="col text-center" >
      <button style="width: 20rem;" type="button" class="btn btn-lg btn-primary" (click)="modal.close('Close click'); navigateTo('page/servidor/representante')" >Representante Legal</button>
    </div>
  </div>
</ng-template>

<ng-template #takepicture let-modal>

  <div class="row mb-2" id="print-section"  >
    <div class="col">
      <div class="card">
        <div class="card-header">
          <p style="color: red;"><b>Antes de capturar certifique-se de registrar uma foto onde o rosto do servidor estejam bem visível, com boa nitidez e luminosidade. Não permita utilizar bonés, óculos e nem sorrir!</b></p>
        </div>
        <div class="card-content">

      <webcam [height]="500"  [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event, modal)"  [imageQuality]="1"
      [allowCameraSwitch]="true"
    ></webcam>
    <button type="button" class="btn btn-lg btn-primary mb-2 mb-md-0 btn-block" (click)="triggerSnapshot()" >Tirar Foto</button>
        </div>
      </div>
    </div>
  </div>
  </ng-template>
<ng-template #comprovante let-modal>
  <div class="row mb-2" id="print-section"  >
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Comprovante de atendimento</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col">
                Inicio do atendimento: {{beautifulDate(servidor.inicio_atendimento)}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Fim do atendimento: {{beautifulDate(servidor.fim_atendimento)}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Nome: {{servidor.nome}}
              </div>
            </div>
            <div class="row" *ngIf="servidor.cpf || servidor.cpf_pensao">
              <div class="col">
                CPF: {{servidor.cpf || servidor.cpf_pensao}}
              </div>
            </div>
            <div class="row" *ngIf="servidor.matricula_pensao || servidor.matricula_beneficiario">
              <div class="col">
                Matricula: {{servidor.matricula_pensao || servidor.matricula_beneficiario}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Município: {{servidor.nomecidade}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b>Você participou do censo previdenciário {{getYear(servidor.inicio_atendimento)}} do regime próprio de previdência social do município de {{servidor.nomecidade}}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      <button  type="button" class="btn btn-lg btn-primary" style="margin-left: auto; margin-right: auto; display: flex;"    printTitle="Comprovante de atendimento"
      printDelay="1000"  printSectionId="print-section" ngxPrint >Salvar PDF</button>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ngx-spinner></ngx-spinner>
