import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import Me from "../../helpers/me";
import {take} from "rxjs/operators";
import Api from "../../helpers/api";
import * as moment from 'moment'
@Injectable()
export class AuthGuard implements CanActivate {
   constructor(private me: Me, private api: Api, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const self = this;
    // se nao tiver logado, retorna falso


    let validade = localStorage.getItem('validateCenso')
    if(validade){
      if(moment(validade).isSameOrBefore(moment())){
        localStorage.removeItem('currentUser');
      }
    }

    if(route.queryParams && route.queryParams.token){
      try{
        let aux = atob(route.queryParams.token)
        let cliente = JSON.parse(aux)
        if(cliente && cliente.usuarioCenso && cliente.senhaCenso){
          localStorage.removeItem('currentUser');
          if(cliente.validadeAcessoCenso){
            localStorage.setItem('validateCenso', cliente.validadeAcessoCenso)
            if(moment(cliente.validadeAcessoCenso).isSameOrBefore(moment())){
              this.router.navigate(['/pages/login']);
              return false;
            }
          }
          await this.me.login(cliente.usuarioCenso, cliente.senhaCenso, '2')
          this.router.navigate(['/page/servidores'])
          return false;
          //localStorage.setItem('currentUser', JSON.stringify(user))
        }
      }catch(e){
        this.router.navigate(['/pages/login']);
        return false;
      }
    }

    const currentUser = this.me.currentUserValue;
    if (currentUser) {
      const json = JSON.parse(JSON.stringify(currentUser))
      if(json){
        if(json.user){
          if (json.user.tipo==='1' && state.url=='/page/admin') {
            return true;
          }else if (json.user.tipo==='2' && state.url=='/page/admin'){
            this.router.navigate(['/page/servidor/inicio']);
            return false;
          }else if (json.user.tipo==='1' && state.url=='/page'){
            this.router.navigate(['/page/admin']);
            return false;
          }else if (json.user.tipo==='2' && state.url=='/page'){
            this.router.navigate(['/page/servidor/inicio']);
            return false;
          }else{
            return true;
          }
        }else{
          return true;
        }
      }
    }else{
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/pages/login']);
      return false;
    }
  }
}


