<table>
  <thead>
    <tr>
      <th>Admissões</th>
      <th>Servidores</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of admissaoServidor">
      <td>{{ item.coluna1 }}</td>
      <td>{{ item.coluna2 }}</td>
    </tr>
  </tbody>
</table>
<div class="tablePadding">
  <h6>a. Tempo de Serviço Passado</h6>
  <table>
    <tbody>
      <tr *ngFor="let item of tempoServicoPassado">
        <td class="coluna1">{{ item.coluna1 }}</td>
        <td>{{ item.coluna2 }}</td>
        <td>{{ item.coluna3 }}</td>
      </tr>
    </tbody>
  </table>
</div>
<h6 class="composicaoTitle">b. Composição Familiar</h6>
<div class="tablePadding">
  <table>
    <tbody>
      <tr *ngFor="let item of composicaoFamiliar">
        <td class="coluna1">{{ item.coluna1 }}</td>
        <td>{{ item.coluna2 }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="tablePadding">
  <table >
    <thead>
      <tr>
        <th class="cabecalho" colspan="2" style="text-align: center;">Diferença Idades</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of composicaoFamiliarIdades">
        <td class="coluna1">{{ item.coluna1 }}</td>
        <td>{{ item.coluna2 }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="tablePadding">
  <table >
    <tbody>
      <tr *ngFor="let item of idadeMediaFilhos">
        <td class="coluna1">{{ item.coluna1 }}</td>
        <td>{{ item.coluna2 }}</td>
      </tr>
    </tbody>
  </table>
</div>

