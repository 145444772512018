<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-triangle-path"
  [fullScreen]="true">
</ngx-spinner>

<h1>Registro de Atividades</h1>

<div *ngIf="usuario_log && usuario_log.length > 0" class="row mb-2">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0 table-container">
        <div class="table-wrapper">
          <table class="table text-center m-0">
            <thead>
              <tr>
                <th class="table-cell">Usuário</th>
                <th class="table-cell">Município</th>
                <th class="table-cell">Último acesso</th>
                <th class="table-cell">Tempo total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let usuario of usuario_log | slice: (pageAd-1) * pageSizeAd : (pageAd-1) * pageSizeAd + pageSizeAd">
                <td class="table-cell">{{usuario.usuario}}</td>
                <td class="table-cell">{{usuario.municipio}}</td>
                <td class="table-cell">{{this.prepareDate(usuario.dataUltimoAcesso)}}</td>
                <td class="table-cell">{{usuario.tempoTotal || 'N/A'}}</td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-wrapper">
            <div class="pagination-info">
              Exibindo {{startItem}} a {{endItem}} de {{totalItems}} itens
            </div>
            <div class="pagination-controls">
              <ngb-pagination style="margin-top: 10px"
                              [(page)]="pageAd"
                              [pageSize]="pageSizeAd"
                              [maxSize]="12"
                              [collectionSize]="totalItems"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="usuario_log.length === 0">
  <p>Nenhum dado disponível.</p>
</div>
