<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Usuários</h3>
      <button  type="button" class="btn btn-outline-primary" (click)="openModal(cadastroUsuario, null, null)">Novo Usuário</button>
    </div>
  </div>
</div>
<div class="row mb-2" >
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0 pb-0">

        <table class="table text-center m-0">
          <thead>
          <tr>
            <th><input  type="text" id="cidade"  class="form-control" style="background-color: white; height: 100%;" placeholder="Cidade" name="cidade" [(ngModel)]="filterCidade" (keyup)="filter()" ></th>
            <th><input type="text" id="nome"  class="form-control" style="background-color: white; height: 100%" placeholder="Nome" name="nome" [(ngModel)]="filterNome" (keyup)="filter()" ></th>
            <th>Login</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="normais.length > 0">
          <tr *ngFor="let servidor of normais| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <td>{{servidor.nomeCidade}}</td>
            <td>{{servidor.nome}}</td>
            <td>{{servidor.login}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="openModal(atualizarSenha, servidor, '2')">Atualizar Senha</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px" *ngIf="normais.length > 0">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px"
                          [(page)]="page"
                          [pageSize]="pageSize"  [maxSize]="12"
                          [collectionSize]="normais.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="normais.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pt-0">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum usuário encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Usuários Administradores</h3>
    </div>
  </div>
</div>
<div class="row mb-2" *ngIf="administradores.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Nome</th>
            <th>Login</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let servidor of administradores| slice: (pageAd-1) * pageSizeAd : (page-1) * pageSizeAd + pageSizeAd">
            <td>{{servidor.nome}}</td>
            <td>{{servidor.login}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="openModal(atualizarSenha, servidor, '1')">Atualizar Senha</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center">
          <ngb-pagination style="margin-top: 10px" *ngIf="administradores.length>0"
                          [(page)]="pageAd"
                          [pageSize]="pageSizeAd"  [maxSize]="12"
                          [collectionSize]="administradores.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="administradores.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum usuário administrador encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #cadastroUsuario let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Novo Usuário</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #cadForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="tipos">Tipo</label>
                <ng-select [items]="tipos"  name="tipos"  bindLabel="name" bindValue="id"  placeholder="Tipo" id="tipos" [(ngModel)]="novoUsuario.tipoUsuario"></ng-select>
              </div>
              <div class="form-group mb-2">
                <label for="nome">Nome</label>
                <input type="text" id="nome" class="form-control" name="nome" [(ngModel)]="novoUsuario.nome" #inputName="ngModel" required>
              </div>
              <div class="form-group mb-2">
                <label for="login">Login</label>
                <input type="text" id="login" class="form-control" name="login" [(ngModel)]="novoUsuario.login" #inputLog="ngModel" required>
              </div>
              <div class="form-group mb-2" *ngIf="novoUsuario.tipoUsuario=='2'">
                <label for="estado">Estado</label>
                <ng-select [items]="estados"  name="estado" (change)="toggleEstado()"  bindLabel="estado" bindValue="estado"  placeholder="Estado" id="estado" [(ngModel)]="novoUsuario.estado"></ng-select>
              </div>
              <div class="form-group mb-2" *ngIf="novoUsuario.tipoUsuario=='2'">
                <label for="cidade">Cidade</label>
                <ng-select [items]="cidades" [disabled]="!cidades || cidades.length==0"  name="cidade"  bindLabel="nome" bindValue="id"  placeholder="Cidade" id="cidade" [(ngModel)]="novoUsuario.cidade"></ng-select>
              </div>
              <div class="form-group mb-2">
                <label for="novaSenha">Nova Senha</label>
                <input type="text" id="novaSenha" class="form-control" name="novaSenha" [(ngModel)]="novoUsuario.senha" #inputSenh="ngModel" required>
              </div>
              <div class="form-group mb-2">
                <label for="confirmaNovaSenha">Confirme a Nova Senha</label>
                <input type="text" id="confirmaNovaSenha" class="form-control" name="confirmaNovaSenha" [(ngModel)]="novoUsuario.confirmaSenha" #inputConfir="ngModel" required>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block btn-secondary" (click)="modal.close('Close click')">Cancelar</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="insertUsuario(modal)" [disabled]="!novoUsuario || !novoUsuario.nome || !novoUsuario.login || !novoUsuario.tipoUsuario|| (novoUsuario.tipoUsuario=='2' && (!novoUsuario.estado || !novoUsuario.cidade))|| !novoUsuario.senha || !novoUsuario.confirmaSenha || (novoUsuario.senha!=novoUsuario.confirmaSenha)">Cadastrar</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #atualizarSenha let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Nova Senha</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="novaSenha">Nova Senha</label>
                <input type="text" id="novaSenha" class="form-control" name="novaSenha" [(ngModel)]="atualizacaoSenha.novaSenha" #inputSenha="ngModel" required>
              </div>
              <div class="form-group mb-2">
                <label for="confirmaNovaSenha">Confirme a Nova Senha</label>
                <input type="text" id="confirmaNovaSenha" class="form-control" name="confirmaNovaSenha" [(ngModel)]="atualizacaoSenha.confirmaNovaSenha" #inputConfir="ngModel" required>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block btn-secondary" (click)="modal.close('Close click')">Cancelar</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="updateSenha(modal)" [disabled]="!atualizacaoSenha || !atualizacaoSenha.novaSenha || !atualizacaoSenha.confirmaNovaSenha || (atualizacaoSenha.novaSenha!=atualizacaoSenha.confirmaNovaSenha)">Atualizar</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ngx-spinner></ngx-spinner>
