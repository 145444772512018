import { Component, OnInit } from '@angular/core';
import Api from 'app/helpers/api';
import Me from 'app/helpers/me';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-relatorios-planilha',
  templateUrl: './relatorios-planilha.component.html',
  styleUrls: ['./relatorios-planilha.component.scss']
})
export class RelatoriosPlanilhaComponent implements OnInit {
  planilhas = [];
  apiCall = this.api.new().silent();

  constructor(
    private spinner: NgxSpinnerService,
    private me: Me,
    private api: Api,
  ) { }

  async ngOnInit() {
    const self = this
    const dadosUsuario = self.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    const idCidade = json.user.cidade;
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const planilhas = await self.apiCall
    .get("servidor/arquivo/planilha?id_cidade=" + idCidade)
    .pipe(take(1))
    .toPromise();
    await self.spinner.hide();
    self.planilhas = JSON.parse(JSON.stringify(planilhas));
  }
  beautifulUrl(url) {
    if (url) {
      return url.replace(
        "https://cadastro-pessoas-arquivos-servidor.s3.amazonaws.com/",
        ""
      );
    } else {
      return "";
    }
  }
}
