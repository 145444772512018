import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as cep from "cep-promise";
import * as moment from "moment";

@Component({
  selector: 'app-obito',
  templateUrl: './obito.component.html',
  styleUrls: ['./obito.component.scss']
})
export class ObitoComponent implements OnInit {

  apiCall = this.api.new().silent();
  page = 1
  pageSize = 10
  idServidor
  sexos = [

    {
      id: 'M',
      name: 'Masculino',
    },{
      id: 'F',
      name: 'Feminino',
    }]
  obito = {
    id: null,
    id_servidor: null,
    numero_termo: null,
    numero_folha: null,
    numero_livro: null,
    data_emissao: null,
    nome_cartorio: null,
    cnpj_cartorio: null,
    tipo_logradouro_cartorio: null,
    logradouro_cartorio: null,
    numero_cartorio: null,
    complemento_cartorio: null,
    bairro_cartorio: null,
    cep_cartorio: null,
    estado_cartorio: null,
    cidade_cartorio: null,
  }
  tiposLogradouro = []
  estados = []
  cidades = []

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }

  async ngOnInit() {
    const self = this
    self.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        self.idServidor = parametros['id'];
      }
    });
    self.tiposLogradouro = []
    self.estados = []
    self.cidades = []
    self.resetaObito()
    const tiposlogradouro = await self.apiCall.get('enderecos/tiposlogradouro').pipe(take(1)).toPromise()
    self.tiposLogradouro = JSON.parse(JSON.stringify(tiposlogradouro));
    const estados = await self.apiCall.get('enderecos/estados').pipe(take(1)).toPromise()
    self.estados = JSON.parse(JSON.stringify(estados));
    const obito = await self.apiCall.get(`servidor/obito?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise();
    if(obito) self.obito = JSON.parse(JSON.stringify(obito));
    if(self.obito.estado_cartorio) await self.toggleEstado()
    if(self.obito.cidade_cartorio) self.obito.cidade_cartorio = +self.obito.cidade_cartorio
    await self.spinner.hide()
  }

  async toggleEstado(){
    this.cidades = []
    const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+this.obito.estado_cartorio).pipe(take(1)).toPromise()
    this.cidades = JSON.parse(JSON.stringify(cidades));
  }

  resetaObito(){
    this.obito = {
      id: null,
      id_servidor: null,
      numero_termo: null,
      numero_folha: null,
      numero_livro: null,
      data_emissao: null,
      nome_cartorio: null,
      cnpj_cartorio: null,
      tipo_logradouro_cartorio: null,
      logradouro_cartorio: null,
      numero_cartorio: null,
      complemento_cartorio: null,
      bairro_cartorio: null,
      cep_cartorio: null,
      estado_cartorio: null,
      cidade_cartorio: null,
    }
  }
  consultaCep() {
    const self = this;
    if(!self.obito.cep_cartorio){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    cep(self.obito.cep_cartorio).then(async local => {
      self.obito.logradouro_cartorio = local.street
      self.obito.bairro_cartorio = local.neighborhood
      self.obito.estado_cartorio = local.state
      await self.toggleEstado()
      const foundCity = self.cidades.find(c=>c.nome.toLowerCase().includes(local.city.toLowerCase()))
      if(foundCity) self.obito.cidade_cartorio = foundCity.id
      const foundTipoLogradouro = self.tiposLogradouro.find(t=>t.descricao.toLowerCase().includes(local.street.split(" ")[0].toLowerCase()))
      if(foundTipoLogradouro) self.obito.tipo_logradouro_cartorio = foundTipoLogradouro.id
      self.spinner.hide()
    }).catch(erro => {
      self.spinner.hide()
    });
  }
  async atualizarOuCadastrar(){
    const self = this
    if(!self.obito || !self.idServidor){
      return
    }
    self.obito.data_emissao = this.preparaData(self.obito.data_emissao)
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    if(self.obito.id){
      await self.apiCall.get(`servidor/obito/editar?id_obito=${self.obito.id}&numero_termo=${self.obito.numero_termo}&numero_folha=${self.obito.numero_folha}&numero_livro=${self.obito.numero_livro}&data_emissao=${self.obito.data_emissao}&nome_cartorio=${self.obito.nome_cartorio}&cnpj_cartorio=${self.obito.cnpj_cartorio}&tipo_logradouro_cartorio=${self.obito.tipo_logradouro_cartorio}&logradouro_cartorio=${self.obito.logradouro_cartorio}&numero_cartorio=${self.obito.numero_cartorio}&complemento_cartorio=${self.obito.complemento_cartorio}&bairro_cartorio=${self.obito.bairro_cartorio}&cep_cartorio=${self.obito.cep_cartorio}&estado_cartorio=${self.obito.estado_cartorio}&cidade_cartorio=${self.obito.cidade_cartorio}`).pipe(take(1)).toPromise()
    }else{
      await self.apiCall.get(`servidor/obito/novo?id_servidor=${self.idServidor}&numero_termo=${self.obito.numero_termo}&numero_folha=${self.obito.numero_folha}&numero_livro=${self.obito.numero_livro}&data_emissao=${self.obito.data_emissao}&nome_cartorio=${self.obito.nome_cartorio}&cnpj_cartorio=${self.obito.cnpj_cartorio}&tipo_logradouro_cartorio=${self.obito.tipo_logradouro_cartorio}&logradouro_cartorio=${self.obito.logradouro_cartorio}&numero_cartorio=${self.obito.numero_cartorio}&complemento_cartorio=${self.obito.complemento_cartorio}&bairro_cartorio=${self.obito.bairro_cartorio}&cep_cartorio=${self.obito.cep_cartorio}&estado_cartorio=${self.obito.estado_cartorio}&cidade_cartorio=${self.obito.cidade_cartorio}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.obito.id ? 'Óbito atualizado com sucesso!' : 'Óbito cadastrado com sucesso!', 'success')
    await self.ngOnInit()
  }
  preparaData(data){
    if(data){
      return moment(data, 'DDMMYYYY').format('YYYY-MM-DD')
    }else{
      data = ''
    }
    return data
  }
}
