<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300">Bem vindo ao Census</h1>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <input type="text" id="cidade" class="form-control" style="background-color: white; height: 100%"
      placeholder="Nome da Cidade" name="cidade" [(ngModel)]="filterCidadecomUsuarios" (keyup)="filterCity()">
  </div>
</div>
<div class="row mb-4" *ngIf="cidadesComUsuarios && cidadesComUsuarios.length > 0">
  <div class="col-12">
    <div class="card-content" >
      <div class="card-body pr-0 pl-0" >
        <div class="card-body">
          <ul class="list-group">
            <li *ngFor="let c of cidadesComUsuarios" class="list-group-item">
              <div class="row mb-2">
                <div class="col-12 col-md-3">
                  <h5 class="mt-2">{{c.city}}</h5>
                  <p>{{c.users.length}} usuários</p>
                </div>
                <div class="col-12 col-md-9 text-right d-flex align-items-center justify-content-end">
                  <div class="d-md-none" role="group">
                    <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                      <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" ngbDropdownToggle>
                        <i class="ft-more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu" role="menu">
                        <a class="dropdown-item" routerLink="/page/servidores" [queryParams]="{cidade: c.id}">Servidores</a>
                        <a class="dropdown-item" routerLink="/page/municipio/relatorios" [queryParams]="{cidade: c.id}">Relatórios</a>
                        <a class="dropdown-item" routerLink="/page/municipio/orgaos" [queryParams]="{cidade: c.id}">Configurações</a>
                        <a class="dropdown-item" routerLink="/page/municipio/usuarios" [queryParams]="{cidade: c.id}">Usuários</a>
                      </div>
                    </div>
                  </div>
                  <div class="btn-group btn-group-lg d-none d-md-block mt-2 mb-2" role="group">
                    <button type="button" placement="top" ngbTooltip="Importações."
                      routerLink="/page/importacoes" [queryParams]="{cidade: c.id}" class="btn btn-outline-primary btn-icon">
                      <i class="fa fa-upload"></i>
                    </button>
                    <button type="button" placement="top" ngbTooltip="Visualizar Servidores." routerLink="/page/servidores"
                      [queryParams]="{cidade: c.id}" class="btn btn-outline-primary btn-icon">
                      <i class="fa fa-eye"></i>
                    </button>
                    <button type="button" placement="top" ngbTooltip="Visualizar Relatórios." routerLink="/page/municipio/relatorios"
                      [queryParams]="{cidade: c.id}" class="btn btn-outline-primary btn-icon">
                      <i class="fa fa-dashboard"></i>
                    </button>
                    <button type="button" placement="top" ngbTooltip="Visualizar Configurações." routerLink="/page/municipio/orgaos"
                      [queryParams]="{cidade: c.id}" class="btn btn-outline-primary btn-icon">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button type="button" placement="top" ngbTooltip="Visualizar Usuários." routerLink="/page/municipio/usuarios"
                      [queryParams]="{cidade: c.id}" class="btn btn-outline-primary btn-icon">
                      <i class="fa fa-users"></i>
                    </button>
                  </div>
                  <button type="button" class="btn btn-outline-primary mt-2 mb-2 ml-2" (click)="toggleCollapse(c.city)"
                    [attr.aria-expanded]="!collapsedStates[c.city]" aria-controls="{{c.city}}">
                    Ver Usuários
                  </button>
                </div>
              </div>
              
            
          <div id="{{c.city}}" [ngbCollapse]="collapsedStates[c.city]">
            <ul class="list-group">
              <li *ngFor="let usuario of c.users | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
                class="list-group-item">
                <div class="media">
                  <div class="media-body">
                    <h5>{{usuario.nome + (usuario.backoffice && usuario.backoffice.validadeAcessoCenso ? ' - validade:
                      '+beautifulDate(usuario.backoffice.validadeAcessoCenso) : '')}}</h5>
                    <small>{{usuario.login}}</small>
                  </div>
                  <div class=" d-md-none" role="group">
                    <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                      <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" ngbDropdownToggle>
                        <i class="ft-more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu" role="menu">
                        <a class="dropdown-item" routerLink="/page/servidores"
                          [queryParams]="{cidade: usuario.cidade}">Servidores</a>
                        <a class="dropdown-item" routerLink="/page/municipio/relatorios"
                          [queryParams]="{cidade: usuario.cidade}">Relatórios</a>
                        <a class="dropdown-item" routerLink="/page/municipio/orgaos"
                          [queryParams]="{cidade: usuario.cidade}">Configurações</a>
                        <a class="dropdown-item" routerLink="/page/municipio/usuarios"
                          [queryParams]="{cidade: usuario.cidade}">Usuários</a>
                        <a class="dropdown-item" (click)="openModal(atualizarSenha, usuario, '2')">Atualizar senha</a>                        
                        <a class="dropdown-item"
                          (click)="alterarStatusUsuario(usuario.id, usuario.ativo)">{{usuario.ativo ?
                          'Inativar' : 'Ativar'}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="btn-group btn-group-lg d-none d-md-block" role="group">
                    
                    <button type="button" class="btn btn-outline-primary btn-icon" placement="top"
                      (click)="openModal(atualizarSenha, usuario, '2')" ngbTooltip="Atualizar senha."><i
                        class="fa fa-refresh"></i></button> 
                    <button type="button" class="btn btn-outline-primary btn-icon" placement="top"
                      (click)="alterarStatusUsuario(usuario.id, usuario.ativo)"
                      ngbTooltip="{{usuario.ativo ? 'Inative este usuário.' : 'Ative este usuário.'}}"> <i
                        class="fa fa-lock"></i></button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mb-2" *ngIf="usuarios.length > 0" style="display: none;">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <ul class="list-group">
          <li *ngFor="let usuario of usuarios | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
            class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">{{usuario.nomeCidade}}</h5>
                <p>{{usuario.nome + (usuario.backoffice && usuario.backoffice.validadeAcessoCenso ? ' - validade:
                  '+beautifulDate(usuario.backoffice.validadeAcessoCenso) : '')}}</p>
                <small>{{usuario.login}}</small>
              </div>
              <div class=" d-md-none" role="group">
                <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                  <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" ngbDropdownToggle>
                    <i class="ft-more-vertical"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a class="dropdown-item" routerLink="/page/servidores"
                      [queryParams]="{cidade: usuario.cidade}">Servidores</a>
                    <a class="dropdown-item" routerLink="/page/municipio/relatorios"
                      [queryParams]="{cidade: usuario.cidade}">Relatórios</a>
                    <a class="dropdown-item" routerLink="/page/municipio/orgaos"
                      [queryParams]="{cidade: usuario.cidade}">Configurações</a>
                    <a class="dropdown-item" routerLink="/page/municipio/usuarios"
                      [queryParams]="{cidade: usuario.cidade}">Usuários</a>
                    <a class="dropdown-item" (click)="openModal(atualizarSenha, usuario, '2')">Atualizar senha</a>
                    <a class="dropdown-item" (click)="liberaValidade(atualizaValidade, usuario)">Atualizar validade
                      acesso</a>
                    <a class="dropdown-item" (click)="alterarStatusUsuario(usuario.id, usuario.ativo)">{{usuario.ativo ?
                      'Inativar' : 'Ativar'}}</a>
                  </div>
                </div>
              </div>
              <div class="btn-group btn-group-lg d-none d-md-block" role="group">
                <button type="button" placement="top" ngbTooltip="Visualizar Servidores." routerLink="/page/servidores"
                  [queryParams]="{cidade: usuario.cidade}" class="btn btn-outline-primary btn-icon"><i
                    class="fa fa-eye"></i></button>
                <button type="button" placement="top" ngbTooltip="Visualizar Relatórios."
                  routerLink="/page/municipio/relatorios" [queryParams]="{cidade: usuario.cidade}"
                  class="btn btn-outline-primary btn-icon"><i class="fa fa-dashboard"></i></button>
                <button type="button" placement="top" ngbTooltip="Visualizar Configurações."
                  routerLink="/page/municipio/orgaos" [queryParams]="{cidade: usuario.cidade}"
                  class="btn btn-outline-primary btn-icon"><i class="fa fa-edit"></i></button>
                <button type="button" placement="top" ngbTooltip="Visualizar Usuários."
                  routerLink="/page/municipio/usuarios" [queryParams]="{cidade: usuario.cidade}"
                  class="btn btn-outline-primary btn-icon"><i class="fa fa-users"></i></button>
                <button type="button" class="btn btn-outline-primary btn-icon" placement="top"
                  (click)="openModal(atualizarSenha, usuario, '2')" ngbTooltip="Atualizar senha."><i
                    class="fa fa-refresh"></i></button>
                <button type="button" class="btn btn-outline-primary btn-icon" placement="top"
                  (click)="liberaValidade(atualizaValidade, usuario)" ngbTooltip="Atualizar Validade Acesso."><i
                    class="fa fa-calendar"></i></button>
                <button type="button" class="btn btn-outline-primary btn-icon" placement="top"
                  (click)="alterarStatusUsuario(usuario.id, usuario.ativo)"
                  ngbTooltip="{{usuario.ativo ? 'Inative este usuário.' : 'Ative este usuário.'}}"> <i
                    class="fa fa-lock"></i></button>
              </div>
            </div>
          </li>
        </ul>
        <div class="form-row" style="margin: 5px">
          <div class="d-flex justify-content-center">
            <ngb-pagination style="margin-top: 10px" *ngIf="usuarios.length>0" [(page)]="page" [pageSize]="pageSize"
              [maxSize]="12" [collectionSize]="usuarios.length"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="usuarios.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum usuário encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #atualizaValidade let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Validade Acesso</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="validade">Validade</label>
                <input type="text" id="validade" class="form-control" name="validade" mask="00/00/0000"
                  [(ngModel)]="usuarioValidade.validade" #validade="ngModel" required>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block btn-secondary"
                    (click)="modal.close('Close click')">Cancelar</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="updateValidade(modal)"
                    [disabled]="!usuarioValidade || !usuarioValidade.id || !usuarioValidade.validade">Atualizar</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #atualizarSenha let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Nova Senha</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="novaSenha">Nova Senha</label>
                <input type="text" id="novaSenha" class="form-control" name="novaSenha"
                  [(ngModel)]="atualizacaoSenha.novaSenha" #inputSenha="ngModel" required>
              </div>
              <div class="form-group mb-2">
                <label for="confirmaNovaSenha">Confirme a Nova Senha</label>
                <input type="text" id="confirmaNovaSenha" class="form-control" name="confirmaNovaSenha"
                  [(ngModel)]="atualizacaoSenha.confirmaNovaSenha" #inputConfir="ngModel" required>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block btn-secondary"
                    (click)="modal.close('Close click')">Cancelar</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="updateSenha(modal)"
                    [disabled]="!atualizacaoSenha || !atualizacaoSenha.novaSenha || !atualizacaoSenha.confirmaNovaSenha || (atualizacaoSenha.novaSenha!=atualizacaoSenha.confirmaNovaSenha)">Atualizar</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ngx-spinner></ngx-spinner>