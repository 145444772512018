import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import * as moment from 'moment'
@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1
  pageSize = 10
  atualizacaoSenha = {
    novaSenha: null,
    confirmaNovaSenha: null,
  }
  userToUpdate = null
  tipoUsuario = null
  filterCidade
  filterCidadecomUsuarios

  usuarioValidade = {
    id : null,
    validade : null,
  }
  usuarios = []
  usuariosOriginal = []
  cidadesComUsuarios = []

  cidadesComUsuariosOriginal = []

  cidadeUsuarios = {
    city: '',
    users: []
};

collapsedStates: {[key: string]: boolean} = {};

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private http: HttpClient,
    private api: Api
  ) {
  }

  async ngOnInit() {
   const self = this
    self.usuarioValidade = {
      id : null,
      validade : null,
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    const data = await self.apiCall.get('users/lista/servidor').pipe(take(1)).toPromise()

    self.usuarios = JSON.parse(JSON.stringify(data))

    const cidades = self.getUniqueCityNames(self.usuarios)

   


// Adiciona usuários às suas respectivas cidades
self.usuarios.forEach(usuario => {
  if (this.cidadesComUsuarios[usuario.nomeCidade]) {
      this.cidadesComUsuarios[usuario.nomeCidade].push(usuario);
  }
});

const resultado = cidades.map(cidade => {
  const usuariosDaCidade = self.usuarios.filter(usuario => usuario.nomeCidade === cidade);

  // Verifica se há usuários na cidade para pegar o código da cidade
  const cidadeId = usuariosDaCidade.length > 0 ? usuariosDaCidade[0].cidade : null;

  return {
      city: cidade,
      id: cidadeId,
      users: usuariosDaCidade
  };
});

// Filtra cidades que têm usuários
this.cidadesComUsuarios = resultado.filter(cidade => cidade.users.length > 0);

// Define os estados colapsados
this.cidadesComUsuarios.forEach((cidade, index) => {
  this.collapsedStates[cidade.city] = index !== 0;
});


console.log(this.cidadesComUsuarios)

// for(let u of self.usuarios){
    //   if(u.nomeCidade){
    //     const retorno = await self.http.get('https://lz3cfpx1ie.execute-api.us-east-1.amazonaws.com/dev/clientes/validade/'+u.nomeCidade).toPromise()
    //     u.backoffice = JSON.parse(JSON.stringify(retorno)).return
    //   }
    // }
    self.spinner.hide()

    self.usuariosOriginal = [...self.usuarios]

    self.cidadesComUsuariosOriginal = [...self.cidadesComUsuarios]

  //  const estadosCivis = await self.apiCall.get('servidor/estadocivil').pipe(take(1)).toPromise()

  }

   getUniqueCityNames(users): string[] {
    const cityNames = new Set<string>();

    console.log(users)

    for (const user of users) {
        cityNames.add(user.nomeCidade);
    }

    return Array.from(cityNames);
}

toggleCollapse(cityName: string): void {
  if (this.collapsedStates[cityName] === undefined) {
    this.collapsedStates[cityName] = true;
  } else {
    this.collapsedStates[cityName] = !this.collapsedStates[cityName];
  }
}


  openModal(content, usuario, tipo){
    this.userToUpdate = usuario
    this.tipoUsuario = tipo
    const modal = this.modalService.open(content, { centered: true })
  }
  liberaValidade(content, usuario){

    if(!usuario.backoffice){
      return
    }
    this.usuarioValidade = {
      id : usuario.backoffice.id,
      validade : usuario.backoffice.validadeAcessoCenso ? moment(usuario.backoffice.validadeAcessoCenso).format('DDMMYYYY') : null,
    }

    const modal = this.modalService.open(content, { centered: true })
  }
  filter(){
    this.usuarios = [...this.usuariosOriginal]
    if(this.filterCidade){
      this.usuarios = this.usuarios.filter(x=>x.nomeCidade && x.nomeCidade.toLowerCase().includes(this.filterCidade.toLowerCase()))
    }
    this.usuarios = [...this.usuarios]

  }

  filterCity(){
    this.cidadesComUsuarios = [...this.cidadesComUsuariosOriginal]
    if(this.filterCidadecomUsuarios){
      this.cidadesComUsuarios = this.cidadesComUsuarios.filter(x=>x.city && x.city.toLowerCase().includes(this.filterCidadecomUsuarios.toLowerCase()))
    }
    this.cidadesComUsuarios = [...this.cidadesComUsuarios]

  }
  async updateValidade(modal){
    if(!this.usuarioValidade || !this.usuarioValidade.id || !this.usuarioValidade.validade){
      return
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    await this.http.put('https://lz3cfpx1ie.execute-api.us-east-1.amazonaws.com/dev/clientes/validade', {
      id : this.usuarioValidade.id,
      validadeAcessoCenso : moment(this.usuarioValidade.validade, 'DDMMYYYY').toDate()
    }).toPromise()
    this.spinner.hide()
    await this.app.alert('Tudo certo!', 'Senha atualizada com sucesso!', 'success')
    modal.close('Close click')
    await this.ngOnInit()

  }
  async updateSenha(modal){
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    await this.apiCall.get(`users/edicao/senha?idUsuario=${this.userToUpdate.id}&senha=${this.atualizacaoSenha.novaSenha}&tipoUsuario=${this.tipoUsuario}`).pipe(take(1)).toPromise()
    this.spinner.hide()
    await this.app.alert('Tudo certo!', 'Senha atualizada com sucesso!', 'success')
    modal.close('Close click')
    await this.ngOnInit()

  }

  async alterarStatusUsuario(id, ativo){
    if (ativo) {
      ativo = 0;
    } else {
      ativo = 1;
    }
    await this.apiCall.get(`users/edicao/status?idUsuario=${id}&ativo=${ativo}&tipoUsuario=2`).pipe(take(1)).toPromise()
    await this.app.alert('Tudo certo!', 'Usuário atualizado com sucesso!', 'success')
    await this.ngOnInit()

  }
  beautifulDate(date){
    return moment(date).format('DD/MM/YYYY')
  }
}
