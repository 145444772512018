import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as moment from "moment";
import * as cep from "cep-promise";

@Component({
  selector: 'app-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss']
})
export class EnderecoComponent implements OnInit {

  apiCall = this.api.new().silent();
  endereco = {
    cep: null,
    tipo_logradouro: null,
    logradouro: null,
    numero: null,
    id: null,
    id_servidor: null,
    complemento: null,
    bairro: null,
    estado: null,
    cidade: null,
  }
  idServidor
  tiposlogradouro = []
  estados = []
  cidades = []
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }

  async ngOnInit(){
    const self = this
    self.endereco = null
    self.idServidor = null
    self.tiposlogradouro = []
    self.estados = []
    self.cidades = []
    self.endereco = {
      cep: null,
      tipo_logradouro: null,
      logradouro: null,
      numero: null,
      id: null,
      id_servidor: null,
      complemento: null,
      bairro: null,
      estado: null,
      cidade: null,
    }
    this.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        this.idServidor = parametros['id'];
      }
    });

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const estados = await self.apiCall.get('enderecos/estados').pipe(take(1)).toPromise()
    self.estados = JSON.parse(JSON.stringify(estados));

    const tiposlogradouro = await self.apiCall.get('enderecos/tiposlogradouro').pipe(take(1)).toPromise()
    self.tiposlogradouro = JSON.parse(JSON.stringify(tiposlogradouro));

    const endereco = await self.apiCall.get(`servidor/endereco?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise()

    if(endereco) self.endereco = JSON.parse(JSON.stringify(endereco));
    if(self.endereco.cidade) self.endereco.cidade = +self.endereco.cidade

    await self.toggleEstado()

    self.spinner.hide()
  }


  async atualizarOuCadastrar(){
    const self = this
    if(!self.endereco || !self.idServidor){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
      let endereco = {...self.endereco}
      if(endereco.bairro) endereco.bairro = endereco.bairro.replace(/'/g, "''")
      if(endereco.logradouro) endereco.logradouro = endereco.logradouro.replace(/'/g, "''")
      if(endereco.complemento) endereco.complemento = endereco.complemento.replace(/'/g, "''")
    if(self.endereco.id){
      await self.apiCall.get(`servidor/endereco/editar?id_endereco=${endereco.id}&tipo_logradouro=${endereco.tipo_logradouro}&logradouro=${endereco.logradouro}&numero=${endereco.numero}&complemento=${endereco.complemento}&bairro=${endereco.bairro}&cep=${endereco.cep}&estado=${endereco.estado}&cidade=${endereco.cidade}`).pipe(take(1)).toPromise()
    }else{
      await self.apiCall.get(`servidor/endereco/novo?id_servidor=${self.idServidor}&tipo_logradouro=${endereco.tipo_logradouro}&logradouro=${endereco.logradouro}&numero=${endereco.numero}&complemento=${endereco.complemento}&bairro=${endereco.bairro}&cep=${endereco.cep}&estado=${endereco.estado}&cidade=${endereco.cidade}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.endereco.id ? 'Endereço atualizado com sucesso!' : 'Endereço cadastrado com sucesso!', 'success')
    await self.ngOnInit()
  }
  consultaCep() {
    const self = this;
    if(!self.endereco.cep){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    cep(self.endereco.cep).then(async local => {
      self.endereco.logradouro = local.street
      self.endereco.bairro = local.neighborhood
      self.endereco.estado = local.state
      await self.toggleEstado()
      const foundCity = self.cidades.find(c=>c.nome.toLowerCase().includes(local.city.toLowerCase()))
      if(foundCity) self.endereco.cidade = foundCity.id
      const foundTipoLogradouro = self.tiposlogradouro.find(t=>t.descricao.toLowerCase().includes(local.street.split(" ")[0].toLowerCase()))
      if(foundTipoLogradouro) self.endereco.tipo_logradouro = foundTipoLogradouro.id
      self.spinner.hide()
    }).catch(erro => {
      self.spinner.hide()
    });
  }
  async toggleEstado(){
    this.cidades = []
    const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+this.endereco.estado).pipe(take(1)).toPromise()
    this.cidades = JSON.parse(JSON.stringify(cidades));
  }
  preparaData(data){
    if(data){
      return moment(data, 'DDMMYYYY').format('YYYY-MM-DD')
    }else{
      data = ''
    }
    return data
  }

}
