import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as moment from "moment";

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {

  apiCall = this.api.new().silent();
  documentos = {
    cpf: null,
    cpts: null,
    data_emissao_cpts: '',
    data_emissao_rg: '',
    id: null,
    id_servidor: null,
    nit: null,
    orgao_expedidor_rg: null,
    rg: null,
    secao_titulo_eleitor: null,
    serie_cpts: null,
    titulo_eleitor:  null,
    uf_rg: null,
    uf_titulo_eleitor:  null,
    zona_titulo_eleitor: null,
  }
  idServidor
  idCidade
  servidor
  orgaosExpedidores = []
  estados = []
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }

  async ngOnInit(){
    const self = this
    self.documentos = null
    self.idServidor = null
    self.orgaosExpedidores = []
    self.estados = []
    self.documentos = {
      cpf: null,
      cpts: null,
      data_emissao_cpts: null,
      data_emissao_rg: null,
      id: null,
      id_servidor: null,
      nit: null,
      orgao_expedidor_rg: null,
      rg: null,
      secao_titulo_eleitor: null,
      serie_cpts: null,
      titulo_eleitor:  null,
      uf_rg: null,
      uf_titulo_eleitor:  null,
      zona_titulo_eleitor: null,
    }
    this.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        this.idServidor = parametros['id'];
      }
      if (parametros['idCidade']) {
        this.idCidade = parametros['idCidade'];
      }
    });

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const estados = await self.apiCall.get('enderecos/estados').pipe(take(1)).toPromise()
    self.estados = JSON.parse(JSON.stringify(estados));
    const servidor = await self.apiCall.get(`servidor?id=${self.idServidor}&id_cidade=${self.idCidade}`).pipe(take(1)).toPromise()
    self.servidor = JSON.parse(JSON.stringify(servidor));
    const orgaosexpedidores = await self.apiCall.get('servidor/orgaosexpedidores').pipe(take(1)).toPromise()
    self.orgaosExpedidores = JSON.parse(JSON.stringify(orgaosexpedidores));

    const documento = await self.apiCall.get(`servidor/documentos?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise()
    if(documento) self.documentos = JSON.parse(JSON.stringify(documento));

    self.spinner.hide()
  }

  disableDocumentos(){
    const self = this

    if(self.servidor && self.servidor.status && self.servidor.status==1){
      if (
        !self.documentos.cpf ||
        !self.documentos.nit ||
        !self.documentos.uf_rg ||
        !self.documentos.rg ||
        !self.documentos.orgao_expedidor_rg
      ) {
        return true;
      } else {
        return false;
      }
    }else if(self.servidor && self.servidor.status){ 
      if (
        !self.documentos.cpf ||
        !self.documentos.nit ||
        !self.documentos.orgao_expedidor_rg ||
        !self.documentos.uf_rg ||
        !self.documentos.rg ||
        !self.documentos.data_emissao_rg
      ) {
        return true;
      } else {
        return false;
      }
    }else {
      return false
    }
  }
  async atualizarOuCadastrar(){
    const self = this
    if(!self.documentos || !self.idServidor){
      return
    }

    self.documentos.data_emissao_rg = this.preparaData(self.documentos.data_emissao_rg)
    self.documentos.data_emissao_cpts = this.preparaData(self.documentos.data_emissao_cpts)
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(self.documentos.id){
      await self.apiCall.get(`servidor/documentos/editar?id=${self.documentos.id}&cpf=${self.documentos.cpf}&nit=${self.documentos.nit}&rg=${self.documentos.rg}&data_emissao_rg=${self.documentos.data_emissao_rg}&orgao_expedidor_rg=${self.documentos.orgao_expedidor_rg}&uf_rg=${self.documentos.uf_rg}&cpts=${self.documentos.cpts}&serie_cpts=${self.documentos.serie_cpts}&data_emissao_cpts=${self.documentos.data_emissao_cpts}&titulo_eleitor=${self.documentos.titulo_eleitor}&zona_titulo_eleitor=${self.documentos.zona_titulo_eleitor}&secao_titulo_eleitor=${self.documentos.secao_titulo_eleitor}&uf_titulo_eleitor=${self.documentos.uf_titulo_eleitor}`).pipe(take(1)).toPromise()
    }else{
      await self.apiCall.get(`servidor/documentos/novo?id_servidor=${self.idServidor}&cpf=${self.documentos.cpf}&nit=${self.documentos.nit}&rg=${self.documentos.rg}&data_emissao_rg=${self.documentos.data_emissao_rg}&orgao_expedidor_rg=${self.documentos.orgao_expedidor_rg}&uf_rg=${self.documentos.uf_rg}&cpts=${self.documentos.cpts}&serie_cpts=${self.documentos.serie_cpts}&data_emissao_cpts=${self.documentos.data_emissao_cpts}&titulo_eleitor=${self.documentos.titulo_eleitor}&zona_titulo_eleitor=${self.documentos.zona_titulo_eleitor}&secao_titulo_eleitor=${self.documentos.secao_titulo_eleitor}&uf_titulo_eleitor=${self.documentos.uf_titulo_eleitor}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.documentos.id ? 'Documentos atualizados com sucesso!' : 'Documentos cadastrados com sucesso!', 'success')
    await self.ngOnInit()
  }

  preparaData(data){
    if(data){
      return moment(data, 'DDMMYYYY').format('YYYY-MM-DD')
    }else{
      data = ''
    }
    return data
  }

}
