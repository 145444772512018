<div class="row text-left mb-2">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h3 class="text-bold-300">Relatórios Administrativos</h3>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <ng-select [items]="cidades"   name="cidades"  placeholder="Cidades" id="cidades" bindLabel="nome" bindValue="id"   (change)="changeCidade($event)" [(ngModel)]="cidadeEscolhida"></ng-select>
  </div>
  <div class="col" *ngIf="cidadeEscolhida">
    <input type="text" id="ano"  class="form-control" style="background-color: white; height: 100%" placeholder="Ano SPREV" name="ano" [(ngModel)]="filterAno" >
  </div>
  <div class="col">
    <div class="btn-group btn-group-lg" role="group">
      <button  type="button" class="btn btn-outline-primary" [disabled]="desabilitaRelatorio" (click)="exportarBancoDeDadosPuro()">Exportar Padrão</button>
      <button  type="button" class="btn btn-outline-primary" [disabled]="desabilitaRelatorio" (click)="exportarBancoDeDadosFormatoSPREV()">Exportar SPREV</button>
    </div>
  </div>
</div>
<h4>Tabelas relacionadas aos Servidores</h4>
<div class="row mb-2" >
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">

        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Tabela</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let tabela of tabelasServidores| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <td>{{tabela.tabela}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="exportarTabelaServidores(tabela.tabela)">Exportar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<h4>Tabelas Gerais</h4>
<div class="row mb-2" >
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">

        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Tabela</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let tabela of tabelasGerais| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <td>{{tabela.tabela}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="exportarTabelaGeral(tabela.tabela)">Exportar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row text-left mb-2">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h4>Backup Anexo dos Servidores</h4>
      <button  type="button" class="btn btn-outline-primary" (click)="ziparArquivos()">Gerar Zip Arquivos</button>
    </div>
  </div>
</div>
<div class="row mb-2" >
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">

        <table class="table text-center m-0">
          <thead>
          <tr>
            <th>Usuário</th>
            <th>Data</th>
            <th>Nome</th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let arquivo of arquivos| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
            <td>{{arquivo.nome}}</td>
            <td>{{arquivo.data_inclusao | date: 'dd/MM/yyyy'}}</td>
            <td>{{arquivo.chave}}</td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="downloadArquivo(arquivo.chave)" >Download</button>
            </td>
            <td>
              <button  type="button" class="btn btn-outline-primary" (click)="deletar(arquivo.chave, arquivo.id)" >Deletar</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
