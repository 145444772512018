import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as moment from "moment";

@Component({
  selector: 'app-orgaos',
  templateUrl: './orgaos.component.html',
  styleUrls: ['./orgaos.component.scss']
})
export class OrgaosComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1
  pageSize = 10
  orgao = {
    id: null,
    descricao: null,
    poder: null,
    cnpj: null,
    administracao: null
  }
  poderes = [
    'Executivo',
    'Legislativo',
    'Judiciário',
    'Ministério Público',
    'Tribunal de Contas',
    'Defensoria Pública'
  ]
  administracoes = [
    'Administração Direta',
    'Administração Indireta'
  ]
  orgaos = []
  cidade
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }
  async ngOnInit() {
    const self = this
    self.resetaOrgao()
    const dadosUsuario = this.me.currentUserValue;
    const json = JSON.parse(JSON.stringify(dadosUsuario));
    let cidade = json.user.cidade;
    if(json.user.tipo==='1'){
      self.route.queryParams.subscribe( parametros => {
        if (parametros['cidade']) {
          self.cidade = parametros['cidade'];
        }
      });
    }else{
      this.cidade = cidade

    }
    if(self.cidade){
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const orgaos = await self.apiCall.get('enderecos/estados/cidades/orgaos/todos?cidade='+self.cidade).pipe(take(1)).toPromise()
      self.orgaos = JSON.parse(JSON.stringify(orgaos));
      self.spinner.hide()
    }

  }
  resetaOrgao(){
    this.orgao = {
      id: null,
      descricao: null,
      poder: null,
      cnpj: null,
      administracao: null
    }
  }


  openModalXl(content, orgao){
    this.resetaOrgao()
    if(orgao) this.orgao = orgao
    const modal = this.modalService.open(content, { centered: true, size: "xl" })
  }
  async atualizarOuCadastrar(modal){
    const self = this
    if(!self.orgao || !self.cidade){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(self.orgao.id){
      await self.apiCall.get(`enderecos/estados/cidades/orgaos/editar?id_orgao=${self.orgao.id}&descricao=${self.orgao.descricao}&poder=${self.orgao.poder}&cnpj=${self.orgao.cnpj}&administracao=${self.orgao.administracao}`).pipe(take(1)).toPromise()

    }else{
      await self.apiCall.get(`enderecos/estados/cidades/orgaos/novo?id_cidade=${self.cidade}&descricao=${self.orgao.descricao}&poder=${self.orgao.poder}&cnpj=${self.orgao.cnpj}&administracao=${self.orgao.administracao}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.orgao.id ? 'Orgão atualizado com sucesso!' : 'Orgão cadastrado com sucesso!', 'success')
    modal.close('Close click')
    await self.ngOnInit()
  }
  async alterarStatusOrgao(id, ativo){
    if (ativo) {
      ativo = 0;
    } else {
      ativo = 1;
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const updatestatus = await this.apiCall.get(`enderecos/estados/cidades/orgaos/atualiza-status?id_orgao=${id}&ativo=${ativo}`).pipe(take(1)).toPromise()
    this.spinner.hide()
    this.ngOnInit()
  }

}
