import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import * as cep from "cep-promise";
import * as moment from "moment";

@Component({
  selector: 'app-representante',
  templateUrl: './representante.component.html',
  styleUrls: ['./representante.component.scss']
})
export class RepresentanteComponent implements OnInit {
  apiCall = this.api.new().silent();
  page = 1
  pageSize = 10
  idServidor
  tempos = [];
  sexos = [

    {
      id: 'M',
      name: 'Masculino',
    },{
      id: 'F',
      name: 'Feminino',
    }]
  representante = {
    id: null,
    id_servidor: null,
    tipo_representatividade: null,
    data_inicio:null,
    data_fim: null,
    nome_representante: null,
    cpf_representante: null,
    rg_representante: null,
    orgao_expedidor_rg_representante: null,
    data_emissao_rg_representante: null,
    uf_rg_representante: null,
    data_nascimento_representante: null,
    sexo_representante: null,
    tipo_logradouro_representante: null,
    logradouro_representante: null,
    numero_representante: null,
    complemento_representante: null,
    bairro_representante: null,
    cep_representante: null,
    estado_representante: null,
    cidade_representante: null,
    telefone_representante: null,
    celular_representante: null,
    email_representante: null,
  }
  orgaosExpedidores = []
  tiposRepresentatividade = []
  tiposLogradouro = []
  estados = []
  cidades = []

  tempoTotalCadastrado= 0
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api
  ) {
  }

  async ngOnInit() {
    const self = this
    self.route.queryParams.subscribe( parametros => {
      if (parametros['id']) {
        self.idServidor = parametros['id'];
      }
    });
    self.orgaosExpedidores = []
    self.tiposRepresentatividade = []
    self.tiposLogradouro = []
    self.estados = []
    self.cidades = []
    self.resetaRepresentante()
    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const orgaosexpedidores = await self.apiCall.get('servidor/orgaosexpedidores').pipe(take(1)).toPromise()
    self.orgaosExpedidores = JSON.parse(JSON.stringify(orgaosexpedidores));

    const tiposlogradouro = await self.apiCall.get('enderecos/tiposlogradouro').pipe(take(1)).toPromise()
    self.tiposLogradouro = JSON.parse(JSON.stringify(tiposlogradouro));

    const estados = await self.apiCall.get('enderecos/estados').pipe(take(1)).toPromise()
    self.estados = JSON.parse(JSON.stringify(estados));

    const tiposRepresentatividade = await self.apiCall.get('servidor/tiposrepresentatividade').pipe(take(1)).toPromise()
    self.tiposRepresentatividade = JSON.parse(JSON.stringify(tiposRepresentatividade));

    const representante = await self.apiCall.get(`servidor/representantelegal?id_servidor=${this.idServidor}`).pipe(take(1)).toPromise()
    if(representante) self.representante = JSON.parse(JSON.stringify(representante));
    if(self.representante.estado_representante) await self.toggleEstado()
    if(self.representante.cidade_representante) self.representante.cidade_representante = +self.representante.cidade_representante
    await self.spinner.hide()

  }
  consultaCep() {
    const self = this;
    if(!self.representante.cep_representante){
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    cep(self.representante.cep_representante).then(async local => {
      self.representante.logradouro_representante = local.street
      self.representante.bairro_representante = local.neighborhood
      self.representante.estado_representante = local.state
      await self.toggleEstado()
      const foundCity = self.cidades.find(c=>c.nome.toLowerCase().includes(local.city.toLowerCase()))
      if(foundCity) self.representante.cidade_representante = foundCity.id
      const foundTipoLogradouro = self.tiposLogradouro.find(t=>t.descricao.toLowerCase().includes(local.street.split(" ")[0].toLowerCase()))
      if(foundTipoLogradouro) self.representante.tipo_logradouro_representante = foundTipoLogradouro.id
      self.spinner.hide()
    }).catch(erro => {
      self.spinner.hide()
    });
  }
  resetaRepresentante(){
    this.representante = {
      id: null,
      id_servidor: null,
      tipo_representatividade: null,
      data_inicio:null,
      data_fim: null,
      nome_representante: null,
      cpf_representante: null,
      rg_representante: null,
      orgao_expedidor_rg_representante: null,
      data_emissao_rg_representante: null,
      uf_rg_representante: null,
      data_nascimento_representante: null,
      sexo_representante: null,
      tipo_logradouro_representante: null,
      logradouro_representante: null,
      numero_representante: null,
      complemento_representante: null,
      bairro_representante: null,
      cep_representante: null,
      estado_representante: null,
      cidade_representante: null,
      telefone_representante: null,
      celular_representante: null,
      email_representante: null,
    }
  }

  async toggleEstado(){
    this.cidades = []
    const cidades = await this.apiCall.get('enderecos/estados/cidades?estado='+this.representante.estado_representante).pipe(take(1)).toPromise()
    this.cidades = JSON.parse(JSON.stringify(cidades));
  }

  async atualizarOuCadastrar(){
    const self = this
    if(!self.representante || !self.idServidor){
      return
    }
    self.representante.data_inicio = this.preparaData(self.representante.data_inicio)
    self.representante.data_fim = this.preparaData(self.representante.data_fim)
    self.representante.data_emissao_rg_representante = this.preparaData(self.representante.data_emissao_rg_representante)
    self.representante.data_nascimento_representante = this.preparaData(self.representante.data_nascimento_representante)
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    if(self.representante.id){
      console.log('vai atualizar', self.representante)
      await self.apiCall.get(`servidor/representantelegal/editar?id_representante=${self.representante.id}&tipo_representatividade=${self.representante.tipo_representatividade}&data_inicio=${self.representante.data_inicio}&data_fim=${self.representante.data_fim}&nome_representante=${self.representante.nome_representante}&cpf_representante=${self.representante.cpf_representante}&rg_representante=${self.representante.rg_representante}&orgao_expedidor_rg_representante=${self.representante.orgao_expedidor_rg_representante}&data_emissao_rg_representante=${self.representante.data_emissao_rg_representante}&uf_rg_representante=${self.representante.uf_rg_representante}&data_nascimento_representante=${self.representante.data_nascimento_representante}&sexo_representante=${self.representante.sexo_representante}&tipo_logradouro_representante=${self.representante.tipo_logradouro_representante}&logradouro_representante=${self.representante.logradouro_representante}&numero_representante=${self.representante.numero_representante}&complemento_representante=${self.representante.complemento_representante}&bairro_representante=${self.representante.bairro_representante}&cep_representante=${self.representante.cep_representante}&estado_representante=${self.representante.estado_representante}&cidade_representante=${self.representante.cidade_representante}&telefone_representante=${self.representante.telefone_representante}&celular_representante=${self.representante.celular_representante}&email_representante=${self.representante.email_representante}`).pipe(take(1)).toPromise()
    }else{
      await self.apiCall.get(`servidor/representantelegal/novo?id_servidor=${self.idServidor}&tipo_representatividade=${self.representante.tipo_representatividade}&data_inicio=${self.representante.data_inicio}&data_fim=${self.representante.data_fim}&nome_representante=${self.representante.nome_representante}&cpf_representante=${self.representante.cpf_representante}&rg_representante=${self.representante.rg_representante}&orgao_expedidor_rg_representante=${self.representante.orgao_expedidor_rg_representante}&data_emissao_rg_representante=${self.representante.data_emissao_rg_representante}&uf_rg_representante=${self.representante.uf_rg_representante}&data_nascimento_representante=${self.representante.data_nascimento_representante}&sexo_representante=${self.representante.sexo_representante}&tipo_logradouro_representante=${self.representante.tipo_logradouro_representante}&logradouro_representante=${self.representante.logradouro_representante}&numero_representante=${self.representante.numero_representante}&complemento_representante=${self.representante.complemento_representante}&bairro_representante=${self.representante.bairro_representante}&cep_representante=${self.representante.cep_representante}&estado_representante=${self.representante.estado_representante}&cidade_representante=${self.representante.cidade_representante}&telefone_representante=${self.representante.telefone_representante}&celular_representante=${self.representante.celular_representante}&email_representante=${self.representante.email_representante}`).pipe(take(1)).toPromise()
    }
    self.spinner.hide()
    await this.app.alert('Tudo certo!', self.representante.id ? 'Representante Legal atualizado com sucesso!' : 'Representante Legal cadastrado com sucesso!', 'success')
    await self.ngOnInit()
  }
  preparaData(data){
    if(data){
      return moment(data, 'DDMMYYYY').format('YYYY-MM-DD')
    }else{
      data = ''
    }
    return data
  }
}
